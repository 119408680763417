import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Card, CardBody, Button, Modal, ModalBody, FormGroup, Label, Input, ModalHeader, Table } from 'reactstrap';
import { formatDate, textCapital } from '../../../Helpers/Utils';
import { toast } from 'react-toastify';
import { patchApiCall, reloadWindow } from 'gfdu';
import { encrypt } from '../../../Helpers/Crypto';
import { carDataChange } from '../../../Reducer/carSlice';
import fasTag from "../../../Assets/images/Fastag.svg"
import ImageUpload from './ImageUpload';
import EditDetails from './EditDetails';
import { contentLoadingChange } from '../../../Reducer/componentSlice';
import { useNavigate } from 'react-router-dom';
import { APIProvider, ControlPosition, MapControl, AdvancedMarker, Map, useAdvancedMarkerRef } from "@vis.gl/react-google-maps";
import Config from '../../../Helpers/Config';


const CarDetails = (props) => {
    const dispatch = useDispatch()
    const inputRef = useRef()
    const imageRef = useRef()
    const navigate = useNavigate()
    const car = useSelector((state) => state.car)
    const [markerRef, marker] = useAdvancedMarkerRef();
    const [carItems, setCarItems] = useState([])
    const [selectedImage, setSelectedImage] = useState("")
    const [viewCar, setViewCar] = useState("")
    const [viewReason, setViewReason] = useState(false)
    const [disableButton, setButtonDisable] = useState(false)
    const [reasonToggle, setReasonToggle] = useState(false)
    const [imageOpen, setImageOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [openImage, setOpenImage] = useState(false)
    const [verifyOpen, setVerifyOpen] = useState(false)
    const featureIcons = [
        { icon: <i className="bi bi-luggage"></i>, value: "Luggage" },
        { icon: <i className="bi bi-battery-charging"></i>, value: "Mobile Charger" },
        { icon: <i className='bx bxs-baby-carriage' ></i>, value: "Baby Seat" },
        { icon: <i className="bi bi-tools"></i>, value: "Puncture Kit" },
        { icon: <i className='bx bxs-spray-can' ></i>, value: "Air Freshener" },
        { icon: <i className='bx bx-user-voice' ></i>, value: "Voice Control" },
        { icon: <i className='bx bx-camera-home' ></i>, value: "Reverse Camera" },
        { icon: <i className="bi bi-broadcast"></i>, value: "Reverse Sensor" },
        { icon: <i className='bx bx-bluetooth' ></i>, value: "Bluetooth" },
        { icon: <i className='bx bx-wind'></i>, value: "Ac" },
        { icon: <i className='bx bxs-music' ></i>, value: "Music System" },
        { icon: <i className="bi bi-arrow-repeat"></i>, value: "Power Steering" },
        { icon: <i className="bi bi-person-video3"></i>, value: "Power Window" },
        { icon: <i className='bx bxs-car-mechanic'></i>, value: "Spare Tyre" },
        { icon: <i className='bx bx-shield'></i>, value: "Air Bag" },
        { icon: <i className="bi bi-earbuds"></i>, value: "Aux Input" },
        { icon: <i className="bi bi-distribute-horizontal"></i>, value: "Boot Space" }
    ]
    const changeStatus = (status) => {
        dispatch(contentLoadingChange(true))
        setButtonDisable(true)
        if (status === false && (car?.carData?.reason === undefined || car?.carData?.reason === "" || car?.carData?.reason?.length <= 5)) {
            setButtonDisable(false)
            dispatch(contentLoadingChange(false))
            if (car?.carData?.reason?.length !== 0 && car?.carData?.reason?.length  <= 5) {
                return toast.error("Reason is not enough")
            } else {
                return toast.error("Reason is required")   
            }
        }
        patchApiCall(`/admin/car/status/${props?.carId}?status=${status}`, encrypt({ ...(status === false ? { reason: car?.carData?.reason } : {}) }), (response) => {
            if (response?.success) {
                toast.success(`${status ? "Active Successfully" : "Deactive Successfully"}`)
                setTimeout(() => {
                    reloadWindow()
                }, 2000);
            } else {
                setButtonDisable(false)
                toast.error(response?.msg || response?.message || "Status Couldn't Update")
            }
            dispatch(contentLoadingChange(false))
        })
    }
    const handleChange = (e) => {
        dispatch(carDataChange({ [e?.target?.name]: e?.target?.value }))
    }
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file !== undefined) {
            setViewCar(URL.createObjectURL(file))
            imageRef.current = file
        }
    }
    const updateImage = async () => {
        dispatch(contentLoadingChange(true))
        setButtonDisable(true)
        const formData = new FormData();
        formData.append("car", imageRef.current)
        const imgId = (inputRef.current)?.split("/")?.pop()?.split(".")[0]
        patchApiCall(`/admin/car/carImage/${props?.carId}?imageId=${imgId}`, formData, (response) => {
            if (response?.success) {
                toast.success("Update Successfully");
                setTimeout(() => {
                    reloadWindow()
                    setButtonDisable(false)
                }, 2000);
            } else {
                toast.error("Update Failed");
                setButtonDisable(false)
            }
            dispatch(contentLoadingChange(false))
        })
    }
    const viewImage = (imgId) => {
        inputRef.current = imgId
        setViewCar(imgId)
        setOpenImage(true)
    }
    useEffect(() => {
        if (car?.carView?.carImages && car?.carView?.carImages?.length !== 0) {
            setCarItems(
                car?.carView?.carImages?.map((value, key) => ({
                    src: value,
                    altText: `Car ${key + 1}`,
                    key: `Car ${key + 1}`
                }))
            )
        }
    }, [])
    useEffect(() => {
        if (carItems?.length !== 0) {
            setSelectedImage(carItems[0])
        }
    }, [carItems])

    return (
        <React.Fragment>
            <Modal isOpen={viewReason} toggle={() => setViewReason(!viewReason)}>
                <ModalHeader toggle={() => setViewReason(!viewReason)} className='text-center' >Reason</ModalHeader>
                <ModalBody className='reason-modal'>{car?.carView?.errorMsg}</ModalBody>
            </Modal>
            <Modal isOpen={reasonToggle} toggle={() => setReasonToggle(!reasonToggle)} >
                <ModalBody >
                    <Row className=' flex-column justify-content-center align-items-center'>
                        <Col>
                            <FormGroup row className='justify-content-center'>
                                <Label for="reason" sm={3}>Reason</Label>
                                <Col sm={7}>
                                    <Input id='reason' name='reason' className='input-theme' value={car?.carData?.reason} onChange={handleChange} />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col><div className='text-center' ><Button color='danger' disabled={disableButton} onClick={() => changeStatus(false)} >Deactive</Button></div></Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Modal isOpen={imageOpen} size='xl' toggle={() => setImageOpen(!imageOpen)} >
                <ModalBody>
                    <Row className='my-3' ><Col className='text-center'><h4>Car Upload</h4></Col></Row>
                    <ImageUpload carId={props?.carId} setImageOpen={setImageOpen} />
                </ModalBody>
            </Modal>
            <Modal isOpen={editOpen} size='xl' toggle={() => setEditOpen(!editOpen)}>
                <ModalBody >
                    <Row className='my-3' ><Col className='text-center'><h5>Car Id : {props?.carId}</h5></Col></Row>
                    <EditDetails carId={props?.carId} setEditOpen={setEditOpen} />
                </ModalBody>
            </Modal>
            <Modal isOpen={openImage} size='xl' toggle={() => setOpenImage(!openImage)}>
                <ModalBody >
                    <Row className='flex-column' >
                        <Col><div className='view-car'><img src={viewCar} alt="view" /></div></Col>
                        <Col className='text-center my-3'><div className='input-profile'><input type='file' id='photo' onChange={handleFileChange} /></div><Button tag={"label"} for="photo" className='button-theme'>Change</Button></Col>
                        {
                            viewCar === inputRef.current
                                ? "" :
                                <Col className='text-center my-2'><div className='d-flex justify-content-evenly'><Button className='button-theme' disabled={disableButton} onClick={updateImage}> Update </Button><Button color='danger' disabled={disableButton} onClick={() => setOpenImage(false)}>Close</Button></div></Col>
                        }
                    </Row>
                </ModalBody>
            </Modal>
            <Row className="m-2" >
                <Col lg={7} className='my-2' >
                    <Card className='car-main-view' >
                        <CardBody>
                            <div >
                                {
                                    car?.carView?.carImages && car?.carView?.carImages?.length !== 0 ?
                                        <div className='car-image-view'>
                                            <div className='car-image-main'>
                                                <div className='car-hover-image'>
                                                    <Button className='button-theme mx-1' onClick={() => viewImage(selectedImage?.src)} >View</Button>
                                                </div>
                                                <img src={selectedImage?.src} alt={selectedImage?.altText} />
                                            </div>
                                            {carItems && carItems?.length !== 0 &&
                                                carItems?.map((value, i) => {
                                                    if (i < 10) {
                                                        return (
                                                            <div onClick={() => setSelectedImage(value)} className={`car-images ${selectedImage?.key === value?.key ? "active-car-image" : ""}`} key={value?.key}>
                                                                {selectedImage?.key === value?.key ? <div className='blur' ><i className='text-white bx bx-expand'></i></div> : ""}
                                                                <img src={value?.src} alt={value?.altText} />
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </div> :
                                        <div className='image-upload'>
                                            <Button className='button-theme' onClick={() => setImageOpen(true)} >Upload Images</Button>
                                        </div>
                                }
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={5} className='my-2'>
                    <Card className='car-main-view' >
                        <CardBody>
                            <Row className='flex-column'>
                                <Col>
                                    <p className='profile-data'><span>Car Id</span><span>{car?.carView?.carId} <i className='ms-2 profile-edit bx bx-edit' onClick={() => setEditOpen(true)} ></i></span></p>
                                    <p className='profile-data'><span>Owned By</span><span>{car?.carView?.associateId ? (car?.carView?.associateId)?.toUpperCase() : (car?.carView?.ownerType)?.toUpperCase()}</span></p>
                                    <p className='profile-data'><span>Created By / At</span><span>{textCapital(car?.carView?.createdBy)} / {formatDate(car?.carView?.createdAt)}</span></p>
                                    <p className='profile-data'><span>Registration No</span><span>{car?.carView?.registrationNo?.toUpperCase()}</span></p>
                                    <p className='profile-data'><span>Brand</span><span>{(car?.carView?.brand)?.toUpperCase()}</span></p>
                                    <p className='profile-data'><span>Model</span><span>{(car?.carView?.modelName)?.toUpperCase()}</span></p>
                                    <p className='profile-data'><span>Segment</span><span>{(car?.carView?.segment)?.toUpperCase()}</span></p>
                                    <p className='profile-data'><span>Transmission</span><span>{(car?.carView?.transmissionType)?.toUpperCase()}</span></p>
                                    <p className='profile-data'><span>Fuel</span><span>{(car?.carView?.fuelType)}</span></p>
                                    <p className='profile-data'><span>Colour</span><span>{(car?.carView?.color)?.toUpperCase()}</span></p>
                                    <p className='profile-data'><span>Seats</span><span>{car?.carView?.seats}</span></p>
                                    <p className='profile-data'>
                                        <span>Status</span>
                                        <span>
                                            {
                                                !car?.carView?.status ?
                                                    <Button color="success" disabled={disableButton} onClick={() => changeStatus(true)}>Active</Button> :
                                                    <Button color="danger" onClick={() => setReasonToggle(true)}>Deactive</Button>
                                            }
                                        </span>
                                    </p>
                                    {
                                        car?.carView?.status === false &&
                                        <p className='profile-data' ><span>Reason</span><span><Button className='button-theme' onClick={() => setViewReason(!viewReason)}>View</Button></span></p>
                                    }
                                    {
                                        car?.carView?.carImages && car?.carView?.carImages?.length !== 0 && car?.carView?.deviceId === undefined && car?.carView?.location === undefined &&
                                        <p className='profile-data'>
                                            <span>Device</span>
                                            <span><Button color="success" onClick={() => navigate(`/car/device/${props?.carId}`)}>Register</Button></span>
                                        </p>
                                    }
                                    {
                                        car?.carView?.deviceId !== undefined && car?.carView?.location !== undefined &&
                                        <p className='profile-data'>
                                            <span>Verifiy</span>
                                            <span>{car?.carView?.verified ? "Verified" : <Button color="success" onClick={() => navigate(`/car/verify/${props?.carId}`)}>Verify</Button>}</span>
                                        </p>
                                    }
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row className="m-2">
                <Col>
                    <Card >
                        <CardBody>
                            <Row><Col className='text-center'><h3>Features</h3></Col></Row>
                            <Row xs={1} sm={6} className='mx-2' >
                                {
                                    car?.carView?.features?.map((value, i) => {
                                        return (
                                            <Col key={i} className='my-1' > {featureIcons?.find((items) => items?.value === value)?.icon} {value}</Col>
                                        )
                                    })
                                }
                                {
                                    car?.carView?.fasTag && <Col> <span className='me-1'><img src={fasTag} alt='fasTag' /></span>FasTag</Col>
                                }
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {
                car?.carView?.location && (
                    <Row className="m-2 mb-5">
                        <Col>
                            <Card >
                                <CardBody>
                                    <Row xs={1} sm={3} className='mx-2' >
                                        <Col xs={1} sm={8} className='verify-map-selection'>
                                            {
                                                console.log(parseFloat(car.carView.location.lat), (car.carView.location.lat))

                                            }
                                            <APIProvider apiKey={Config?.KEYS?.MAP_KEY}>
                                                {car?.carView?.location ? (
                                                    <Map mapId={"bf51a910020fa25a"} defaultZoom={10} defaultCenter={{ lat: parseFloat(car.carView.location.lat), lng: parseFloat(car.carView.location.lng) }} gestureHandling={"cooperative"} mapTypeControl={true} zoomControl={true} disableDefaultUI={true}>
                                                        <AdvancedMarker ref={markerRef} position={{ lat: parseFloat(car.carView.location.lat), lng: parseFloat(car.carView.location.lng) }} />
                                                        <MapControl position={ControlPosition.TOP}></MapControl>
                                                    </Map>
                                                ) : (
                                                    <p>Location data is not available</p>
                                                )}
                                            </APIProvider>
                                        </Col>
                                        <Col xs={1} sm={4}>
                                            <p className='profile-data'><span>Device Id</span><span>{(car?.carView?.deviceId)}</span></p>
                                            <p className='profile-data'><span>Start KM</span><span>{(car?.carView?.startKm)}</span></p>
                                            <p className='profile-data'><span>Current KM</span><span>{(car?.carView?.currentKm) || ""}</span></p>
                                            <p className='profile-data'><span>Insurance End</span><span>{formatDate(car?.carView?.insuranceDate) || ""}</span></p>
                                            <p className='profile-data'><span>Latitude</span><span>{(car?.carView?.location?.lat)}</span></p>
                                            <p className='profile-data'><span>Longitude</span><span>{(car?.carView?.location?.lng)?.toUpperCase()}</span></p>
                                            <p className='profile-data'><span>Street</span><span>{car?.carView?.location?.street && textCapital(car?.carView?.location?.street)}</span></p>
                                            <p className='profile-data'><span>Locality</span><span>{car?.carView?.location?.locality && textCapital(car?.carView?.location?.locality)}</span></p>
                                            <p className='profile-data'><span>City</span><span>{car?.carView?.location?.city && textCapital(car?.carView?.location?.city)}</span></p>
                                            <p className='profile-data'><span>Country</span><span>{car?.carView?.location?.country && textCapital(car?.carView?.location?.country)}</span></p>
                                            <p className='profile-data'><span>Pincode</span><span>{car?.carView?.location?.pincode && textCapital(car?.carView?.location?.pincode)}</span></p>
                                            {
                                                car?.carView?.verified ?
                                                    <Table className="table-bg" borderless>
                                                        <thead>
                                                            <tr className="border-bottom text-middle">
                                                                <th className='text-center'>S.No</th>
                                                                <th className='text-center'>KM Per Hour</th>
                                                                <th className='text-center'>Price</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                car?.carView && car?.carView?.priceTypes ? car?.carView?.priceTypes?.map((value, i) => (
                                                                    <tr key={i + 1}>
                                                                        <td className='text-center'>{i + 1}</td>
                                                                        <td className='text-center'>{value.perHourKm}</td>
                                                                        <td className='text-center'>{value?.perHourPrice}</td>
                                                                    </tr>
                                                                )) : 
                                                                <tr>
                                                                    <td colSpan={3} className='text-center'>No Data Found</td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </Table> : ""
                                            }
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )
            }
        </React.Fragment>
    );
}

export default CarDetails;