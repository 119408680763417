import { getApiCall, patchApiCall, postApiCall, reloadWindow } from "gfdu";
import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Modal, ModalBody, Row, Table } from "reactstrap";
import { decrypt, encrypt } from "../../Helpers/Crypto";
import { toast } from "react-toastify";
import Pagination from "../../Helpers/Components/Pagination";
import { contentLoadingChange } from "../../Reducer/componentSlice";
import { useDispatch } from "react-redux";
import PlaceHolder from "../../Helpers/Components/PlaceHolder";
import TablePlaceHolder from "../../Helpers/Components/TablePlaceHolder";
import { isTrimmed } from "../../Helpers/Utils";


const Branch = () => {
  const dispatch = useDispatch()
  const [branchDetails, setBranchDetails] = useState([])
  const [newBranch, setNewBranch] = useState(false)
  const [editBranch, setEditBranch] = useState(false)
  const [disableButton, setDisableButton] = useState(false)
  const [next, setNext] = useState(false)
  const [loading, setLoading] = useState(true)
  const [branch, setBranch] = useState({
    buildingNo: "",
    buildingName: "",
    street: "",
    area: "",
    city: "",
    state: "",
    country: "",
    pincode: ""
  })
  const [filter, setFilter] = useState({
    page: 0,
    count: 10,
    // filter: "",
    // status: true,
    // verified: false,
    // createdBy: "",
    sortBy: "branch",
    sort: false
  })
  const sort = (sortBy, sort) => {
    setFilter((prev) => ({
      ...prev,
      sortBy: sortBy,
      sort: sort
    }))
  }
  const setPageNo = (value) => {
    setFilter((prev) => ({ ...prev, page: value }))
  }
  const openEditModal = (branch) => {
    getApiCall(`/admin/business/location/${branch}`, (response) => {
      if (response?.success) {
        const decryptedData = decrypt(response?.data)
        if (decryptedData?.success) {
          setBranch(decryptedData?.data)
          setBranch((prev)=>({
            ...prev,
            pincode : `${decryptedData?.data?.pincode}`
          }))
          setEditBranch(true)
        } else {
          toast.error("This Branch Cannot Be Edit")
        }
      } else {
        toast.error("This Branch Cannot Be Edit")
      }
    })
  }
  const editSubmit = () => {
    dispatch(contentLoadingChange(true))
    setDisableButton(true)
    if (branch.buildingNo === undefined || branch.buildingNo?.length === 0 || isTrimmed(branch.buildingNo)) {
      toast.error("Invalid Building No")
      dispatch(contentLoadingChange(false))
      setDisableButton(false)
    } else if (branch.buildingName === undefined || branch.buildingName?.length <= 3 || isTrimmed(branch.buildingName)) {
      toast.error("Invalid Building Name")
      dispatch(contentLoadingChange(false))
      setDisableButton(false)
    } else if (branch.street === undefined || branch.street?.length <= 3 || isTrimmed(branch.street)) {
      toast.error("Invalid Street")
      dispatch(contentLoadingChange(false))
      setDisableButton(false)
    } else if (branch.area === undefined || branch.area?.length <= 3 || isTrimmed(branch.area)) {
      toast.error("Invalid Area")
      dispatch(contentLoadingChange(false))
      setDisableButton(false)
    } else if (branch.city === undefined || branch.city?.length <= 3 || isTrimmed(branch.city)) {
      toast.error("Invalid City")
      dispatch(contentLoadingChange(false))
      setDisableButton(false)
    } else if (branch.state === undefined || branch.state?.length <= 3 || isTrimmed(branch.state)) {
      toast.error("Invalid State")
      dispatch(contentLoadingChange(false))
      setDisableButton(false)
    } else if (branch.country === undefined || branch.country?.length <= 3 || isTrimmed(branch.country)) {
      toast.error("Invalid Country")
      dispatch(contentLoadingChange(false))
      setDisableButton(false)
    } else if (branch.pincode === undefined || branch.pincode?.length !== 6 || isTrimmed(branch.pincode)) {
      toast.error("Invalid Pincode")
      dispatch(contentLoadingChange(false))
      setDisableButton(false)
    } else {
      patchApiCall(`/admin/business/location/update/${branch.branch}`, encrypt(branch), (response) => {
        if (response?.success) {
          toast.success("Branch Update SuccessFully")
          setEditBranch(false)
          setTimeout(() => {
            reloadWindow()
            setDisableButton(false)
          }, 2000);
        } else {
          toast.error("Changes not made")
          setDisableButton(false)
        }
        dispatch(contentLoadingChange(false))
      })
    }
  }
  const handleChange = (e) => {
    setBranch((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }
  const onSubmit = () => {
    dispatch(contentLoadingChange(true))
    setDisableButton(true)
    if (branch.buildingNo === undefined || branch.buildingNo?.length === 0 || isTrimmed(branch.buildingNo)) {
      toast.error("Invalid Building No")
      dispatch(contentLoadingChange(false))
      setDisableButton(false)
    } else if (branch.buildingName === undefined || branch.buildingName?.length <= 3 || isTrimmed(branch.buildingName)) {
      toast.error("Invalid Building Name")
      dispatch(contentLoadingChange(false))
      setDisableButton(false)
    } else if (branch.street === undefined || branch.street?.length <= 3 || isTrimmed(branch.street)) {
      toast.error("Invalid Street")
      dispatch(contentLoadingChange(false))
      setDisableButton(false)
    } else if (branch.area === undefined || branch.area?.length <= 3 || isTrimmed(branch.area)) {
      toast.error("Invalid Area")
      dispatch(contentLoadingChange(false))
      setDisableButton(false)
    } else if (branch.city === undefined || branch.city?.length <= 3 || isTrimmed(branch.city)) {
      toast.error("Invalid City")
      dispatch(contentLoadingChange(false))
      setDisableButton(false)
    } else if (branch.state === undefined || branch.state?.length <= 3 || isTrimmed(branch.state)) {
      toast.error("Invalid State")
      dispatch(contentLoadingChange(false))
      setDisableButton(false)
    } else if (branch.country === undefined || branch.country?.length <= 3 || isTrimmed(branch.country)) {
      toast.error("Invalid Country")
      dispatch(contentLoadingChange(false))
      setDisableButton(false)
    } else if (branch.pincode === undefined || branch.pincode?.length !== 6 || isTrimmed(branch.pincode)) {
      toast.error("Invalid Pincode")
      dispatch(contentLoadingChange(false))
      setDisableButton(false)
    } else {
      postApiCall("/admin/business/add-location", encrypt(branch), (response) => {
        if (response?.success) {
          toast.success("Branch Added SuccessFully")
          setTimeout(() => {
            reloadWindow()
            setDisableButton(false)
          }, 2000);
        } else {
          toast.error( response.msg || response.message ||"Branch not added")
          setDisableButton(false)
        }
        dispatch(contentLoadingChange(false))
      })
    }
  }
  const setStatus = (branchId, status) => {
    dispatch(contentLoadingChange(true))
    patchApiCall(`/admin/business/location/status/${branchId}?status=${status}`, encrypt({}), (response) => {
      if (response?.success) {
        toast.success(`Successfully ${status ? "Activated" : "Deactivated"}`)
        reloadWindow()
      } else {
        toast.error(`Failed to ${status ? "Active" : "Deactive"} the branch`)
      }
      dispatch(contentLoadingChange(false))
    })
  }
  useEffect(() => {
    setLoading(false)
    setBranchDetails([]);
    dispatch(contentLoadingChange(true))
    setBranch({})
    const filterData = {
      sort: filter?.sort,
      sortBy: filter?.sortBy,
    }
    setNext(false)
    postApiCall(`/admin/business/location/list/${filter?.page}?count=${filter?.count}`, encrypt(filterData), (response) => {
      if (response?.success) {
        const decryptedData = decrypt(response?.data)
        if (decryptedData?.success) {
          setBranchDetails(decryptedData?.data?.list);
          setNext(decryptedData?.data?.next)
        } else {
          toast.error("Failed to Retrive Details")
        }
      } else {
        toast.error("Failed to Retrive Details")
        if (response?.message === "No Data Found" && filter?.page !== 0) {
          setPageNo(filter?.page) 
        }
      }
      setLoading(true)
      dispatch(contentLoadingChange(false))
    })
  }, [filter])
  useEffect(() => {
    if (editBranch === false) {
      setBranch({})
    }
  }, [editBranch])
  return (
    <React.Fragment>
      <Modal size='lg' isOpen={newBranch} toggle={() => setNewBranch(!newBranch)}>
        <ModalBody className="rounded">
          <Row><Col className="text-center text-dark fs-3 mt-2 mb-3">New Branch</Col></Row>
          <Row className="justify-content-center">
            <Col>
              <Row className='flex-column'>
                <Col>
                  <FormGroup row className='justify-content-center'>
                    <Label for="buildingNo" className="text-dark" sm={3}>Building No</Label>
                    <Input id="buildingNo" name="buildingNo" autoComplete='off' value={branch?.buildingNo || ""} onChange={handleChange} className='text-upper input-size-2 input-theme' type="text" />
                  </FormGroup>
                </Col>
              </Row>
              <Row className='flex-column'>
                <Col>
                  <FormGroup row className='justify-content-center'>
                    <Label for="buildingName" className="text-dark" sm={3}>Building Name</Label>
                    <Input id="buildingName" name="buildingName" autoComplete='off' value={branch?.buildingName || ""} onChange={handleChange} className=' text-upper input-size-2 input-theme' type="text" />
                  </FormGroup>
                </Col>
              </Row>
              <Row className='flex-column'>
                <Col>
                  <FormGroup row className='justify-content-center'>
                    <Label for="street" className="text-dark" sm={3}>Street</Label>
                    <Input id="street" name="street" autoComplete='off' value={branch?.street || ""} onChange={handleChange} className=' text-upper input-size-2 input-theme' type="text" />
                  </FormGroup>
                </Col>
              </Row>
              <Row className='flex-column'>
                <Col>
                  <FormGroup row className='justify-content-center'>
                    <Label for="area" className="text-dark" sm={3}>Area</Label>
                    <Input id="area" name="area" autoComplete='off' value={branch?.area || ""} onChange={handleChange} className=' text-upper input-size-2 input-theme' type="text" />
                  </FormGroup>
                </Col>
              </Row>
              <Row className='flex-column'>
                <Col>
                  <FormGroup row className='justify-content-center'>
                    <Label for="city" className="text-dark" sm={3}>City</Label>
                    <Input id="city" name="city" autoComplete='off' value={branch?.city || ""} onChange={handleChange} className=' text-upper input-size-2 input-theme' type="text" />
                  </FormGroup>
                </Col>
              </Row>
              <Row className='flex-column'>
                <Col>
                  <FormGroup row className='justify-content-center'>
                    <Label for="state" className="text-dark" sm={3}>State</Label>
                    <Input id="state" name="state" autoComplete='off' value={branch?.state || ""} onChange={handleChange} className=' text-upper input-size-2 input-theme' type="text" />
                  </FormGroup>
                </Col>
              </Row>
              <Row className='flex-column'>
                <Col>
                  <FormGroup row className='justify-content-center'>
                    <Label for="country" className="text-dark" sm={3}>Country</Label>
                    <Input id="country" name="country" autoComplete='off' value={branch?.country || ""} onChange={handleChange} className=' text-upper input-size-2 input-theme' type="text" />
                  </FormGroup>
                </Col>
              </Row>
              <Row className='flex-column'>
                <Col>
                  <FormGroup row className='justify-content-center'>
                    <Label for="pincode" className="text-dark" sm={3}>Pincode</Label>
                    <Input id="pincode" name="pincode" autoComplete='off' value={branch?.pincode || ""} onChange={(e) => (e.target.value.length <= 6 && Number(e.target.value) == e.target.value) ? handleChange(e) : ""} className=' text-upper input-size-2 input-theme' type="text" />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row ><Col className="fs-3 mt-2 mb-3 text-center"><Button className="button-theme" disabled={disableButton} onClick={onSubmit} >Submit</Button></Col><Col className="fs-3 mt-2 mb-3 text-center"><Button color="danger" onClick={() => setNewBranch(false)} >Close</Button></Col></Row>
        </ModalBody>
      </Modal>
      <Modal size='lg' isOpen={editBranch} toggle={() => setEditBranch(!editBranch)}>
        <ModalBody className="rounded">
          <Row><Col className="text-center text-dark fs-3 mt-2 mb-3">{branch?.branch}</Col></Row>
          <Row className="justify-content-center">
            <Col>
              <Row className='flex-column'>
                <Col>
                  <FormGroup row className='justify-content-center'>
                    <Label for="buildingNo" className="text-dark" sm={3}>Building No</Label>
                    <Input id="buildingNo" name="buildingNo" autoComplete='off' value={branch?.buildingNo || ""} onChange={handleChange} className='text-upper input-size-2 input-theme' type="text" />
                  </FormGroup>
                </Col>
              </Row>
              <Row className='flex-column'>
                <Col>
                  <FormGroup row className='justify-content-center'>
                    <Label for="buildingName" className="text-dark" sm={3}>Building Name</Label>
                    <Input id="buildingName" name="buildingName" autoComplete='off' value={branch?.buildingName || ""} onChange={handleChange} className=' text-upper input-size-2 input-theme' type="text" />
                  </FormGroup>
                </Col>
              </Row>
              <Row className='flex-column'>
                <Col>
                  <FormGroup row className='justify-content-center'>
                    <Label for="street" className="text-dark" sm={3}>Street</Label>
                    <Input id="street" name="street" autoComplete='off' value={branch?.street || ""} onChange={handleChange} className=' text-upper input-size-2 input-theme' type="text" />
                  </FormGroup>
                </Col>
              </Row>
              <Row className='flex-column'>
                <Col>
                  <FormGroup row className='justify-content-center'>
                    <Label for="area" className="text-dark" sm={3}>Area</Label>
                    <Input id="area" name="area" autoComplete='off' value={branch?.area || ""} onChange={handleChange} className=' text-upper input-size-2 input-theme' type="text" />
                  </FormGroup>
                </Col>
              </Row>
              <Row className='flex-column'>
                <Col>
                  <FormGroup row className='justify-content-center'>
                    <Label for="city" className="text-dark" sm={3}>City</Label>
                    <Input id="city" name="city" autoComplete='off' value={branch?.city || ""} onChange={handleChange} className=' text-upper input-size-2 input-theme' type="text" />
                  </FormGroup>
                </Col>
              </Row>
              <Row className='flex-column'>
                <Col>
                  <FormGroup row className='justify-content-center'>
                    <Label for="state" className="text-dark" sm={3}>State</Label>
                    <Input id="state" name="state" autoComplete='off' value={branch?.state || ""} onChange={handleChange} className=' text-upper input-size-2 input-theme' type="text" />
                  </FormGroup>
                </Col>
              </Row>
              <Row className='flex-column'>
                <Col>
                  <FormGroup row className='justify-content-center'>
                    <Label for="country" className="text-dark" sm={3}>Country</Label>
                    <Input id="country" name="country" autoComplete='off' value={branch?.country || ""} onChange={handleChange} className=' text-upper input-size-2 input-theme' type="text" />
                  </FormGroup>
                </Col>
              </Row>
              <Row className='flex-column'>
                <Col>
                  <FormGroup row className='justify-content-center'>
                    <Label for="pincode" className="text-dark" sm={3}>Pincode</Label>
                    <Input id="pincode" name="pincode" autoComplete='off' value={branch?.pincode || ""} onChange={(e) => (e.target.value.length <= 6 && Number(e.target.value) == e.target.value) ? handleChange(e) : ""} className=' text-upper input-size-2 input-theme' type="text" />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row ><Col className="fs-3 mt-2 mb-3 text-center"><Button className="button-theme" disabled={disableButton} onClick={editSubmit} >Submit</Button></Col><Col className="fs-3 mt-2 mb-3 text-center"><Button color="danger" onClick={() => setEditBranch(false)} >Close</Button></Col></Row>
        </ModalBody>
      </Modal>
      <div className="branch">
        <Container fluid>
          <Row className="justify-content-between my-2" >
            <Col></Col>
            <Col className="text-center"><h3 >Branch Details</h3></Col>
            <Col><div className="d-flex justify-content-end"><Button className="button-theme" onClick={() => setNewBranch(!newBranch)} >New Branch</Button></div></Col>
          </Row>
        </Container>
        <Card className="branch-card">
          <CardBody className="table-card" >
            <Table className="table-bg" borderless >
              <thead>
                <tr className="border-bottom" >
                  <th><div className='sort-head'><div className='sort-arrow' ><i className={filter?.sortBy === "branch" && filter?.sort === false ? "i-active" : ""} onClick={() => sort("branch", false)} >▲</i><i className={filter?.sortBy === "branch" && filter?.sort === true ? "i-active" : ""} onClick={() => sort("branch", true)} >▼</i></div> Branch Id</div></th>
                  <th><div className='sort-head'><div className='sort-arrow' ><i className={filter?.sortBy === "area" && filter?.sort === false ? "i-active" : ""} onClick={() => sort("area", false)} >▲</i><i className={filter?.sortBy === "area" && filter?.sort === true ? "i-active" : ""} onClick={() => sort("area", true)} >▼</i></div> Area </div></th>
                  <th><div className='sort-head'><div className='sort-arrow' ><i className={filter?.sortBy === "city" && filter?.sort === false ? "i-active" : ""} onClick={() => sort("city", false)} >▲</i><i className={filter?.sortBy === "city" && filter?.sort === true ? "i-active" : ""} onClick={() => sort("city", true)} >▼</i></div> City</div></th>
                  <th><div className='sort-head'><div className='sort-arrow' ><i className={filter?.sortBy === "state" && filter?.sort === false ? "i-active" : ""} onClick={() => sort("state", false)} >▲</i><i className={filter?.sortBy === "state" && filter?.sort === true ? "i-active" : ""} onClick={() => sort("state", true)} >▼</i></div> State </div></th>
                  <th><div className='sort-head'><div className='sort-arrow' ><i className={filter?.sortBy === "country" && filter?.sort === false ? "i-active" : ""} onClick={() => sort("country", false)} >▲</i><i className={filter?.sortBy === "country" && filter?.sort === true ? "i-active" : ""} onClick={() => sort("country", true)} >▼</i></div> Country</div></th>
                  <th><div className='sort-head'><div className='sort-arrow' ><i className={filter?.sortBy === "pincode" && filter?.sort === false ? "i-active" : ""} onClick={() => sort("pincode", false)} >▲</i><i className={filter?.sortBy === "pincode" && filter?.sort === true ? "i-active" : ""} onClick={() => sort("pincode", true)} >▼</i></div> Pincode</div></th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {
                  loading ?
                    (branchDetails && branchDetails?.length !== 0 ? branchDetails?.map((value, key) => {
                      return (
                        <tr key={key} onClick={() => openEditModal(value?.branch)} className={`table-content-row cursor-pointer-hover ${!value?.status && "text-disable"}`} >
                          <td>{(value?.branch)?.toUpperCase()}</td>
                          <td>{(value?.area)?.toUpperCase()}</td>
                          <td>{(value?.city)?.toUpperCase()}</td>
                          <td>{(value?.state)?.toUpperCase()}</td>
                          <td>{(value?.country)?.toUpperCase()}</td>
                          <td>{value?.pincode}</td>
                          <td><Button color={!value?.status ? "success" : "danger"} onClick={() => setStatus(value?.branch, !value?.status)} className="statusBtn">{!value?.status ? "Active" : "Deactive"}</Button></td>
                        </tr>
                      )
                    })
                      :
                      <tr><td className="text-center" colSpan={7}>No Data Found</td></tr>)

                    :

                    <TablePlaceHolder count={7} />
                }
              </tbody>
            </Table>
            <Pagination pageNo={filter?.page} changePage={setPageNo} next={next} />
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default Branch;
