import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { offCanvaChange, sideBarChange } from "../Reducer/componentSlice"
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { getApiCall, postApiCall } from "gfdu";
import { toast } from "react-toastify";
import { useAuth } from "../Contexts/AuthenticateProvider"
import { decrypt, encrypt } from "../Helpers/Crypto";

const Header = () => {
  const { logOut } = useAuth()
  const dispatch = useDispatch()
  const component = useSelector((state) => state.component)
  const [logo, setLogo] = useState({ logo: "", favIcon: "" })
  const [width, setWidth] = useState(window.innerWidth);
  const changeBar = () => {
    dispatch(sideBarChange(!component?.sideBar))
  }
  const signOut = () => {
    postApiCall("/admin/logout", encrypt({}), (response) => {
      if (response?.success) {
        toast.success("Logout successfully")
        logOut()
      } else {
        toast.error("Logout failed")
      }
    })
  }
  useEffect(() => {
    getApiCall("/admin/business/view/site-details", (response) => {
      if (response?.success) {
        const decryptData = decrypt(response?.data)
        setLogo({
          logo: decryptData?.data?.logo,
          favIcon: decryptData?.data?.favIcon
        })
      }
    })
  }, [])
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])
  useEffect(() => {
    if (width < 992) {
      if (component.offCanva === false) {
        dispatch(sideBarChange(true))
      }
      dispatch(offCanvaChange(true))
    } else {
      dispatch(offCanvaChange(false))
    }
  }, [width])
  return (
    <React.Fragment>
      <header className="header">
        {width > 991 ?
          <>
            <div className={`logo ${component.sideBar ? "logo-full" : "logo-sm"}`}>{ logo?.logo && <a href="/dashboard"><img src={logo?.logo} alt="logo" /></a>}</div>
            <div className={`header-content ${component.sideBar ? "header-content-lg" : "header-content-sm"}`}>
              <div className="d-flex justify-content-center align-items-center">
                <i className="bi bi-list menu-btn" onClick={changeBar}></i>
              </div>
              <div className="d-flex justify-content-center align-items-center mx-4">
                <UncontrolledDropdown className="mx-3" >
                  <DropdownToggle className="nav-link cursor-pointer" tag="a">
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="mx-3 border rounded-circle px-2" >
                        <i className="bi fs-3 bi-person-fill"></i>
                      </div>
                      <div>Admin</div>
                    </div>
                  </DropdownToggle>
                  <DropdownMenu container="body">
                    <DropdownItem onClick={function noRefCheck() { }}>Profile</DropdownItem>
                    <DropdownItem onClick={signOut}>Logout</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </>
          :
          <div className="d-flex w-100 p-5 justify-content-end align-items-center">
            <i className="bi bi-list menu-btn" onClick={changeBar}></i>
          </div>
        }
      </header>
    </React.Fragment>
  );
};

export default Header;
