import React, { useState } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import Branch from '../Branch'
import Brand from './Brand'
import Support from './Support'
import PaymentTax from './PaymentTax'
import classnames from 'classnames';

const SiteSettings = () => {
  const [currentActiveTab, setCurrentActiveTab] = useState('1');
  const toggle = tab => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  }
  return (
    <React.Fragment>
      <div className='site-details mb-5'>
        <Nav tabs>
          <NavItem>
            <NavLink className={ "cursor-pointer  " + classnames({ active: currentActiveTab === '1' })} onClick={() => { toggle('1'); }}  >Brand</NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={ "cursor-pointer  " +classnames({ active: currentActiveTab === '2' })} onClick={() => { toggle('2'); }}  >Support</NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={ "cursor-pointer  " +classnames({ active: currentActiveTab === '3' })} onClick={() => { toggle('3'); }}  >Payment & Tax</NavLink>
          </NavItem>
        </Nav>
        <div className='tab-nav-content'>
          <TabContent activeTab={currentActiveTab}>
            <TabPane tabId="1"><Brand /></TabPane>
            <TabPane tabId="2"><Support /></TabPane>
            <TabPane tabId="3"><PaymentTax /></TabPane>
          </TabContent>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SiteSettings