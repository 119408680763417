import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Table, Input, FormGroup, Label } from 'reactstrap'
import { textCapital } from '../../../Helpers/Utils'
import { useDispatch, useSelector } from 'react-redux'
import { getApiCall, patchApiCall, reloadWindowToPath } from 'gfdu'
import { associateDataChange, associateDataEmpty, associateViewChange } from '../../../Reducer/associateSlice'
import { decrypt, encrypt } from '../../../Helpers/Crypto'
import { toast } from 'react-toastify'
import { contentLoadingChange } from '../../../Reducer/componentSlice'
import PlaceHolder from '../../../Helpers/Components/PlaceHolder'

const PanUpdate = (props) => {
    const customerId = props.customerId
    const dispatch = useDispatch()
    const associateView = useSelector((state) => state.associate)?.associateView
    const associateData = useSelector((state) => state.associate)?.associateData
    const [buttonDisable, setButtonDisable] = useState(false)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setLoading(false)
        getApiCall(`/admin/associate/details/${customerId}`, (response) => {
            if (response?.success) {
                const decryptedData = decrypt(response?.data)
                if (decryptedData?.success) {
                    dispatch(associateViewChange(decryptedData?.data))
                } else {
                    dispatch(associateViewChange({}))
                }
            } else {
                dispatch(associateViewChange({}))
                toast.error("Unable to Retrive Data")
            }
            setLoading(true)
        })
    }, [])
    const handleChange = (e) => {
        dispatch(associateDataChange({ [e.target.name]: e.target.value }))
    }
    const panVerify = () => {
        dispatch(contentLoadingChange(true))
        setButtonDisable(true)
        if (associateData?.panNo?.length !== 10) {
            setButtonDisable(false)
            dispatch(contentLoadingChange(false))
            toast.error("Invalid Pan No")
        } else {
            patchApiCall(`/admin/associate/kyc/pan/verify/${customerId}`, encrypt({ panNo: (associateData?.panNo).toUpperCase() }), (response) => {
                if (response?.success) {
                    toast?.success("Pan Vertified Successfully")
                    reloadWindowToPath(`/associate/${customerId}`)
                } else {
                    setButtonDisable(false)
                    toast.error(response?.msg || response?.message || "Invalid Pan No")
                }
                dispatch(contentLoadingChange(false))
            })
        }
    }
    useEffect(() => {
        dispatch(associateDataEmpty())
    }, [])
    return (
        <React.Fragment>
            <Row className='my-2 flex-column justify-content-center align-items-center' lg={2} >
                <Col>
                    <FormGroup row className='justify-content-center'>
                        <Label for="name" sm={3}>Host Id : </Label>
                        <Col sm={5}>{loading ? (associateView?.associateId && associateView?.associateId) : <PlaceHolder />}</Col>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup row className='justify-content-center'>
                        <Label for="name" sm={3}>Name : </Label>
                        <Col sm={5}>{loading ? (associateView?.name && textCapital(associateView?.name)) : <PlaceHolder />}</Col>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup row className='justify-content-center'>
                        <Label for="name" sm={3}>Email ID : </Label>
                        <Col sm={5}>{loading ? (associateView?.email?.emailId && associateView?.email?.emailId) : <PlaceHolder />}</Col>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup row className='justify-content-center'>
                        <Label for="name" sm={3}>Phone No : </Label>
                        <Col sm={5}>{loading ? (associateView?.phone && associateView?.phone) : <PlaceHolder />}</Col>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup row className='justify-content-center'>
                        <Label for="name" sm={3}>Aadhar No : </Label>
                        <Col sm={5}>{loading ? (associateView?.kyc?.aadhar?.aadharNo && associateView?.kyc?.aadhar?.aadharNo) : <PlaceHolder />}</Col>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup row className='justify-content-center'>
                        <Label for="name" sm={3}>Pan No : </Label>
                        <Col sm={5}><Input id="panNo" name="panNo" autoComplete='off' value={associateData?.panNo || ""} onChange={(e) => { e.target.value.length <= 10 && handleChange(e) }} className='input-theme input-size-1 text-upper' type="text" /></Col>
                    </FormGroup>
                </Col>
                <Col lg={12}><div className='text-center'><Button className='button-theme' disabled={buttonDisable} onClick={panVerify}>Verify Pan</Button></div></Col>
            </Row>
        </React.Fragment>
    )
}

export default PanUpdate;