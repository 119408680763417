import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    siteDetail:{
        logo: "https://res.cloudinary.com/dofjpl5cn/image/upload/v1717135110/site/logo.png",
        favIcon : "https://res.cloudinary.com/dofjpl5cn/image/upload/v1717135111/site/favIcon.png",
        siteName: "getMyDrive",
        siteTitle: "getMyDrive",
    },
    support: {
        email_1: "sakthivelan595@gmail.com",
        email_2: "sakthivelan5955@gmail.com",
        mobile_1: "9444028352",
        mobile_2: "9444028353"
    },
    payment: {
        razorpay: {
            publicKey: "1212",
            secretKey: "1212"
        },
        COD: true
    },
    tax: {
        tds: 0,
        gst: 10
    },
    refferral: {
        associate: 90,
        customer: 80
    }
};

const siteDetailSlice = createSlice({
    name: "siteDetail",
    initialState,
    reducers: {
        siteDetailChange: (state, { payload }) => {
            state.siteDetail = { ...state?.siteDetail , ...payload}
        },
        supportDetailsChange: (state, { payload }) => {
            state.support = { ...state?.support , ...payload}
        },
        paymentDetailsChange: (state, { payload }) => {
            state.payment = { ...state?.payment , ...payload}
        },
        taxDetailsChange:(state, { payload }) => {
            state.tax = { ...state?.tax , ...payload}
        }
    }
});

export const { siteDetailChange , paymentDetailsChange , supportDetailsChange , taxDetailsChange } = siteDetailSlice.actions
export default siteDetailSlice.reducer;
