import React from 'react'
import { Spinner } from 'reactstrap'

const Loader = () => {
  return (
    <React.Fragment>
      <div className='loader-page'>
        <Spinner type="grow"></Spinner>
        <Spinner type="grow"></Spinner>
        <Spinner type="grow"></Spinner>
        <Spinner type="grow"></Spinner>
        <Spinner type="grow"></Spinner>
        <Spinner type="grow"></Spinner>
        <Spinner type="grow"></Spinner>
        <Spinner type="grow"></Spinner>
        <Spinner type="grow"></Spinner>
        <Spinner type="grow"></Spinner>
      </div>
    </React.Fragment>
  )
}

export default Loader