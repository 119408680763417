import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";

const Pagination = (props) => {
    let [previousShow, setPreviousShow] = useState(true);
    let [nextShow, setNextShow] = useState(true);
    useEffect(() => {
        if (props?.pageNo === 0) {
            setPreviousShow(false);
        } else {
            setPreviousShow(true);
        }
        setNextShow(props?.next);
    }, [props?.next, props?.pageNo]);
    const previous = () => {
        props?.changePage(props?.pageNo - 1);
    };
    const next = () => {
        props?.changePage(props?.pageNo + 1);
    };
    return (
        <React.Fragment>
            <Container>
                <Row className="pagination-theme">
                    <Col className="d-flex justify-content-start">
                        {previousShow && (
                            <Button className="button-theme" onClick={previous}>
                              {"<<"}  Previous
                            </Button>
                        )}
                    </Col>
                    <Col className="d-flex justify-content-end">
                        {nextShow && (
                            <Button className="button-theme" onClick={next}>
                                Next {">>"}
                            </Button>
                        )}
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default Pagination;
