import { getApiCall, patchApiCall  } from 'gfdu'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { decrypt, encrypt } from '../../../Helpers/Crypto'
import { associateViewChange, associateDataChange } from '../../../Reducer/associateSlice'
import { contentLoadingChange } from '../../../Reducer/componentSlice'
import { toast } from 'react-toastify'
import { textCapital } from '../../../Helpers/Utils'
import PlaceHolder from '../../../Helpers/Components/PlaceHolder'

const AadharUpdate = (props) => {
    const dispatch = useDispatch()
    const associateView = useSelector((state) => state.associate)?.associateView
    const associateData = useSelector((state) => state.associate)?.associateData
    const [buttonDisable, setButtonDisable] = useState(true)
    const [disable, setDisable] = useState(false)
    const [loading, setLoading] = useState(true)
    const handleChange = (e) => {
        dispatch(associateDataChange({ [e.target.name]: e.target.value }))
    }
    const aadharOtpSend = () => {
        dispatch(contentLoadingChange(true))
        if (associateData?.aadharNo?.length !== 12 && Number(associateData?.aadharNo) == associateData?.aadharNo) {
            dispatch(contentLoadingChange(false))
            toast.error("Invalid Aadhar No")
        } else {
            setDisable(true)
            patchApiCall(`/admin/associate/kyc/aadhar/otp/${props.customerId}`, encrypt({ aadharNo: Number(associateData?.aadharNo) }), (response) => {
                if (response?.success) {
                    const decryptedData = decrypt(response?.data)
                    if (decryptedData?.success) {
                        const validKeys = {
                            reference_id: decryptedData?.data?.reference_id,
                            decentroTxnId: decryptedData?.data?.decentroTxnId
                        }
                        dispatch(associateDataChange(validKeys))
                        toast.success("Otp Sended")
                        setButtonDisable(false)
                    } else {
                        toast.error("Invalid Aadhar Card")
                    }
                } else {
                    toast.error(response?.msg || response?.message || "Invalid Aadhar No !")
                }
                dispatch(contentLoadingChange(false))
                setDisable(false)
            })
        }
    }
    const aadharVerify = () => {
        dispatch(contentLoadingChange(true))
        if (associateData?.aadharNo?.length !== 12) {
            dispatch(contentLoadingChange(false))
            toast.error("Invalid Aadhar No")
        } else if (associateData?.otp?.length !== 6) {
            dispatch(contentLoadingChange(false))
            toast.error("Invalid Aadhar Otp")
        } else {
            setDisable(true)
            const validKeys = {
                aadharNo: associateData?.aadharNo,
                reference_id: associateData.reference_id,
                decentroTxnId: associateData?.decentroTxnId,
                otp: associateData?.otp
            }
            patchApiCall(`/admin/associate/kyc/aadhar/verify/${props.customerId}`, encrypt(validKeys), (response) => {
                if (response?.success) {
                    toast?.success("Aadhar Vertified Successfully")
                    props.changeStep(3)
                } else {
                    toast.error(response?.msg || response?.message || "Invalid Aadhar No")
                }
                dispatch(contentLoadingChange(false))
                setDisable(false)
            })
        }
    }
    useEffect(() => {
        setLoading(false)
        dispatch(contentLoadingChange(true))
        getApiCall(`/admin/associate/details/${props.customerId}`, (response) => {
            if (response?.success) {
                const decryptedData = decrypt(response?.data)
                if (decryptedData?.success) {
                    dispatch(associateViewChange(decryptedData?.data))
                } else {
                    dispatch(associateViewChange({}))
                }
            } else {
                dispatch(associateViewChange({}))
                toast.error("Unable to Retrive Data")
            }
            setLoading(true)
            dispatch(contentLoadingChange(false))
        })
    }, [])
    useEffect(() => {
        dispatch(associateDataChange({ otp: "" }))
    }, [buttonDisable])
    return (
        <React.Fragment>
            {
                associateView?.name &&
                <Row className='my-2 flex-column justify-content-center align-items-center' lg={2} >
                    <Col>
                        <FormGroup row className='justify-content-center'>
                            <Label for="name" sm={3}>Host Id : </Label>
                            <Col sm={5}>{ loading ? (associateView?.associateId && associateView?.associateId) : <PlaceHolder/>}</Col>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup row className='justify-content-center'>
                            <Label for="name" sm={3}>Name : </Label>
                            <Col sm={5}>{loading ? (associateView?.name && textCapital(associateView?.name) ) : <PlaceHolder/> }</Col>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup row className='justify-content-center'>
                            <Label for="name" sm={3}>Email ID : </Label>
                            <Col sm={5}>{loading ? (associateView?.email?.emailId && associateView?.email?.emailId) : <PlaceHolder/>}</Col>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup row className='justify-content-center'>
                            <Label for="name" sm={3}>Phone No : </Label>
                            <Col sm={5}>{loading ? (associateView?.phone && associateView?.phone) : <PlaceHolder/> }</Col>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup row className='justify-content-center'>
                            <Label for="name" sm={3}>Aadhar No : </Label>
                            <Col sm={5}>  {buttonDisable ? <Input id="aadharNo" name="aadharNo" autoComplete='off' value={associateData?.aadharNo || ""} onChange={(e) => { e.target.value.length <= 12 && (Number(e.target.value) == e.target.value) && handleChange(e) }} className='input-theme input-size-2' type="text" /> : <><span className='me-3'>{associateData?.aadharNo}</span><i onClick={() => setButtonDisable(true)} className='bx bxs-pencil' ></i></>}</Col>
                        </FormGroup>
                    </Col>
                    {!buttonDisable &&
                        <Col>
                            <FormGroup row className='justify-content-center'>
                                <Label for="name" sm={3}>Otp : </Label>
                                <Col sm={5}><Input id="otp" name="otp" autoComplete='off' value={associateData?.otp || ""} onChange={(e) => { e.target.value.length <= 6 && (Number(e.target.value) == e.target.value) && handleChange(e) }} className='input-theme input-size-1' type="text" /></Col>
                            </FormGroup>
                        </Col>
                    }
                    <Col lg={12}><div className='text-center'>{buttonDisable ? <Button className='button-theme' disabled={associateData?.aadharNo?.length !== 12 || disable} onClick={aadharOtpSend}>Send Otp </Button> : <Button className='button-theme' disabled={disable} onClick={aadharVerify}>Verify Aadhar</Button>}</div></Col>
                </Row>
            }
        </React.Fragment>
    )
}

export default AadharUpdate