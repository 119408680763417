import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Table, Input, Dropdown, DropdownToggle, DropdownMenu, FormGroup, Label } from 'reactstrap'
import { formatDate, parseDateString, textCapital } from '../../../Helpers/Utils'
import { useDispatch, useSelector } from 'react-redux'
import { getApiCall, patchApiCall } from 'gfdu'
import { customerDataChange, customerDataEmpty, customerViewChange } from '../../../Reducer/customerSlice'
import { decrypt, encrypt } from '../../../Helpers/Crypto'
import { toast } from 'react-toastify'
import DatePicker from "react-multi-date-picker"
import "react-multi-date-picker/styles/backgrounds/bg-dark.css"
import { contentLoadingChange } from '../../../Reducer/componentSlice';
import PlaceHolder from '../../../Helpers/Components/PlaceHolder';

const PanUpdate = (props) => {
    const customerId = props.customerId
    const dispatch = useDispatch()
    const customerView = useSelector((state) => state.customer)?.customerView
    const customerData = useSelector((state) => state.customer)?.customerData
    const [loading,setLoading] = useState(true)
    const [dob, setDob] = useState((new Date()).setFullYear(((new Date()).getFullYear()) - 18))
    const [buttonDisable,setButtonDisable] = useState(false)
    useEffect(() => {
        setLoading(false)
        getApiCall(`/admin/customer/details/${customerId}`, (response) => {
            if (response?.success) {
                const decryptedData = decrypt(response?.data)
                if (decryptedData?.success) {
                    dispatch(customerViewChange(decryptedData?.data))
                    setDob(parseDateString(decryptedData?.data?.kyc?.aadhar?.aadharDetails?.proofOfIdentity?.dob) || (new Date()).setFullYear(((new Date()).getFullYear()) - 18))
                } else {
                    dispatch(customerViewChange({}))
                }
            } else {
                dispatch(customerViewChange({}))
                toast.error("Unable to Retrive Data")
            }
            setLoading(true)
        })
    }, [])
    const handleChange = (e) => {
        dispatch(customerDataChange({ [e.target.name]: e.target.value }))
    }
    const DlVerify = () => {
        dispatch(contentLoadingChange(true))
        setButtonDisable(true)
        const date = new Date(dob)
        const dateOfBirth = formatDate(date)
        if (!/^([A-Z]{2})(\d{2}|\d{3})[a-zA-Z]{0,1}(\d{4})(\d{7})$/.test(customerData?.dlNo?.toUpperCase())) {
            toast.error("Invalid DL No")
            dispatch(contentLoadingChange(false))
            setButtonDisable(true)
        } else {
            patchApiCall(`/admin/customer/kyc/dl/${customerId}`, encrypt({ dlNo: customerData?.dlNo?.toUpperCase(), dob: dateOfBirth }), (response) => {
                if (response?.success) {
                    toast?.success("DL Vertified Successfully")
                    props.changeStep(4)
                } else {
                    setButtonDisable(false)
                    toast.error(response?.msg || "Invalid dlNo")
                }
                dispatch(contentLoadingChange(false))
            })
        }
    }

    useEffect(() => {
        dispatch(customerDataEmpty())
    }, [])
    return (
        <React.Fragment>
            <Row className='my-2 flex-column justify-content-center align-items-center' lg={2} >
            <Col>
                    <FormGroup row className='justify-content-center align-items-center'>
                        <Label for="customerId" sm={3}>Customer ID</Label>
                        <Col sm={5}>{ loading ? (customerView?.customerId && customerView?.customerId) : <PlaceHolder/>}</Col>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup row className='justify-content-center align-items-center'>
                        <Label for="name" sm={3}>Name</Label>
                        <Col sm={5}>{ loading ? (customerView?.name && textCapital(customerView?.name)) : <PlaceHolder/> }</Col>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup row className='justify-content-center align-items-center'>
                        <Label for="email" sm={3}>Email ID</Label>
                        <Col sm={5}>{ loading ? (customerView?.email?.emailId && customerView?.email?.emailId) : <PlaceHolder/>}</Col>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup row className='justify-content-center align-items-center'>
                        <Label for="phone" sm={3}>Phone No</Label>
                        <Col sm={5}>{ loading ? (customerView?.phone && customerView?.phone) : <PlaceHolder/>}</Col>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup row className='justify-content-center align-items-center'>
                        <Label for="phone" sm={3}>Aadhar No</Label>
                        <Col sm={5}>{ loading ? (customerView?.kyc?.aadhar?.aadharNo && customerView?.kyc?.aadhar?.aadharNo) : <PlaceHolder/>}</Col>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup row className='justify-content-center align-items-center'>
                        <Label for="phone" sm={3}>License No</Label>
                        <Col sm={5}><Input id="dlNo" name="dlNo" autoComplete='off' value={customerData?.dlNo || ""} onChange={(e) => { e.target.value.length <= 15 && handleChange(e) }} className='input-theme input-size-1 text-upper' type="text" /></Col>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup row className='justify-content-center align-items-center'>
                        <Label for="phone" sm={3}>DOB</Label>
                        <Col sm={5}><DatePicker className='calender-theme' format='DD-MM-YYYY' inputClass="date-theme" value={dob} onChange={setDob} minDate={(new Date()).setFullYear(((new Date()).getFullYear()) - 100)} maxDate={(new Date()).setFullYear(((new Date()).getFullYear()) - 18)} /></Col>
                    </FormGroup>
                </Col>
                <Col lg={12}><div className='text-center'><Button className='button-theme' disabled={buttonDisable} onClick={DlVerify}>Verify</Button></div></Col>
            </Row>
        </React.Fragment>
    )
}

export default PanUpdate;