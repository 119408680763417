import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import { sideBarChange } from "../Reducer/componentSlice"
import { postApiCall } from "gfdu";
import { encrypt } from "../Helpers/Crypto";
import { toast } from "react-toastify";
import { useAuth } from "../Contexts/AuthenticateProvider";


const Sidebar = () => {
    const { logOut } = useAuth()
    const dispatch = useDispatch()
    const component = useSelector((state) => state.component);
    const isActive = ({ isActive }) => ({ color: isActive ? '#ff4d32' : '#878a99' })
    // const [associate, setAssociate] = useState(false);
    // const associateToggle = () => setAssociate(!associate);
    const signOut = () => {
        postApiCall("/admin/logout", encrypt({}), (response) => {
          if (response?.success) {
            toast.success("Logout successfully")
            logOut()
          } else {
            toast.error("Logout failed")
          }
        })
      }
    return (
        <React.Fragment>
            {
                component?.offCanva ?
                    <>
                        <Offcanvas direction="end" isOpen={!component?.sideBar} toggle={() => dispatch(sideBarChange(!component?.sideBar))}>
                            <OffcanvasHeader toggle={() => dispatch(sideBarChange(!component?.sideBar))}></OffcanvasHeader>
                            <OffcanvasBody>
                            <div className="sidebar-menu">
                                        <ul className=" mx-4 text-start">
                                            <li><NavLink to={"/dashboard"} style={isActive} ><i className="bi bi-house"></i>Dashboard</NavLink></li>
                                            <li> <NavLink to={"/site-settings"} style={isActive} > <i className='bx bxs-objects-horizontal-left'></i>Site Details</NavLink></li>
                                            <li><NavLink to={"/branch"} style={isActive} ><i className='bx bx-current-location'></i>Branch</NavLink></li>
                                            <li><NavLink to={"/associate"} style={isActive} ><i className='bx bxs-face'></i>Associate</NavLink></li>
                                            <li><NavLink to={"/customer"} style={isActive} ><i className='bx bxs-user-account'></i>Customer</NavLink></li>
                                            <li><NavLink to={"/vehicle"} style={isActive} ><i className="fa-solid fa-truck-ramp-box"></i>Vehicle</NavLink></li>
                                            <li><NavLink to={"/car"} style={isActive} ><i className="bi bi-car-front"></i>Car</NavLink></li>
                                            <li><NavLink to={"/bike"} style={isActive} ><i className="fa-solid fa-motorcycle"></i>Bike</NavLink></li>
                                            <li><NavLink onClick={signOut}><i className='bx bx-power-off'></i>Logout</NavLink></li>
                                        </ul>
                                    </div>
                            </OffcanvasBody>
                        </Offcanvas>
                    </>
                    :
                    <>
                        <div className={`sidebar ${component?.sideBar ? "sidebar-lg" : "sidebar-sm"}`}>
                            {
                                component?.sideBar ?
                                    <div className="sidebar-menu">
                                        <ul className=" mx-4 text-start">
                                            <li><NavLink to={"/dashboard"} style={isActive} ><i className="bi bi-house"></i>Dashboard</NavLink></li>
                                            <li> <NavLink to={"/site-settings"} style={isActive} > <i className='bx bxs-objects-horizontal-left'></i>Site Details</NavLink></li>
                                            {/* <li> <NavLink style={isActive} onClick={associateToggle} ><i className="bi bi-sliders"></i>Associate</NavLink>
                                    <Collapse isOpen={associate}>
                                        <ul className="sub-links">
                                            <li><NavLink to={"/site-settings"} style={isActive} > <i className='bx bxs-objects-horizontal-left'></i>Site Details</NavLink></li>
                                            <li><NavLink to={"/branch"} style={isActive} ><i className='bx bxs-location-plus'></i>Branch</NavLink></li>
                                        </ul>
                                    </Collapse>
                                </li> */}
                                            <li><NavLink to={"/branch"} style={isActive} ><i className='bx bx-current-location'></i>Branch</NavLink></li>
                                            <li><NavLink to={"/associate"} style={isActive} ><i className='bx bxs-face'></i>Associate</NavLink></li>
                                            <li><NavLink to={"/customer"} style={isActive} ><i className='bx bxs-user-account'></i>Customer</NavLink></li>
                                            <li><NavLink to={"/vehicle"} style={isActive} ><i className="fa-solid fa-truck-ramp-box"></i>Vehicle</NavLink></li>
                                            <li><NavLink to={"/car"} style={isActive} ><i className="bi bi-car-front"></i>Car</NavLink></li>
                                            <li><NavLink to={"/bike"} style={isActive} ><i className="fa-solid fa-motorcycle"></i>Bike</NavLink></li>
                                        </ul>
                                    </div>
                                    :
                                        <ul className="text-center my-3">
                                            <li className="my-2" ><NavLink to={"/dashboard"} style={isActive} ><i className="bi bi-house fs-3"></i></NavLink></li>
                                            <li className="my-2" ><NavLink to={"/site-settings"} style={isActive} > <i className='bx bxs-objects-horizontal-left fs-3'></i></NavLink></li>
                                            <li className="my-2" ><NavLink to={"/branch"} style={isActive} ><i className='bx bx-current-location fs-3'></i></NavLink></li>
                                            <li className="my-2" ><NavLink to={"/associate"} style={isActive} ><i className='bx bxs-face fs-3'></i></NavLink></li>
                                            <li className="my-2" ><NavLink to={"/customer"} style={isActive} ><i className='bx bxs-user-account fs-3'></i></NavLink></li>
                                            <li className="my-2" ><NavLink to={"/car"} style={isActive} ><i className="fa-solid fa-truck-ramp-box"></i></NavLink></li>
                                            <li className="my-2" ><NavLink to={"/car"} style={isActive} ><i className="bi bi-car-front fs-3"></i></NavLink></li>
                                            <li className="my-2" ><NavLink to={"/bike"} style={isActive} ><i className="fa-solid fa-motorcycle fs-3"></i></NavLink></li>
                                        </ul>

                            }
                        </div>
                    </>
            }
        </React.Fragment>
    );
};

export default Sidebar;
