import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import CarList from './CarList'
import BikeList from './BikeList'
import { toast } from 'react-toastify'
import Select from "react-select";
import { getApiCall, patchApiCall, postApiCall, reloadWindow } from 'gfdu'
import { decrypt, encrypt } from '../../Helpers/Crypto'

const BrandList = () => {
  const [addBrand, setAddBrand] = useState(false)
  const [editBrand, setEditBrand] = useState(false)
  const [buttonDisable, setButtonDisable] = useState(false)
  const [brand, setBrand] = useState(
    {
      brandName: "",
      brandType: "",
      brandId: ""
    }
  )
  const Option = [
    { label: "Cars", value: "Cars" },
    { label: "Bikes", value: "Bikes" },
  ]
  const setSelect = (e, name) => {
    setBrand((prev) => ({
      ...prev,
      brandType: e.value
    }))
  }
  const handleChange = (e) => {
    setBrand((prev) => ({
      ...prev,
      brandName: e.target?.value
    }))
  }

  const openEdit = (id) => {
    getApiCall(`/admin/vehicle/brand/${id}`, (response) => {
      if (response?.success) {
        const data = decrypt(response?.data)?.data
        setBrand({
          brandName: data?.brand,
          brandType: data?.brandType,
          brandId: data?.brandId
        })
        setEditBrand(true)
      } else {
        toast?.error("Invalid Id")
      }
    })
  };
  const registerBrand = () => {
    if (brand?.brandName === "" || brand?.brandName?.trim().length === 0 || brand?.brandName === undefined || brand?.brandName === null || Number(brand?.brandName) === "number") {
      toast?.error("Invalid Brand Name")
    } else if (brand?.brandType === "") {
      toast?.error("Invalid Brand Type")
    } else {
      setButtonDisable(true)
      postApiCall("/admin/vehicle/brand", encrypt({ brand: brand?.brandName, brandType: brand?.brandType }), (response) => {
        if (response?.success) {
          toast?.success("Registered Successfully")
          setTimeout(() => {
            reloadWindow()
          }, 2000)
        } else {
          toast?.error(response?.message)
          setButtonDisable(false)
        }
      })
    }
  }
  const updateBrand = () => {
    if (brand?.brandName === "" || brand?.brandName?.trim().length === 0 || brand?.brandName === undefined || brand?.brandName === null || Number(brand?.brandName) === "number") {
      toast?.error("Invalid Brand Name")
    } else if (brand?.brandType === "") {
      toast?.error("Invalid Brand Type")
    } else {
      setButtonDisable(true)
      patchApiCall(`/admin/vehicle/brand/${brand?.brandId}`, encrypt({ brand: brand?.brandName, brandType: brand?.brandType }), (response) => {
        if (response?.success) {
          toast?.success("Updated Successfully")
          setTimeout(() => {
            reloadWindow()
          }, 2000)
        } else {
          toast?.error(response?.message)
          setButtonDisable(false)
        }
      })
    }
  }
  useEffect(() => {
    setBrand({
      brandName: "",
      brandType: ""
    })
  }, [addBrand])
  return (
    <React.Fragment>
      <Modal isOpen={addBrand} toggle={() => setAddBrand(!addBrand)}>
        <ModalHeader toggle={() => setAddBrand(!addBrand)} ></ModalHeader>
        <ModalBody>
          <Row xs={1}>
            <Col>
              <h4 className='text-center text-theme my-3'>Create Brand</h4>
              <FormGroup row className='justify-content-center'>
                <Label for="brand" sm={3}>Brand Name</Label>
                <Col sm={5}>
                  <Input id="brand" name="brand" autoComplete='off' className='input-theme text-upper input-size-2' type="text" value={brand?.brandName} onChange={handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row className='justify-content-center'>
                <Label for="brandType" sm={3}>Brand Type</Label>
                <Col sm={5}>
                  <Select id="brandType" name="brandType" autoComplete='off'
                    styles={{
                      control: (styles) => ({ ...styles, borderColor: "#ff4d32", "&:hover": { boxShadow: "0 0 0 3px #ff4d3215", borderColor: "#ff4d32" } }),
                      option: (style, state) => ({ ...style, "&:hover": { backgroundColor: state.isSelected ? "#ff4d32" : "#ff4d3215" }, backgroundColor: state.isSelected && "#ff4d32" }),
                    }}
                    className='input-size-2'
                    options={Option}
                    onChange={(e) => setSelect(e, "brandType")}
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col className='m-2 text-center'><Button className='button-theme' disabled={buttonDisable} onClick={registerBrand}>Register</Button></Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal isOpen={editBrand} toggle={() => setEditBrand(!editBrand)}>
        <ModalHeader toggle={() => setEditBrand(!editBrand)} ></ModalHeader>
        <ModalBody>
          <Row xs={1}>
            <Col>
              <h4 className='text-center text-theme my-3'>Edit Brand</h4>
              <FormGroup row className='justify-content-center'>
                <Label for="brand" sm={3}>Brand Name</Label>
                <Col sm={5}>
                  <Input id="brand" name="brand" autoComplete='off' className='input-theme text-upper input-size-2' type="text" value={brand?.brandName} onChange={handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row className='justify-content-center'>
                <Label for="brandType" sm={3}>Brand Type</Label>
                <Col sm={5}>
                  <Select id="brandType" isDisabled={true} name="brandType" autoComplete='off'
                    styles={{
                      control: (styles) => ({ ...styles, borderColor: "#ff4d32", "&:hover": { boxShadow: "0 0 0 3px #ff4d3215", borderColor: "#ff4d32" } }),
                      option: (style, state) => ({ ...style, "&:hover": { backgroundColor: state.isSelected ? "#ff4d32" : "#ff4d3215" }, backgroundColor: state.isSelected && "#ff4d32" }),
                    }}
                    className='input-size-2'
                    options={Option}
                    value={{ label: brand?.brandType, value: brand?.brandType }}
                    onChange={(e) => setSelect(e, "brandType")}
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col className='m-2 text-center'><Button className='button-theme' disabled={buttonDisable} onClick={updateBrand}>Update</Button></Col>
          </Row>
        </ModalBody>
      </Modal>
      <Card>
        <CardBody>
          <Row className='text-center'>
            <Col><h3 className='text-theme'>Brand Details</h3></Col>
          </Row>
          <Row className='text-end'>
            <Col className='m-2'><Button className='button-theme' onClick={() => setAddBrand(!addBrand)}>Brand Add</Button></Col>
          </Row>
          <Row sm={3} className='justify-content-evenly'>
            <Col>
              <Card className="branch-card table-card">
                <CardBody >
                  <CarList openEdit={openEdit} />
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card className="branch-card table-card">
                <CardBody>
                  <BikeList openEdit={openEdit} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default BrandList
