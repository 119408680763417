import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email:"",
  password:"",
  otp:""
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    dataChange : (state,{payload}) => {
      state[payload[0]] = payload[1]
    }
  }
});

export const {dataChange} = loginSlice.actions
export default loginSlice.reducer;
