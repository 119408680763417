import { useEffect } from "react";
import React from "react"
import { Outlet } from "react-router-dom";
import { useAuth } from "../Contexts/AuthenticateProvider";
import { reloadWindowToPath } from "gfdu";
import Layout from "../Layout";


function PrivateRoutes({ children }) {
  const { isAuthenticated } = useAuth();
  useEffect(() => {
    if (!isAuthenticated) {
      reloadWindowToPath("/login");
    }
  }, [isAuthenticated]);

  return (
    <React.Fragment>
      {isAuthenticated && (
        <>
          <Layout>
            {children}
            <Outlet />
          </Layout>
        </>
      )}
    </React.Fragment>
  );
}

export default PrivateRoutes;
