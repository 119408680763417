import React, { useEffect, useState } from 'react'
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Pagination, Row, Table, UncontrolledDropdown } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { contentLoadingChange } from '../../Reducer/componentSlice'
import { useNavigate } from 'react-router-dom'
import { postApiCall } from 'gfdu'
import { decrypt, encrypt } from '../../Helpers/Crypto'
import { bikeBrandListChange } from "../../Reducer/bikeVehicleSlice"

const BikeList = (props) => {
    const dispatch = useDispatch()
    const bikeVehicle = useSelector((state) => state.bikeVehicle)
    const navigate = useNavigate()
    const [next, setNext] = useState(false)
    const [filter, setFilter] = useState({
        page: 0,
        count: 10
    })
    const search = (e) => {
        setFilter((prev) => ({
            ...prev,
            filter: e.target.value
        }))
    }
    const setPageNo = (value) => {
        setFilter((prev) => ({ ...prev, page: value }))
    }
    useEffect(() => {
        dispatch(contentLoadingChange(true))
        dispatch(bikeBrandListChange([]))
        const filterData = {
            ...(filter?.filter && { search: filter?.filter }),
            vehicleType: "Bike"
        }
        postApiCall(`/admin/vehicle/brand/list/${filter?.page}`, encrypt(filterData), (response) => {
            if (response?.success) {
                const decryptedData = decrypt(response?.data)
                if (decryptedData?.success) {
                    dispatch(bikeBrandListChange(decryptedData?.data?.currentPage))
                    setNext(decryptedData?.data?.next)
                } else {
                    dispatch(bikeBrandListChange([]))
                    setNext(false)
                }
            } else {
                dispatch(bikeBrandListChange([]))
                setNext(false)
            }
            dispatch(contentLoadingChange(false))
        })
    }, [filter])
    return (
        <React.Fragment>
            <Row>
                <Col className='text-center text-theme fs-3'>Bike</Col>
            </Row>
            <Row xs={4} className='justify-content-between' >
                <Col><Input className='search-box input-size-2' placeholder='Search...' value={filter?.filter} onChange={search} /></Col>
            </Row>
            <Row xs={5}>
                <Col xs={12}>
                    <Table className="table-bg" borderless >
                        <thead>
                            <tr className="border-bottom text-middle" >
                                <th>S.No</th>
                                <th>Brand Id</th>
                                <th>Brand Name</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                bikeVehicle &&
                                    bikeVehicle?.bikeBrandList?.length !== 0 ?
                                    bikeVehicle?.bikeBrandList?.map((value, i) => {
                                        return (
                                            <tr key={i}>
                                                <td className='text-center'>{i + 1}</td>
                                                <td className='text-center'>{value?.brandId}</td>
                                                <td className='text-center'>{value?.brand}</td>
                                                <td className='text-center'>
                                                    <UncontrolledDropdown className="mx-3" >
                                                        <DropdownToggle className="nav-link not-button cursor-pointer"><i className="fa-solid fa-ellipsis-vertical"></i></DropdownToggle>
                                                        <DropdownMenu container="body">
                                                            <DropdownItem onClick={() => navigate(`/vehicle/${value?.brandId}`)}>View</DropdownItem>
                                                            <DropdownItem onClick={() => props?.openEdit(value?.brandId)}>Edit</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </td>
                                            </tr>
                                        )
                                    }) :
                                    <tr className='text-center'>
                                        <td colSpan={4}>No Data Found</td>
                                    </tr>
                            }
                        </tbody>
                    </Table>
                    <Pagination pageNo={filter?.page} changePage={setPageNo} next={next} />
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default BikeList
