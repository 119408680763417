import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { carDataChange, carDataEmpty, carViewChange, carViewEmpty } from '../../../Reducer/carSlice';
import { getApiCall } from 'gfdu';
import { decrypt } from '../../../Helpers/Crypto';
import { toast } from 'react-toastify';
import Loader from '../../../Helpers/Components/Loader';
import CarDetails from './CarDetails';

const Index = () => {
    const { id } = useParams("id");
    const dispatch = useDispatch()
    const car = useSelector((state) => state.car)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        dispatch(carViewEmpty())
        dispatch(carDataEmpty())
        setLoading(true)
        getApiCall(`/admin/car/${id}`, (response) => {
            if (response?.success) {
                const decryptedData = decrypt(response?.data)
                if (decryptedData?.success) {
                    dispatch(carViewChange(decryptedData?.data))
                    dispatch(carDataChange(decryptedData?.data))
                } else {
                    dispatch(carViewEmpty())
                }
            } else {
                dispatch(carViewEmpty())
                toast.error("Unable to Retrive Data")
            }
            setLoading(false)
        })
    }, [])
    return (
        <React.Fragment>
            {
                loading ?
                    <Loader />
                    :
                    car?.carView?.carId !== undefined ?
                        <>
                            <CarDetails carId={id} />
                        </>
                        :
                        ""
            }
            {/* <ProfileCard/> */}
        </React.Fragment>
    );
}

export default Index;
