import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'reactstrap'
import { randomId } from '../../../Helpers/Utils'
import { toast } from 'react-toastify'
import { postApiCall, reloadWindowToPath } from 'gfdu'
import { contentLoadingChange } from '../../../Reducer/componentSlice'
import { useDispatch } from 'react-redux'

const ImageUpload = (props) => {
    const dispatch = useDispatch()
    const [imageTemp, setImageTemp] = useState([])
    const [buttonDisable, setButtonDisable] = useState(false)
    const imageChange = (e) => {
        const imageDatas = Object?.values(e.target.files)
        if (imageDatas && imageDatas?.length !== 0) {
            imageDatas?.map((value) => {
                if (value?.size <= 10000000) {
                    setImageTemp((prev) => {
                        return [
                            ...prev,
                            {
                                imgId: randomId(),
                                file: value,
                                url: URL.createObjectURL(value)
                            }
                        ]
                    })
                } else {
                    toast?.error(`Image Must to be below 20 MB`)
                }
            })
        } else {
            toast?.error("kindly select correct images")
        }
    };
    const cancelImage = (imgId) => {
        setImageTemp(
            imageTemp?.filter((value) => {
                return value?.imgId !== imgId
            })
        )
    }
    const submitImage = () => {
        dispatch(contentLoadingChange(true))
        setButtonDisable(true)
        if (imageTemp?.length === 5) {
            const formData = new FormData()
            imageTemp?.forEach((value) => {
                formData?.append("bike", value?.file)
            })
            postApiCall(`/admin/bike/bikeImage/${props?.bikeId}`, formData, (response) => {
                if (response?.success) {
                    toast?.success("Image is Uploaded Successfully")
                    setTimeout(() => {
                        reloadWindowToPath(`/bike/${props?.bikeId}`)
                    }, 2000);
                } else {
                    setButtonDisable(false)
                    toast.error(response?.msg || response.message || "Image is Upload Failed");
                }
                dispatch(contentLoadingChange(false))
            })
        } else {
            setButtonDisable(false)
            dispatch(contentLoadingChange(false))
            toast?.error("Must Upload more than 5 Images")
        }
    }
    useEffect(() => {
        if (imageTemp?.length > 5) {
            setImageTemp(imageTemp.slice(0, 5))
        }
    }, [imageTemp])
    return (
        <React.Fragment>
            <Row className='justify-content-center align-items-center'>
                <div className='image-add-card'>
                    {
                        imageTemp && imageTemp?.length !== 0 &&
                        imageTemp?.map((value, i) => {
                            if (i < 5) {
                                return (
                                    <div className='image-view-box' key={value?.imgId}>
                                        <span className='cancel-image' onClick={() => cancelImage(value?.imgId)} ><i className='bx bx-x'></i></span>
                                        <span ><img src={value?.url} alt={value?.imgId} /></span>
                                    </div>
                                )
                            }
                        })
                    }

                    {
                        imageTemp && imageTemp?.length < 5 &&
                        <div className='image-box' ><input onChange={imageChange} type='file' multiple="multiple" accept="image/png,image/jpeg" /> <i className="bi bi-plus-lg"></i></div>
                    }
                </div>
            </Row>
            <Row className='text-center'><Col className='my-4'><Button className='button-theme' disabled={buttonDisable} onClick={submitImage} >Submit</Button></Col><Col className='my-4'><Button color='danger' onClick={() => props?.setImageOpen(false)} >Close</Button></Col></Row>
        </React.Fragment>
    )
}

export default ImageUpload