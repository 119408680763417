import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    bikeList : [],
    bikeView : {},
    bikeData : {},
    togglers : {
      emailOpen : false,
      emailOtp : false,
      reasonOpen : false
    }
};

const bikeSlice = createSlice({
  name: "bike",
  initialState,
  reducers: {
    bikeDataEmpty : (state,{payload}) =>{
      state.bikeData = {}
    },
    bikeListEmpty : (state,{payload}) =>{
      state.bikeList = []
    },
    bikeViewEmpty : (state,{payload}) =>{
      state.bikeView = {}
    },
    bikeListChange : (state,{payload}) => {
      state.bikeList = payload
    },
    bikeViewChange : (state,{payload}) => {
      state.bikeView = { ...state?.bikeView , ...payload}
    },
    bikeDataChange : (state,{payload}) => {
      state.bikeData = { ...state?.bikeData , ...payload}
    },
    togglersChange : (state,{payload}) => {
      state.togglers = { ...state?.togglers,...payload}
    }  
  }
});

export const { bikeListChange , bikeViewChange , bikeDataChange , togglersChange , bikeDataEmpty , bikeListEmpty, bikeViewEmpty } = bikeSlice.actions
export default bikeSlice.reducer;
