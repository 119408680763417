import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { associateDataChange, associateDataEmpty } from '../../../Reducer/associateSlice';
import { contentLoadingChange } from "../../../Reducer/componentSlice"
import { toast } from 'react-toastify';
import { postApiCall } from "gfdu";
import { decrypt, encrypt } from "../../../Helpers/Crypto";
import Timer from '../../../Helpers/Components/Timer';

const Registration = (props) => {
    const fileInputRef = useRef(null);
    const dispatch = useDispatch();
    const formData = new FormData();
    const associateData = useSelector((state) => state.associate?.associateData || {});
    const [phoneOtp, setPhoneOtp] = useState(false);
    const [emailOtp, setEmailOtp] = useState(false);
    const [otpTimer, setOtpTimer] = useState({ phone: false, email: false })
    const [buttonDisable, setButtonDisable] = useState(false);
    const handleChange = (e) => {
        dispatch(associateDataChange({ [e.target.name]: e.target.value }));
    };
    const radioChange = (e) => {
        dispatch(associateDataChange({ gender: e.target.value }));
    };
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file !== undefined) {
            fileInputRef.current = file;
            dispatch(associateDataChange({ photoName: file.name, photoSize: file.size }));
        }
    };
    const sendOtp = (url, data, setType, name) => {
        dispatch(contentLoadingChange(true))
        postApiCall(url, encrypt(data), (response) => {
            if (response?.success) {
                setType(true);
                toast.success("OTP sent successfully");
                setOtpTimer((prev) => ({ ...prev, [name]: true }))
            } else {
                toast.error(response?.message || response.msg || "OTP not sent");
            }
            dispatch(contentLoadingChange(false))
        });
    };
    const onPhoneOtp = () => {
        dispatch(contentLoadingChange(true))
        if (!/^[0-9]{10}$/.test(associateData?.phone)) {
            dispatch(contentLoadingChange(false))
            toast.error("Invalid Phone Number");
        } else {
            sendOtp("/admin/associate/registration/phone-otp", { phone: associateData?.phone }, setPhoneOtp, "phone");
        }
    };
    const onMailOtp = () => {
        dispatch(contentLoadingChange(true))
        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(associateData?.email)) {
            dispatch(contentLoadingChange(false))
            toast.error("Invalid Email Id");
        } else {
            sendOtp("/admin/associate/registration/email-otp", { email: associateData?.email }, setEmailOtp, "email");
        }
    };
    const validateForm = () => {
        if (!/^[a-zA-Z\s]{2,}$/.test(associateData?.name)) {
            toast.error("Invalid Name");
            return false;
        }
        if (!["male", "female"].includes(associateData?.gender)) {
            toast.error("Invalid Gender");
            return false;
        }
        if (!/^[0-9]{10}$/.test(associateData?.phone)) {
            toast.error("Invalid Phone Number");
            return false;
        }
        if (!/^[0-9]{6}$/.test(associateData?.mobileOtp)) {
            toast.error("Invalid Phone OTP");
            return false;
        }
        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(associateData?.email)) {
            toast.error("Invalid Email Id");
            return false;
        }
        if (!/^[0-9]{5}$/.test(associateData?.mailOtp)) {
            toast.error("Invalid Email OTP");
            return false;
        }
        if (fileInputRef.current === null || fileInputRef.current === undefined) {
            toast.error("Invalid Profile");
            return false;
        }
        return true;
    };
    const onSubmit = () => {
        dispatch(contentLoadingChange(true))
        setButtonDisable(true)
        if (!validateForm()) {
            dispatch(contentLoadingChange(false))
            return setButtonDisable(false);
        }
        for (const key in associateData) {
            formData.append(key, associateData[key]);
        }
        if (fileInputRef.current) {
            formData.append('photo', fileInputRef.current);
        }
        postApiCall("/admin/associate/registration", formData, (response) => {
            if (response?.success) {
                const decryptedData = decrypt(response?.data);
                if (decryptedData?.success) {
                    props.setCustomerId(decryptedData?.data?.associateId);
                    toast.success("Registered Successfully");
                    setTimeout(() => {
                        props.changeStep(2);
                        dispatch(contentLoadingChange(false))
                    }, 2000);
                    dispatch(associateDataChange({}));
                } else {
                    dispatch(contentLoadingChange(false))
                    setButtonDisable(false)
                    toast.error("Registration Failed");
                }
            } else {
                dispatch(contentLoadingChange(false))
                setButtonDisable(false)
                toast.error(response.msg || response.message || "Registration Failed");
            }
        })
    };

    useEffect(() => {
        dispatch(associateDataEmpty({}));
    }, []);

    return (
        <React.Fragment>
            <Row className='my-2 flex-column'>
                <Col>
                    <FormGroup row className='justify-content-center'>
                        <Label for="name" sm={3}>Name</Label>
                        <Col sm={5}>
                            <Input id="name" name="name" autoComplete='off' onChange={handleChange} value={associateData.name || ''} className='input-theme text-upper' type="text" />
                        </Col>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup row className='justify-content-center'>
                        <Label for="gender" sm={3}>Gender</Label>
                        <Col sm={5} className='d-flex justify-content-evenly align-items-center'>
                            <div>
                                <Input name="gender" id='male' className='radio-theme' onChange={radioChange} value="male" checked={associateData.gender === 'male'} type="radio" />
                                <Label for="male" className='mx-2'>Male</Label>
                            </div>
                            <div>
                                <Input name="gender" id='female' onChange={radioChange} value="female" checked={associateData.gender === 'female'} type="radio" />
                                <Label for="female" className='mx-2'>Female</Label>
                            </div>
                        </Col>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup row className='justify-content-center'>
                        <Label for="phone" sm={3}>Phone No</Label>
                        <Col sm={5}>
                            <Row>
                                <Col lg={9} className='d-flex align-items-center'>{!phoneOtp ? <Input id="phone" name="phone" autoComplete='off' onChange={(e) => (e.target.value.length <= 10 && Number(e.target.value) == e.target.value) ? handleChange(e) : ""} value={associateData.phone || ''} className='input-theme' type="text" /> : `${associateData?.phone}`}</Col>
                                <Col lg={3} className='text-center my-2' ><Button className='button-theme' disabled={otpTimer.phone} onClick={onPhoneOtp}>{!phoneOtp ? "OTP" : "Resend"}</Button></Col>
                            </Row>
                        </Col>
                    </FormGroup>
                </Col>
                {phoneOtp && (
                    <Col>
                        <FormGroup row className='justify-content-center'>
                            <Label for="mobileOtp" sm={3}>Phone OTP</Label>
                            <Col sm={5}>
                                <Row>
                                    <Col xs={10} lg={5}><Input id="mobileOtp" name="mobileOtp" autoComplete='off' onChange={(e) => (e.target.value.length <= 6 && Number(e.target.value) == e.target.value) ? handleChange(e) : ""} value={associateData.mobileOtp || ''} className='input-theme' type="text" /></Col>
                                    <Col xs={2} className='d-flex align-items-center' >{otpTimer?.phone && <Timer time={120} endFunction={() => setOtpTimer((prev) => ({ ...prev, phone: false }))} />}</Col>
                                </Row>
                            </Col>
                        </FormGroup>
                    </Col>
                )}
                <Col>
                    <FormGroup row className='justify-content-center'>
                        <Label for="email" sm={3}>Email ID</Label>
                        <Col sm={5}>
                            <Row>
                                <Col lg={9} className='d-flex align-items-center'>{!emailOtp ? <Input id="email" name="email" autoComplete='off' onChange={handleChange} value={associateData?.email || ''} className='input-theme text-lower' type="email" /> : `${associateData?.email}`}</Col>
                                <Col lg={3} className='text-center my-2' ><Button className='button-theme' disabled={otpTimer.email} onClick={onMailOtp}>{!emailOtp ? "OTP" : "Resend"}</Button></Col>
                            </Row>
                        </Col>
                    </FormGroup>
                </Col>
                {emailOtp && (
                    <Col>
                        <FormGroup row className='justify-content-center'>
                            <Label for="mailOtp" sm={3}>Email OTP</Label>
                            <Col sm={5}>
                                <Row>
                                    <Col xs={10} lg={5}><Input id="mailOtp" name="mailOtp" autoComplete='off' onChange={(e) => (e.target.value.length <= 5 && Number(e.target.value) == e.target.value) ? handleChange(e) : ""} value={associateData.mailOtp || ''} className='input-theme' type="text" /></Col>
                                    <Col xs={2} className='d-flex align-items-center' >{otpTimer?.email && <Timer time={120} endFunction={() => setOtpTimer((prev) => ({ ...prev, email: false }))} />}</Col>
                                </Row>
                            </Col>
                        </FormGroup>
                    </Col>
                )}
                <Col>
                    <FormGroup row className='justify-content-center'>
                        <Label for="photo" sm={3}>Profile Photo</Label>
                        <Col sm={5}>
                            <Input id="photo" name="photo" autoComplete='off' className='input-theme' type="file" onChange={handleFileChange} />
                        </Col>
                    </FormGroup>
                </Col>
                <Col>
                    <div className='text-center my-3'>
                        <Button className='button-theme' disabled={associateData?.mailOtp?.length !== 5 || associateData?.mobileOtp?.length !== 6 || buttonDisable} onClick={onSubmit}>Register</Button>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default Registration;