import { useEffect } from "react";
import React from "react"
import { Outlet } from "react-router-dom";
import { useAuth } from "../Contexts/AuthenticateProvider";
import { reloadWindowToPath } from "gfdu";

function PublicRoutes({ children }) {
  const { isAuthenticated } = useAuth();
  useEffect(() => {
    if (isAuthenticated) {
        reloadWindowToPath("/dashboard");
    }
  }, [isAuthenticated]);
  return (
    <React.Fragment>
      {!isAuthenticated && (
        <>
          {children}
          <Outlet />
        </>
      )}
    </React.Fragment>
  );
}

export default PublicRoutes;
