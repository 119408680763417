import React, { useState, useEffect } from 'react';
import Profile from './Profile';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { customerDataChange, customerDataEmpty, customerViewChange, customerViewEmpty } from '../../../Reducer/customerSlice';
import { getApiCall } from 'gfdu';
import { decrypt } from '../../../Helpers/Crypto';
import { toast } from 'react-toastify';
import KycDetails from './KycDetails';

const Index = () => {
    const { id } = useParams("id");
    const dispatch = useDispatch()
    const customer = useSelector((state) => state.customer)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        dispatch(customerViewEmpty())
        dispatch(customerDataEmpty())
        setLoading(false)
        getApiCall(`/admin/customer/details/${id}`, (response) => {
            if (response?.success) {
                const decryptedData = decrypt(response?.data)
                if (decryptedData?.success) {
                    dispatch(customerViewChange(decryptedData?.data))
                    dispatch(customerDataChange(decryptedData?.data))
                } else {
                    dispatch(customerViewEmpty())
                }
            } else {
                dispatch(customerViewEmpty())
                toast.error("Unable to Retrive Data")
            }
            setLoading(true)
        })
    }, [])
    return (
        <React.Fragment>
            <Profile loading={loading} id={id} />
            <KycDetails loading={loading} id={id} />
        </React.Fragment>
    );
}

export default Index;
