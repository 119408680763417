import React, { useEffect, useState } from 'react'

const Steper = (props) => {
    const [count, setCount] = useState([])
    const [active,setActive] = useState(0)
    useEffect(() => {
        if (props?.count) {
            for (let i = 1; i <= props?.count; i++) {
                setCount((prev) => [ ...prev,i ])
            }   
        }
    }, [props?.count])
    useEffect(() => {
        if (props?.active) {
            setActive(Number(props?.active))  
        }
    }, [props?.active])

    return (
        <React.Fragment>
            <div className='step-box'>
                {
                    count &&
                    count.map((value,key) => {
                        return (
                            <div key={key} className={`step-box-card ${ active > value ? 'step-completed': ""} ${ active == value ? "step-active" : ""}`}>{ active > value ? <i className='bx bx-check fs-3'></i>: value}</div>
                        )
                    })
                }

            </div>
        </React.Fragment>
    )
}

export default Steper