import React from 'react'
import PlaceHolder from './PlaceHolder'

const TablePlaceHolder = (props) => {
  return (
    <React.Fragment>
        <tr>{[...Array(props?.count || 1)].map((_, i) => <td key={i} ><PlaceHolder/></td>)}</tr>
    </React.Fragment>
  )
}

export default TablePlaceHolder