import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    sideBar : true,
    offCanva : false,
    contentLoading : false
};

const componentSlice = createSlice({
  name: "component",
  initialState,
  reducers: {
    sideBarChange : (state,{payload}) => {
      state.sideBar = payload
    },
    offCanvaChange : (state,{payload}) => {
      state.offCanva = payload
    },
    contentLoadingChange : (state,{payload}) => {
      state.contentLoading = payload
    }
  }
});

export const {sideBarChange , offCanvaChange ,contentLoadingChange } = componentSlice.actions
export default componentSlice.reducer;
