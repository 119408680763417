import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap'
import { getApiCall, patchApiCall, reloadWindow } from 'gfdu'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { decrypt, encrypt } from '../../Helpers/Crypto'
import { supportDetailsChange } from '../../Reducer/siteDetailsSlice'
import { contentLoadingChange } from '../../Reducer/componentSlice'


const Support = () => {
  const [details, setDetails] = useState({ email_1: "", email_2: "", mobile_1: "", mobile_2: "" })
  const [disableButton, setDisableButton] = useState(false)
  const handleChange = (e) => {
    setDetails((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }
  const siteData = useSelector((state) => state.siteDetail)
  const dispatch = useDispatch()
  const onSubmit = () => {
    dispatch(contentLoadingChange(true))
    setDisableButton(true)
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(details?.email_1)) {
      dispatch(contentLoadingChange(false))
      setDisableButton(false)
      toast.error("Invalid Email Id 1")
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(details?.email_2)) {
      dispatch(contentLoadingChange(false))
      setDisableButton(false)
      toast.error("Invalid Email Id 2")
    } else if (details?.email_1 === details?.email_2) {
      dispatch(contentLoadingChange(false))
      setDisableButton(false)
      toast.error("EmailId must not to be Same")
    } else if (!/^[0-9]{10}$/.test(details?.mobile_1)) {
      dispatch(contentLoadingChange(false))
      setDisableButton(false)
      toast.error("Invalid Mobile No 1")
    } else if (!/^[0-9]{10}$/.test(details?.mobile_2)) {
      dispatch(contentLoadingChange(false))
      setDisableButton(false)
      toast.error("Invalid Mobile No 2")
    } else if (details?.mobile_1 === details?.mobile_2) {
      dispatch(contentLoadingChange(false))
      setDisableButton(false)
      toast.error("MObile must not to be Same")
    } else {
      patchApiCall("/admin/business/site/support/update", encrypt({ ...details, mobile_1: Number(details?.mobile_1), mobile_2: Number(details?.mobile_2) }), (response) => {
        if (response?.success) {
          toast.success("Updated Successfully");
          setTimeout(() => {
            setDisableButton(false)
            reloadWindow()
          }, 3000);
        } else {
          setDisableButton(false)
          toast.error(response?.message || response?.msg || "Update Failed")
        }
        dispatch(contentLoadingChange(false))
      })
    }
  }
  useEffect(() => {
    getApiCall("/admin/business/site/support/view", (response) => {
      if (response.success) {
        const decryptData = decrypt(response?.data)
        dispatch(supportDetailsChange(decryptData?.data))
        setDetails(decryptData?.data)
      } else {
        toast.error("Failed to retrive details")
      }
    })
  }, [])
  return (
    <React.Fragment>
      <Container fluid>
        <Row className='flex-column'>
          <Col className='text-center fs-3 my-2' >Support Details</Col>
          <Col className='table-card' >
            <Card className='m-4 tab-card'>
              <CardBody>
                <Row xl={2} xs={1}>
                  <Col className='my-2'>
                    <Row xs={1} className='justify-content-evenly'>
                      <Col>
                        <FormGroup row>
                          <Label sm={3}>Email 1</Label>
                          <Col><Input type='text' id='email_1' name='email_1' value={details?.email_1} onChange={handleChange} /></Col>
                        </FormGroup>
                      </Col>
                      <Col >
                        <FormGroup row>
                          <Label sm={3}>Email 2</Label>
                          <Col><Input type='text' id='email_2' name='email_2' value={details?.email_2} onChange={handleChange} /></Col>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col className='my-2' >
                    <Row xs={1} className='justify-content-evenly'>
                      <Col>
                        <FormGroup row>
                          <Label sm={3}>Contact No 1</Label>
                          <Col><Input type='text' id='mobile_1' name='mobile_1' value={details?.mobile_1} onChange={handleChange} /></Col>
                        </FormGroup>
                      </Col>
                      <Col >
                        <FormGroup row>
                          <Label sm={3}>Contact No 2</Label>
                          <Col><Input type='text' id='mobile_2' name='mobile_2' value={details?.mobile_2} onChange={handleChange} /></Col>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className='my-5'>
                  <Col className='text-center' ><Button className='button-theme' disabled={disableButton} onClick={onSubmit} >Update</Button></Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default Support;