import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    carList : [],
    carView : {},
    carData : {},
    togglers : {
      emailOpen : false,
      emailOtp : false,
      reasonOpen : false
    }
};

const carSlice = createSlice({
  name: "car",
  initialState,
  reducers: {
    carDataEmpty : (state,{payload}) =>{
      state.carData = {}
    },
    carListEmpty : (state,{payload}) =>{
      state.carList = []
    },
    carViewEmpty : (state,{payload}) =>{
      state.carView = {}
    },
    carListChange : (state,{payload}) => {
      state.carList = payload
    },
    carViewChange : (state,{payload}) => {
      state.carView = { ...state?.carView , ...payload}
    },
    carDataChange : (state,{payload}) => {
      state.carData = { ...state?.carData , ...payload}
    },
    togglersChange : (state,{payload}) => {
      state.togglers = { ...state?.togglers,...payload}
    }  
  }
});

export const { carListChange , carViewChange , carDataChange , togglersChange , carDataEmpty , carListEmpty, carViewEmpty } = carSlice.actions
export default carSlice.reducer;
