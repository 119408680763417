import React from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { useSelector } from 'react-redux'

const Index = ({ children }) => {
  const component = useSelector((state) => state.component)
  return (
    <React.Fragment>
      <div className="layout">
        <Header />
        <Sidebar />
        <div className={`content-panel ${component?.sideBar ? "content-panel-lg" : "content-panel-sm"}`} >{children}</div>
      </div>
    </React.Fragment>
  );
};

export default Index;
