import React from 'react'

const ErrorPage = () => {
    return (
        <React.Fragment>
            <div className='full-page'>
                <div className='d-flex justify-content align-items-center flex-column'>
                    <div className='error-symbol'>!</div>
                    <h2 className='my-4'>Something Went Wrong</h2>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ErrorPage