import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Table, Input, Label, FormGroup, ButtonGroup, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { formatDate, parseDateString, textCapital } from '../../../Helpers/Utils'
import { useDispatch, useSelector } from 'react-redux'
import { getApiCall, patchApiCall, reloadWindowToPath } from 'gfdu'
import { customerDataChange, customerDataEmpty, customerViewChange } from '../../../Reducer/customerSlice'
import { decrypt, encrypt } from '../../../Helpers/Crypto'
import { toast } from 'react-toastify'
import classnames from 'classnames';
import DatePicker from 'react-multi-date-picker'
import { contentLoadingChange } from '../../../Reducer/componentSlice';

const ThirdDocument = (props) => {
    const customerId = props.customerId
    const dispatch = useDispatch()
    const customerView = useSelector((state) => state.customer)?.customerView
    const customerData = useSelector((state) => state.customer)?.customerData
    const [currentActiveTab, setCurrentActiveTab] = useState('1');
    const [dob, setDob] = useState((new Date()).setFullYear(((new Date()).getFullYear()) - 18))
    const [buttonDisable,setButtonDisable] = useState(false)
    const toggle = tab => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab);
    }
    const handleChange = (e) => {
        dispatch(customerDataChange({ [e.target.name]: e.target.value }))
    }
    const VoterVerify = () => {
        dispatch(contentLoadingChange(true))
        setButtonDisable(true)
        if (!/^([a-zA-Z]){3}([0-9]){7}$/.test(customerData?.voterId)) {
            dispatch(contentLoadingChange(false))
            setButtonDisable(false)
            toast.error("Invalid Voter No")
        } else {
            patchApiCall(`/admin/customer/kyc/voter/${customerId}`, encrypt({ voterId: customerData?.voterId.toUpperCase() }), (response) => {
                if (response?.success) {
                    toast?.success("Voter Verified Successfully")
                    setTimeout(() => {
                        reloadWindowToPath(`/customer/${customerId}`)
                    }, 2000);
                } else {
                    setButtonDisable(false)
                    toast.error(response?.msg || response.message || "Invalid Voter No")
                }
                dispatch(contentLoadingChange(false))
            })
        }
    }
    const PassportVerify = () => {
        dispatch(contentLoadingChange(true))
        setButtonDisable(true)
        const date = new Date(dob)
        const dateOfBirth = formatDate(date)
        if (!/^[a-zA-Z\s]{2,}$/.test(customerData?.name)) {
            setButtonDisable(false)
            dispatch(contentLoadingChange(false))
            toast.error("Invalid Name")
        } else if (!/^[a-zA-Z0-9]{12,15}$/.test(customerData?.passportNo)) {
            setButtonDisable(false)
            dispatch(contentLoadingChange(false))
            toast.error("Invalid Passport No")
        } else {
            patchApiCall(`/admin/customer/kyc/passport/${customerId}`, encrypt({ name: customerData?.name?.toUpperCase(), passportNo: customerData?.passportNo?.toUpperCase(), dob: dateOfBirth }), (response) => {
                if (response?.success) {
                    toast?.success("Passport Verified Successfully")
                    setTimeout(() => {
                        reloadWindowToPath(`/customer/${customerId}`)
                    }, 2000);
                } else {
                    setButtonDisable(false)
                    toast.error(response?.message || response.msg || "Invalid Passport Details")
                }
                dispatch(contentLoadingChange(false))
            })
        }
    }
    useEffect(() => {
        dispatch(customerDataEmpty())
        getApiCall(`/admin/customer/details/${customerId}`, (response) => {
            if (response?.success) {
                const decryptedData = decrypt(response?.data)
                if (decryptedData?.success) {
                    dispatch(customerDataChange(decryptedData?.data))
                    dispatch(customerViewChange(decryptedData?.data))
                    setDob(parseDateString(decryptedData?.data?.kyc?.aadhar?.aadharDetails?.proofOfIdentity?.dob) || (new Date()).setFullYear(((new Date()).getFullYear()) - 18))
                } else {
                    dispatch(customerViewChange({}))
                }
            } else {
                dispatch(customerViewChange({}))
                toast.error("Unable to Retrive Data")
            }
        })
    }, [])
    
    return (
        <React.Fragment>
            <Row className='my-2 flex-column justify-content-center align-items-center' lg={2} >
                <Col >
                    <div className='site-details'>
                        <Nav tabs>
                            <NavItem className='col'>
                                <NavLink className={classnames({ active: currentActiveTab === '1' }) + " voter-tab cursor-pointer"} onClick={() => { toggle('1'); }}  >Voter Id</NavLink>
                            </NavItem>
                            <NavItem className='col'>
                                <NavLink className={classnames({ active: currentActiveTab === '2' }) + " passport-tab cursor-pointer"} onClick={() => { toggle('2'); }}  >Passport</NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent className='form-tab' activeTab={currentActiveTab}>
                            <TabPane tabId="1">
                                <Row className='py-4 flex-column'>
                                    <Col className='text-center mb-4'>Voter Verification </Col>
                                    <Col>
                                        <FormGroup row className='justify-content-center align-items-center'>
                                            <Label for="voterId" sm={3}>Customer ID</Label>
                                            <Col sm={5}>{customerView?.customerId && customerView?.customerId}</Col>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup row className='justify-content-center align-items-center'>
                                            <Label for="voterId" sm={3}>Voter No</Label>
                                            <Col sm={5}>
                                                <Input id="voterId" name="voterId" autoComplete='off' value={customerData?.voterId || ""} onChange={(e) => { e.target.value.length <= 10 && handleChange(e) }} className='input-theme input-size-1 text-upper' type="text" />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={12}><div className='text-center'><Button className='button-theme' disabled={buttonDisable} onClick={VoterVerify}>Verify</Button></div></Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                                <Row className='py-4 flex-column'>
                                    <Col className='text-center mb-4'>Passport Verification </Col>
                                    <Col>
                                        <FormGroup row className='justify-content-center align-items-center'>
                                            <Label for="voterId" sm={3}>Customer ID</Label>
                                            <Col sm={5}>{customerView?.customerId && customerView?.customerId}</Col>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup row className='justify-content-center align-items-center'>
                                            <Label for="name" sm={3}>Name </Label>
                                            <Col sm={5}>
                                                <Input id="name" name="name" autoComplete='off' value={customerData?.name || ""} onChange={(e) => { handleChange(e) }} className='input-theme input-size-1 text-upper' type="text" />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup row className='justify-content-center align-items-center'>
                                            <Label for="passportNo" sm={3}>File No</Label>
                                            <Col sm={5}>
                                                <Input id="passportNo" name="passportNo" autoComplete='off' value={customerData?.passportNo || ""} onChange={(e) => { e.target.value.length <= 15 && handleChange(e) }} className='input-theme input-size-1 text-upper' type="text" />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup row className='justify-content-center align-items-center'>
                                            <Label for="dob" sm={3}>Date Of Birth</Label>
                                            <Col sm={5}>
                                                <DatePicker className='calender-theme' format='DD-MM-YYYY' inputClass="date-theme" value={dob} onChange={setDob} minDate={(new Date()).setFullYear(((new Date()).getFullYear()) - 100)} maxDate={(new Date()).setFullYear(((new Date()).getFullYear()) - 18)} />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={12}><div className='text-center'><Button className='button-theme' disabled={buttonDisable} onClick={PassportVerify}>Verify</Button></div></Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default ThirdDocument;