import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, CardBody, Col, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner, Table } from 'reactstrap'
import { formatDate, textCapital } from '../../../Helpers/Utils'
import { patchApiCall, reloadWindow } from 'gfdu'
import { encrypt } from '../../../Helpers/Crypto'
import { toast } from 'react-toastify'
import { customerDataChange, togglersChange } from '../../../Reducer/customerSlice'
import { contentLoadingChange } from '../../../Reducer/componentSlice';
import { useParams } from 'react-router-dom'
import PlaceHolder from '../../../Helpers/Components/PlaceHolder'
import ProfileAvatar from "../../../Assets/images/avatar.png"

const Profile = (props) => {
    const { id } = useParams("id");
    const inputFile = useRef(null)
    const dispatch = useDispatch()
    const customer = useSelector((state) => state.customer)
    const togglers = customer?.togglers
    const [viewReason, setViewReason] = useState(false)
    const [buttonDisable, setButtonDisable] = useState(false)
    const [viewPhoto, setViewPhoto] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const [profilePhoto, setProfilePhoto] = useState("")
    const updateProfileData = async () => {
        dispatch(contentLoadingChange(true))
        setButtonDisable(true)
        if (!customer?.customerView) {
            toast.error("Invalid Associate Data");
            return dispatch(contentLoadingChange(false));
        }
        if (customer.customerView.photo === profilePhoto || profilePhoto === "") {
            toast.error("Invalid Input");
            return dispatch(contentLoadingChange(false));
        }
        const formData = new FormData();
        formData.append("name", customer.customerView.name);
        formData.append("gender", customer.customerView.gender);
        formData.append("photo", profilePhoto);
        patchApiCall(`/admin/customer/profile/${id}`, formData, (response) => {
            if (response?.success) {
                toast.success("Update Successfully");
                setViewPhoto(false);
                setTimeout(() => {
                    reloadWindow()
                }, 2000);
            } else {
                toast.error("Update Failed");
            }
            dispatch(contentLoadingChange(false))
            setButtonDisable(false)
        })
    };
    const changeStatus = (status) => {
        dispatch(contentLoadingChange(true))
        setButtonDisable(true)
        if (status === false && !customer?.customerData?.reason) {
            dispatch(contentLoadingChange(false))
            setButtonDisable(false)
            return toast.error("Reason is required")
        }
        patchApiCall(`/admin/customer/status/${id}?status=${status}`, encrypt({ ...(status === false ? { reason: customer?.customerData?.reason } : {}) }), (response) => {
            if (response?.success) {
                toast.success(`${status ? "Active Successfully" : "Deactive Successfully"}`)
                setTimeout(() => {
                    reloadWindow()
                    setButtonDisable(false)
                }, 2000);
            } else {
                setButtonDisable(false)
                toast.error( response.msg || response?.message ||"Status Couldn't Update")
            }
            dispatch(contentLoadingChange(false))
        })
    }
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file !== undefined) {
            setProfilePhoto(file)
            inputFile.current = URL.createObjectURL(file)   
        }
    }
    const radioChange = (e) => {
        dispatch(customerDataChange({ gender: e.target.value }));
    }
    const updateData = () => {
        dispatch(contentLoadingChange(true))
        setButtonDisable(true)
        const formData = new FormData();
        if (!/^[a-zA-Z\s]{2,}$/.test(customer?.customerData?.name)) {
            dispatch(contentLoadingChange(false))
            toast.error("Invalid Name");
            return false;
        }
        if (!["male", "female"].includes(customer?.customerData?.gender)) {
            dispatch(contentLoadingChange(false))
            toast.error("Invalid Gender");
            return false;
        }
        for (const key in customer?.customerData) {
            formData.append(key, customer?.customerData[key]);
        }
        patchApiCall(`/admin/customer/profile/${id}`, formData, (response) => {
            if (response?.success) {
                toast.success("Update Successfully");
                dispatch(customerDataChange({}));
                setEditModal(false);
                setTimeout(() => {
                    reloadWindow()
                }, 2000);
            } else {
                toast.error( response?.msg || response?.message ||"Update Failed");
            }
            dispatch(contentLoadingChange(false))
            setButtonDisable(false)
        })
    }
    const emailVerifyOtp = () => {
        dispatch(contentLoadingChange(true))
        setButtonDisable(true)
        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(customer?.customerData?.email?.emailId || customer?.customerData?.email)) {
            dispatch(contentLoadingChange(false))
            setButtonDisable(false)
            toast.error("Invalid Email Id");
        } else if (!/^\d{5}$/.test(customer?.customerData?.mailOtp)) {
            dispatch(contentLoadingChange(false))
            setButtonDisable(false)
            toast.error("Invalid Email Otp");
        } else {
            patchApiCall(`/admin/customer/email/verify/${id}`, encrypt({ email: (customer?.customerData?.email?.emailId || customer?.customerData?.email), otp: customer?.customerData?.mailOtp }), (response) => {
                if (response?.success) {
                    toast.success("Otp Verified SuccessFully")
                    setTimeout(() => {
                        reloadWindow()
                        setButtonDisable(false)
                    }, 2000);
                } else {
                    setButtonDisable(false)
                    toast.error(response?.msg || response?.message || "Invalid Email")
                }
                dispatch(contentLoadingChange(false))
            })
        }
    }
    const emailSendOtp = () => {
        dispatch(contentLoadingChange(true))
        setButtonDisable(true)
        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(customer?.customerData?.email?.emailId || customer?.customerData?.email)) {
            dispatch(contentLoadingChange(false))
            setButtonDisable(false)
            toast.error("Invalid Email Id");
        } else {
            patchApiCall(`/admin/customer/email/otp/${id}`, encrypt({ email: (customer?.customerData?.email?.emailId || customer?.customerData?.email) }), (response) => {
                if (response?.success) {
                    toast.success("Otp Sended")
                    changeToggle({ emailOtp: true })
                } else {
                    toast.error(response?.msg || response?.message || "Invalid Email")
                }
                setButtonDisable(false)
                dispatch(contentLoadingChange(false))
            })
        }
    }
    const handleChange = (e) => {
        dispatch(customerDataChange({ [e?.target?.name]: e?.target?.value }))
    }
    const changeToggle = (status) => {
        dispatch(togglersChange(status))
    }
    useEffect(() => {
        inputFile.current = customer?.customerView?.photo
        setProfilePhoto(customer?.customerView?.photo)
    }, [viewPhoto])
    return (
        <React.Fragment>
            <Modal isOpen={viewReason} toggle={() => setViewReason(!viewReason)}>
                <ModalHeader toggle={() => setViewReason(!viewReason)} className='text-center' >Reason</ModalHeader>
                <ModalBody className='reason-modal'>{customer?.customerView?.errorMsg}</ModalBody>
            </Modal>
            <Modal isOpen={togglers?.emailOpen} toggle={() => changeToggle({ emailOpen: !togglers?.emailOpen })} >
                <ModalBody >
                    <Row className=' flex-column justify-content-center align-items-center'>
                        <Col>
                            <FormGroup row className='justify-content-center'>
                                <Label for="email" sm={3}>Email ID</Label>
                                <Col sm={7}>
                                    <Row className='align-items-center'>
                                        <Col lg={10}>{!togglers?.emailOtp ? <Input id='email' name='email' type='email' autoComplete='off' className='input-theme text-lower' value={customer?.customerData?.email?.emailId || customer?.customerData?.email} onChange={handleChange} /> : `${ customer?.customerData?.email || customer?.customerData?.email?.emailId}`}</Col>
                                        <Col>{!buttonDisable ? <i onClick={emailSendOtp} className=' text-theme fs-5 bx bx-send' ></i> : <i className='fs-5 bx bx-send' ></i>}</Col>
                                    </Row>
                                </Col>
                            </FormGroup>
                        </Col>
                        {
                            togglers?.emailOtp &&
                            <>
                                <Col>
                                    <FormGroup row className='justify-content-center'>
                                        <Label for="mailOtp" sm={3}>Otp</Label>
                                        <Col sm={7}>
                                            <Input id='mailOtp' name='mailOtp' autoComplete='off' className='input-theme input-size-1' value={customer?.customerData?.mailOtp || ""} onChange={(e) => (e.target.value?.length <= 5 && Number(e.target?.value) == e.target?.value) ? handleChange(e) : ""} />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col><div className='text-center'><Button className='button-theme' disabled={buttonDisable} onClick={emailVerifyOtp} >Verify</Button></div></Col>
                            </>
                        }
                    </Row>
                </ModalBody>
            </Modal>
            <Modal isOpen={editModal} toggle={() => setEditModal(!editModal)} >
                <ModalBody  >
                    <Row className='my-2 flex-column'>
                        <Col>
                            <FormGroup row className='justify-content-center'>
                                <Label for="name" sm={3}>Name</Label>
                                <Col sm={7}>
                                    <Input id="name" name="name" autoComplete='off' onChange={handleChange} value={customer?.customerData.name || ''} className='input-theme text-upper' type="text" />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup row className='justify-content-center'>
                                <Label for="gender" sm={3}>Gender</Label>
                                <Col sm={7} className='d-flex justify-content-evenly align-items-center'>
                                    <div>
                                        <Input name="gender" id='male' onChange={radioChange} value="male" checked={customer?.customerData.gender === 'male'} type="radio" />
                                        <Label for="male" className='mx-2'>Male</Label>
                                    </div>
                                    <div>
                                        <Input name="gender" id='female' onChange={radioChange} value="female" checked={customer?.customerData.gender === 'female'} type="radio" />
                                        <Label for="female" className='mx-2'>Female</Label>
                                    </div>
                                </Col>
                            </FormGroup>
                        </Col>

                        <Col className='text-center'><Button className='button-theme' disabled={buttonDisable} onClick={updateData}>Update</Button></Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Modal isOpen={viewPhoto} toggle={() => setViewPhoto(!viewPhoto)}>
                <ModalBody>
                    <Row className='flex-column'>
                        <Col className='text-center'>Profile Photo</Col>
                        <Col className='view-photo'>{inputFile.current ? <img src={inputFile.current} alt='profile' /> : "Upload Image"}</Col>
                        <Col className='text-center' ><div className='input-profile'><input type='file' id='profile' name='profile' onChange={handleFileChange} /></div> <Label htmlFor='profile' className='profile-label' >Change Profile</Label></Col>
                        {
                            customer?.customerView?.photo === profilePhoto
                                ? "" :
                                <Col className='text-center my-2'><div className='d-flex justify-content-evenly'><Button className='button-theme' disabled={buttonDisable} onClick={updateProfileData}>Update</Button><Button color='danger' onClick={() => setViewPhoto(false)}>Close</Button></div></Col>
                        }
                    </Row>
                </ModalBody>
            </Modal>
            <Modal isOpen={togglers?.reasonOpen} toggle={() => changeToggle({ reasonOpen: !togglers?.reasonOpen })} >
                <ModalBody >
                    <Row className=' flex-column justify-content-center align-items-center'>
                        <Col>
                            <FormGroup row className='justify-content-center'>
                                <Label for="reason" sm={3}>Reason</Label>
                                <Col sm={7}>
                                    <Input id='reason' name='reason' className='input-theme' value={customer?.customerData?.reason} onChange={handleChange} />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col><div className='text-center' ><Button color='danger' disabled={buttonDisable} onClick={() => changeStatus(false)} >Deactive</Button></div></Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Row className="m-2">
                <Col>
                    <Card >
                        <CardBody>
                            <Row>
                                <Col lg={3} >
                                    <div className='customer-profile'>
                                        <img src={customer?.customerView?.photo || ProfileAvatar} alt='Customer' onClick={() => setViewPhoto(true)} />
                                    </div>
                                </Col>
                                <Col className='mt-2' >
                                    <div className='profile-data mb-3'><span>Host ID</span><span>{props?.loading ? (customer?.customerView?.customerId && customer?.customerView?.customerId?.toUpperCase()) : <PlaceHolder />}</span></div>
                                    <div className='profile-data mb-3'><span>Created By</span><span>{props?.loading ? (customer?.customerView?.createdBy && textCapital(customer?.customerView?.createdBy)) : <PlaceHolder />}</span></div>
                                    <div className='profile-data mb-3'><span>Created At</span><span>{props?.loading ? (customer?.customerView?.createdAt && formatDate(customer?.customerView?.createdAt)) : <PlaceHolder />}</span></div>
                                    <p className='profile-data'>
                                        <span>Status</span>
                                        <span>
                                            {
                                                !customer?.customerView?.status ?
                                                    <Button color="success" disabled={buttonDisable} onClick={() => changeStatus(true)}>Active</Button> :
                                                    <Button color="danger" onClick={() => changeToggle({ reasonOpen: true })}>Deactive</Button>
                                            }
                                        </span>
                                    </p>
                                    {
                                        customer?.customerView?.status === false &&
                                        <p className='profile-data' ><span>Reason</span><span><Button className='button-theme' onClick={() => setViewReason(!viewReason)}>View</Button></span></p>
                                    }
                                </Col>
                                <Col className='mt-2'>
                                    <div className='profile-data mb-3'><span>Name</span><span>{props?.loading ? (customer?.customerView?.name && textCapital(customer?.customerView?.name)) : <PlaceHolder />} <i className='ms-2 profile-edit bx bx-edit' onClick={() => setEditModal(true)} ></i></span></div>
                                    <div className='profile-data mb-3'><span>Gender</span><span>{props?.loading ? (customer?.customerView?.gender && textCapital(customer?.customerView?.gender)) : <PlaceHolder />}</span></div>
                                    <div className='profile-data mb-3'><span>Phone</span><span>{props?.loading ? (customer?.customerView?.phone && customer?.customerView?.phone) : <PlaceHolder />}</span></div>
                                    {
                                        customer?.customerView?.photo ?
                                            <div className='profile-data mb-3'>
                                                <span>Email</span>
                                                <span onClick={() => changeToggle({ emailOpen: !togglers?.emailOpen })} className={`${customer?.customerView?.email?.status ? "text-green" : 'text-red'} cursor-pointer`} >{props?.loading ? (customer?.customerView?.email?.emailId) : <PlaceHolder />}</span>
                                            </div> : ""

                                    }
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default Profile