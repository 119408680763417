import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import Steper from '../../../Helpers/Components/Steper'
import Registration from './Registration'
import AadharUpdate from './AadharUpdate'
import DLUpdate from "./DLUpdate"
import ThirdDocument from './ThirdDocument'

const AssociateRegistration = () => {
    const [step,setStep] = useState(1)
    const [customerId,setCustomerId] = useState("")
    return (
        <React.Fragment>
            <Row className='justify-content-center mb-5'>
                <Col lg={10}>
                    <Row><Col className='text-center text-theme fs-3'>Customer Registration</Col></Row>
                    <Card>
                        <CardBody >
                            <Row className='mb-5'>
                                <Col>
                                    <Steper count={4} active={step} />
                                </Col>
                            </Row>
                            {
                                step === 1 ? <Registration setCustomerId={setCustomerId} changeStep={setStep}/> : step === 2 ?  <AadharUpdate customerId={customerId} changeStep={setStep}/> : step ===  3 ? <DLUpdate customerId={customerId} changeStep={setStep}/> : step ===  4 ?  <ThirdDocument customerId={customerId} /> : ""
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}



export default AssociateRegistration