import React, { useEffect } from 'react'
import ProfileDetails from './ProfileDetails'
import VehicleData from './VehicleData'
import { contentLoadingChange } from '../../../Reducer/componentSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { decrypt, encrypt } from '../../../Helpers/Crypto';
import { useParams } from 'react-router-dom';
import { getApiCall } from 'gfdu';

const index = () => {
  return (
    <React.Fragment>
        <ProfileDetails/>
        <VehicleData/>
    </React.Fragment>
  )
}

export default index