import React from 'react'
import { Spinner } from 'reactstrap'

const ContentLoader = () => {
  return (
    <React.Fragment>
      <div className='content-loader'>
        {/* <Spinner className='spin-loader' /> */}
          <Spinner type="grow"></Spinner>
          <Spinner type="grow"></Spinner>
          <Spinner type="grow"></Spinner>
          <Spinner type="grow"></Spinner>
          <Spinner type="grow"></Spinner>
          <Spinner type="grow"></Spinner>
          <Spinner type="grow"></Spinner>
          <Spinner type="grow"></Spinner>
          <Spinner type="grow"></Spinner>
          <Spinner type="grow"></Spinner>
      </div>
    </React.Fragment>
  )
}

export default ContentLoader