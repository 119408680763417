import React from 'react'
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import PublicRoutes from './PublicRouter';
import Login from '../Components/Login';
import PrivateRoutes from './PrivateRouter';
import Dashboard from '../Components/Dashboard';
import SiteSettings from '../Components/SiteSettings';
import Branch from '../Components/Branch';
import Associate from '../Components/Associate';
import AssociateList from '../Components/Associate/AssociateList';
import AssociateView from '../Components/Associate/AssociateView';
import AssociateRegistration from '../Components/Associate/AssociateRegistration';
import Customer from '../Components/Customer';
import CustomerList from '../Components/Customer/CustomerList';
import CustomerView from '../Components/Customer/CustomerView';
import CustomerRegistration from '../Components/Customer/CustomerRegistration';
import Vehicle from '../Components/Vehicle';
import VehicleList from '../Components/Vehicle/BrandList';
import Car from '../Components/Car';
import CarList from '../Components/Car/CarList';
import CarView from '../Components/Car/CarView';
import CarRegistration from '../Components/Car/CarRegistration';
import Bike from '../Components/Bike';
import BikeList from '../Components/Bike/BikeList';
import BikeView from '../Components/Bike/BikeView';
import BikeRegistration from '../Components/Bike/BikeRegistration';
import BrandView from '../Components/Vehicle/BrandView';
import VariantView from '../Components/Vehicle/VariantView';
import ModelView from '../Components/Vehicle/ModelView';
import VariantRegister from '../Components/Vehicle/VariantRegister';
import VariantEdit from '../Components/Vehicle/VariantEdit';
import CarDevice from '../Components/Car/CarView/CarDevice';
import CarVerification from '../Components/Car/CarView/CarVerification';

const index = () => {
    return (
        <React.Fragment>
            <Router>
                <Routes>
                    <Route element={<PublicRoutes />}>
                        <Route index element={<Login />} />
                        <Route path="/*" element={<Navigate to="/" />} />
                    </Route>
                    
                    <Route element={<PrivateRoutes />}>
                        <Route path="/*" element={<Navigate to="/dashboard" />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/site-settings" element={<SiteSettings />} />
                        <Route path="/branch" element={<Branch />} />
                        <Route path="/associate" element={<Associate />}>
                            <Route index element={<AssociateList />} />
                            <Route path="*" element={<AssociateList />} />
                            <Route path=":id" element={<AssociateView />} />
                            <Route path="registration" element={<AssociateRegistration />} />
                            <Route path="registration/car/:id" element={<CarRegistration />} />
                            <Route path="registration/bike/:id" element={<BikeRegistration />} />
                        </Route>
                        <Route path="/customer" element={<Customer />}>
                            <Route index element={<CustomerList />} />
                            <Route path="*" element={<CustomerList />} />
                            <Route path=":id" element={<CustomerView />} />
                            <Route path="registration" element={<CustomerRegistration />} />
                        </Route>
                        <Route path="/vehicle" element={<Vehicle />}>
                            <Route index element={<VehicleList />} />
                            <Route path="*" element={<VehicleList />} />
                            <Route path=":id" element={<BrandView />} />
                            <Route path="model/:id" element={<ModelView />} />
                            <Route path="model/variant/:id" element={<VariantRegister />} />
                            <Route path="model/variant/edit/:id" element={<VariantEdit />} />
                            <Route path="variant/:id" element={<VariantView />} />
                        </Route>
                        <Route path="/car" element={<Car />}>
                            <Route index element={<CarList />} />
                            <Route path="*" element={<CarList />} />
                            <Route path=":id" element={<CarView />} />
                            <Route path="registration" element={<CarRegistration />} />
                            <Route path="device/:id" element={<CarDevice />} />
                            <Route path="verify/:id" element={<CarVerification />} />
                        </Route>
                        <Route path="/bike" element={<Bike />}>
                            <Route index element={<BikeList />} />
                            <Route path="*" element={<BikeList />} />
                            <Route path=":id" element={<BikeView />} />
                            <Route path="registration" element={<BikeRegistration />} />
                        </Route>
                    </Route>
                </Routes>
            </Router>
        </React.Fragment>
    )
}

export default index