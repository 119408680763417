import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row, Table } from 'reactstrap'
import { decrypt, encrypt } from '../../Helpers/Crypto';
import { getApiCall, patchApiCall, postApiCall, reloadWindow, textCapitalize } from 'gfdu';
import { toast } from 'react-toastify';
import { textCapital } from '../../Helpers/Utils';

const VariantView = () => {
  const { id } = useParams("id");
  const navigate = useNavigate()
  const [variantDetails, setVariantDetails] = useState({})
  const [variantData, setVariantData] = useState({})
  const [serviceData, setServiceData] = useState({})
  const [buttonDisable, setButtonDisable] = useState(false)
  const [loading, setLoading] = useState(true)
  const [createModal, setCreateModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const handleChange = (e) => {
    setServiceData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }
  const openEditModel = (id) => {
    const data = variantDetails?.serviceDetails?.find((value) => value?.serviceId === id)
    setVariantData(data)
    setEditModal(true)
  }
  const deleteService = () => {
    setButtonDisable(true)
    patchApiCall(`/admin/vehicle/service/${id}?serviceId=${variantData?.serviceId}`, {}, (response) => {
      if (response?.success) {
        toast.success("Removed Successfully")
        setTimeout(()=>{
          reloadWindow()
        },1000)
      } else {
        toast.error(response?.message || response?.msg)
        setButtonDisable(false)
      }
    })
  }
  const serviceCreate = () => {
    if (serviceData?.serviceName === "" || serviceData?.serviceName?.trim()?.length === 0 || serviceData?.serviceName === undefined || serviceData?.serviceName === null || Number(serviceData?.serviceName) === "number") {
      toast?.error("Invalid Service Name")
    } else if (serviceData?.spanTime === "" || serviceData?.spanTime === undefined || serviceData?.spanTime === null || Number(serviceData?.spanTime) === 0) {
      toast?.error("Invalid Service Time")
    } else if (serviceData?.spanKm === "" || serviceData?.spanKm === undefined || serviceData?.spanKm === null || Number(serviceData?.spanKm) === 0) {
      toast?.error("Invalid Service Km")
    } else {
      const data = {
        serviceDetails: [
          {
            serviceName: serviceData?.serviceName,
            spanTime: Number(serviceData?.spanTime),
            spanKm: Number(serviceData?.spanKm)
          }
        ]
      }
      postApiCall(`/admin/vehicle/service/${id}`, encrypt(data), (response) => {
        if (response?.success) {
          toast?.success("Service Added Successfully")
          setTimeout(() => {
            reloadWindow()
          }, 1000)
        } else {
          toast.error(response?.msg || response?.message)
        }
      })
    }
  }
  useEffect(() => {
    setServiceData({})
    setVariantDetails({})
    setLoading(true)
    getApiCall(`/admin/vehicle/brand/model/variant/${id}`, (response) => {
      if (response?.success) {
        const decryptedData = decrypt(response?.data)
        if (decryptedData?.success) {
          setVariantDetails((prev) => ({
            ...prev,
            ...decryptedData?.data
          }))
        } else {
          setVariantDetails({})
        }
      } else {
        setVariantDetails({})
        toast.error("Unable to Retrive Data")
      }
      setLoading(false)
    })
  }, [])
  return (
    <React.Fragment>
      <Modal isOpen={createModal} toggle={() => setCreateModal(!createModal)}>
        <ModalHeader toggle={() => setCreateModal(!createModal)}></ModalHeader>
        <ModalBody>
          <Row xs={1} className='justify-content-center'>
            <Col>
              <h4 className='text-center text-theme my-3'>Create Service</h4>
              <FormGroup row className='justify-content-center'>
                <Label for="serviceName" xs={3}>Service</Label>
                <Col xs={5}>
                  <Input id="serviceName" name="serviceName" autoComplete='off' className='input-theme text-upper input-size-2' type="text" value={serviceData?.serviceName || ""} onChange={handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row className='justify-content-center'>
                <Label for="spanTime" xs={3}>Time Span</Label>
                <Col xs={5} className='d-flex'>
                  <Input id="spanTime" name="spanTime" autoComplete="off" className="input-theme text-upper input-size-small" type="text" value={serviceData?.spanTime || ""} onChange={(e) => { (!Number.isNaN(Number(e.target.value)) && Number(e.target.value) >= 0 && Number(e.target.value) <= 15 && (e.target.value?.split(".")[1] ? Number(e.target.value?.split(".")[1]) <= 12 : true)) && handleChange(e) }} />
                  <span className="mx-2"> {(serviceData?.spanTime?.split(".")[0] || 0) + " Years"} <br /> {(serviceData?.spanTime?.split(".")[1] || 0) + " Months"}</span>
                </Col>
              </FormGroup>
              <FormGroup row className='justify-content-center'>
                <Label for="spanKm" xs={3}>KM Span</Label>
                <Col xs={5} className='d-flex align-items-center'>
                  <Input id="spanKm" name="spanKm" autoComplete='off' className='input-theme text-upper input-size-1' value={serviceData?.spanKm} onChange={handleChange} type="number" step={500} min={0} max={10000} onKeyDown={(e) => { e.preventDefault() }} /> <span className='mx-2 fs-4' >{"KM"}</span>
                </Col>
              </FormGroup>
            </Col>
            <Col className='m-2 text-center'><Button className='button-theme' disabled={buttonDisable} onClick={serviceCreate}>Register</Button></Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal isOpen={editModal} toggle={() => setEditModal(!editModal)} >
        <ModalBody>
          <h5 className='text-center'>Are you sure to delete below service ?</h5>
          <Row xs={1} sm={2} className='justify-content-center'>
            <Col>
              <Table className='text-theme'>
                <tr className='my-1'>
                  <td>Service</td>
                  <td>{variantData?.serviceName && textCapital(variantData?.serviceName)}</td>
                </tr>
                <tr className='my-1'>
                  <td>Time Span</td>
                  <td>{(String(variantData?.spanTime)?.split(".")[0] || 0) + " Years "}{(String(variantData?.spanTime)?.split(".")[1] || 0) + " Months"}</td>
                </tr>
                <tr className='my-1'>
                  <td>KM Span</td>
                  <td>{(variantData?.spanKm || 0) + " KM"}</td>
                </tr>
              </Table>
            </Col>
          </Row>
          <div className='d-flex justify-content-evenly'>
            <Button color='success' disabled={buttonDisable} onClick={() => deleteService()}>Ok</Button>
            <Button color='danger' onClick={() => setEditModal(false)}>Cancel</Button>
          </div>
        </ModalBody>
      </Modal>
      {
        loading ?
          "" :
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row sm={3}>
                    <Col sm={8} className='data-details-1' >
                      <h3 className='text-center mb-3 text-theme'>Variant Details </h3>
                      <Row sm={3} xs={1}>
                        <Col className='data-show'>
                          <div className='data-head'>Brand</div>
                          <div className='data-view'>{textCapital(variantDetails?.brand)}</div>
                        </Col>
                        <Col className='data-show'>
                          <div className='data-head'>Model</div>
                          <div className='data-view'>{textCapital(variantDetails?.modelName)}</div>
                        </Col>
                        <Col className='data-show'>
                          <div className='data-head'>Variant</div>
                          <div className='data-view'>{textCapital(variantDetails?.variantName)}</div>
                        </Col>
                        <Col className='data-show'>
                          <div className='data-head'>Segments</div>
                          <div className='data-view'>{variantDetails?.segment}</div>
                        </Col>
                        <Col className='data-show'>
                          <div className='data-head'>Transmission</div>
                          <div className='data-view'>{variantDetails?.transmissionType}</div>
                        </Col>
                        <Col className='data-show'>
                          <div className='data-head'>Fuel Type</div>
                          <div className='data-view'>{variantDetails?.fuelType}</div>
                        </Col>
                        <Col className='data-show'>
                          <div className='data-head'>Seats</div>
                          <div className='data-view'>{variantDetails?.seats}</div>
                        </Col>
                        <Col className='data-show'>
                          <div className='data-head'>Year</div>
                          <div className='data-view'>{variantDetails?.year}</div>
                        </Col>
                        <Col className='data-show'>
                          <div className='data-head'>Max Kms Range</div>
                          <div className='data-view'>{variantDetails?.maxKmsRange}</div>
                        </Col>
                      </Row>
                      <Row xs={1} className='my-2'>
                        <Col className='text-end'>
                          <Button className='m-2 button-theme' onClick={() => setCreateModal(true)}>Add Service</Button>
                        </Col>
                        <Col>
                          <Table className='table-bg table-scroll' borderless>
                            <thead>
                              <tr className="border-bottom text-middle">
                                <th>S.No</th>
                                <th>Service Name</th>
                                <th>Span Time</th>
                                <th>Span Km</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                variantDetails && variantDetails?.serviceDetails &&
                                  variantDetails?.serviceDetails?.length !== 0 ?
                                  variantDetails?.serviceDetails?.map((value, i) => {
                                    return (
                                      <tr key={i}>
                                        <td className='text-center'>{i + 1}</td>
                                        <td className='text-center'>{value?.serviceName && textCapital(value?.serviceName)}</td>
                                        <td className='text-center'>{(String(value?.spanTime)?.split(".")[0] || 0) + " Years "}{(String(value?.spanTime)?.split(".")[1] || 0) + " Months"}</td>
                                        <td className='text-center'>{(value?.spanKm || 0) + " KM"}</td>
                                        <td className='text-center'><i className="fa-solid fa-trash cursor-pointer-hover" onClick={() => openEditModel(value?.serviceId)}></i></td>
                                      </tr>
                                    )
                                  }) :
                                  <tr>
                                    <td colSpan={4} className='text-center'>No Services Found</td>
                                  </tr>
                              }
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={4} className='data-details-2' >
                      <h4 className='text-center mb-3 text-theme'>Total Cars</h4>
                      <Row>
                        <Col className='d-flex justify-content-center'>
                          <div className='total-count border rounded-circle' >0</div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
      }
    </React.Fragment>
  )
}

export default VariantView
