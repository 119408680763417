import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { bikeDataChange, bikeDataEmpty, bikeViewChange, bikeViewEmpty } from '../../../Reducer/bikeSlice';
import { getApiCall } from 'gfdu';
import { decrypt } from '../../../Helpers/Crypto';
import { toast } from 'react-toastify';
import BikeDetails from './BikeDetails';

const Index = () => {
    const { id } = useParams("id");
    const dispatch = useDispatch()
    const bike = useSelector((state) => state.bike)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        dispatch(bikeViewEmpty())
        dispatch(bikeDataEmpty())
        setLoading(false)
        getApiCall(`/admin/bike/${id}`, (response) => {
            if (response?.success) {
                const decryptedData = decrypt(response?.data)
                if (decryptedData?.success) {
                    dispatch(bikeViewChange(decryptedData?.data))
                    dispatch(bikeDataChange(decryptedData?.data))
                } else {
                    dispatch(bikeViewEmpty())
                }
            } else {
                dispatch(bikeViewEmpty())
                toast.error("Unable to Retrive Data")
            }
            setLoading(true)
        })
    }, [])
    return (
        <React.Fragment>
            {
                bike?.bikeView?.bikeId !== undefined ?
                    <>
                        <BikeDetails loading={loading} bikeId={id} />
                    </>
                    :
                    ""
            }
        </React.Fragment>
    );
}

export default Index;
