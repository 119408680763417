import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import Steper from '../../../Helpers/Components/Steper'
import Registration from "./Registration"
import ImageUpload from './ImageUpload'
import { useParams } from 'react-router-dom'

const Index = () => {
    const { id } = useParams("id");
    const [step,setStep] = useState(1)
    const [bikeId,setBikeId] = useState()
    const [associateId,setAssociateId] = useState("")
    useEffect(()=>{
        if (id) {
            setAssociateId(id)
        }
    })
    return (
        <React.Fragment>
            <Row className='mx-2 mb-5 justify-content-center'>
                <Col>
                <Row><Col className='text-center text-theme fs-3'>{associateId ? `Associate Bike` : "Own Bike"}</Col></Row>
                    <Card className='dark-card'>
                        <CardBody >
                            <Row className='mb-5'>
                                <Col>
                                    <Steper count={2} active={step} />
                                </Col>
                            </Row>
                            {
                                step === 1 ? <Registration setStep={setStep} associateId={associateId} setBikeId={setBikeId} /> : <ImageUpload bikeId={bikeId}/>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}



export default Index