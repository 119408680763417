import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row, ButtonGroup } from 'reactstrap'
import { getApiCall, patchApiCall, reloadWindow } from 'gfdu'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { decrypt, encrypt } from '../../Helpers/Crypto'
import { paymentDetailsChange  , taxDetailsChange } from '../../Reducer/siteDetailsSlice'
import { contentLoadingChange } from '../../Reducer/componentSlice'


const Payment = () => {
  const siteData = useSelector((state) => state.siteDetail)
  const dispatch = useDispatch()
  const [paymentDetails, setPaymentDetails] = useState({ razorpay: { publicKey: "", secretKey: "" }, COD: false })
  const [taxDetails,setTaxDetails] = useState({tax:{tds:"",gst:""}})
  const [disableButton,setDisableButton] = useState(false)
  const paymentHandleChange = (e) => {
    setPaymentDetails((prev) => ({
      ...prev,
      razorpay: {
        ...prev.razorpay,
        [e.target.name]: e.target.value
      },
    }))
  }
  const taxHandleChange = (e) => {
    setTaxDetails((prev) => ({
      ...prev,
      tax: {
        ...prev.tax,
        [e.target.name]: e.target.value
      },
    }))
  }
  const codChange = (value) => {
    setPaymentDetails((prev) => ({
      ...prev,
      COD: value
    }))
  }
  const onPaymentSubmit = () => {
    dispatch(contentLoadingChange(true))
    setDisableButton(true)
    if (paymentDetails?.razorpay?.publicKey === "") {
      dispatch(contentLoadingChange(false))
      toast.error("Invalid Public Key")
    } else if (paymentDetails?.razorpay?.secretKey === "") {
      dispatch(contentLoadingChange(false))
      toast.error("Invalid Secret Key")
    } else {
      patchApiCall("/admin/business/site/payment/update", encrypt(paymentDetails), (response) => {
        if (response?.success) {
          toast.success("Updated Successfully");
          setTimeout(() => {
            setDisableButton(false)
            reloadWindow()
          }, 2000);
        } else {
          setDisableButton(false)
          toast.error("Update Failed")
        }
        dispatch(contentLoadingChange(false))
      })
    }
  }
  const onTaxSubmit = () => {
    dispatch(contentLoadingChange(true))
    setDisableButton(true)
    if (taxDetails?.tax?.tds === "") {
      setDisableButton(false)
      dispatch(contentLoadingChange(false))
      toast.error("Invalid TDS")
    } else if (taxDetails?.tax?.gst === "") {
      setDisableButton(false)
      dispatch(contentLoadingChange(false))
      toast.error("Invalid GST")
    } else {
      patchApiCall("/admin/business/site/tax/update", encrypt({tax:{tds:Number(taxDetails?.tax?.tds),gst:Number(taxDetails?.tax?.gst)}}), (response) => {
        if (response?.success) {
          toast.success("Updated Successfully");
          setTimeout(() => {
            setDisableButton(false)
            reloadWindow()
          }, 2000);
        } else {
          setDisableButton(false)
          toast.error("Update Failed")
        }
        dispatch(contentLoadingChange(false))
      })
    }
  }
  useEffect(() => {
    getApiCall("/admin/business/site/payment/view", (response) => {
      if (response.success) {
        const decryptData = decrypt(response?.data)
        dispatch(paymentDetailsChange(decryptData?.data))
        setPaymentDetails(decryptData?.data)
      } else {
        toast.error("Failed to retrive details")
      }
    })    
    getApiCall("/admin/business/site/tax/view", (response) => {
      if (response.success) {
        const decryptData = decrypt(response?.data)
        dispatch(taxDetailsChange({ tax : decryptData?.data }))
        setTaxDetails({ tax:decryptData?.data})
      } else {
        toast.error("Failed to retrive details")
      }
    })
  }, [])
  return (
    <React.Fragment>
      <Container fluid>
        <Row className='flex-column'>
          <Col className='table-card' >
            <Card className='m-4 tab-card'>
              <CardBody>
                <Row className='justify-content-center' ><Col className='text-center fs-3 my-2' >Payment Details</Col></Row>
                <Row lg={2} className='mx-3' >
                  <Col className='py-4'>
                    <Row xs={1} className='justify-content-evenly'>
                      <Col>
                        <FormGroup row>
                          <Label sm={3}>Public Key</Label>
                          <Col><Input type='text' id='publicKey' name='publicKey' value={paymentDetails?.razorpay?.publicKey} onChange={paymentHandleChange} /></Col>
                        </FormGroup>
                      </Col>
                      <Col >
                        <FormGroup row>
                          <Label sm={3}>Secret Key </Label>
                          <Col><Input type='text' id='secretKey' name='secretKey' value={paymentDetails?.razorpay?.secretKey} onChange={paymentHandleChange} /></Col>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup row>
                          <Label sm={4}>Cash On Delivery</Label>
                          <Col>
                            <ButtonGroup>
                              <Button color="success" outline onClick={() => codChange(true)} active={paymentDetails?.COD}>Yes</Button>
                              <Button color="danger" outline onClick={() => codChange(false)} active={!paymentDetails?.COD}>No</Button>
                            </ButtonGroup>
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row >
                  <Col className='text-center' ><Button className='button-theme' disabled={disableButton} onClick={onPaymentSubmit} >Payment Update</Button></Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col className='table-card' >
            <Card className='m-4 tab-card'>
              <CardBody>
                <Row className='justify-content-center' ><Col className='text-center fs-3 my-2' >Tax Details</Col></Row>
                <Row lg={2} className='mx-3' >
                  <Col className='py-4'>
                    <Row xs={1} className='justify-content-evenly'>
                      <Col>
                        <FormGroup row>
                          <Label sm={3}>TDS</Label>
                          <Col><Input type='text' id='tds' name='tds' value={taxDetails?.tax?.tds} onChange={taxHandleChange} /></Col>
                        </FormGroup>
                      </Col>
                      <Col >
                        <FormGroup row>
                          <Label sm={3}>GST</Label>
                          <Col><Input type='text' id='gst' name='gst' value={taxDetails?.tax?.gst} onChange={taxHandleChange} /></Col>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row >
                  <Col className='text-center' ><Button className='button-theme' disabled={disableButton} onClick={onTaxSubmit} >Tax Update</Button></Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default Payment;