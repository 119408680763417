import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    carBrandList : [],
    carBrandView : {},
    carBrandData : {},
    togglers : {
      emailOpen : false,
      emailOtp : false,
      reasonOpen : false
    }
};

const carBrandSlice = createSlice({
  name: "carBrand",
  initialState,
  reducers: {
    carBrandDataEmpty : (state,{payload}) =>{
      state.carBrandData = {}
    },
    carBrandListEmpty : (state,{payload}) =>{
      state.carBrandList = []
    },
    carBrandViewEmpty : (state,{payload}) =>{
      state.carBrandView = {}
    },
    carBrandListChange : (state,{payload}) => {
      state.carBrandList = payload
    },
    carBrandViewChange : (state,{payload}) => {
      state.carBrandView = { ...state?.carBrandView , ...payload}
    },
    carBrandDataChange : (state,{payload}) => {
      state.carBrandData = { ...state?.carBrandData , ...payload}
    },
    togglersChange : (state,{payload}) => {
      state.togglers = { ...state?.togglers,...payload}
    }  
  }
});

export const { carBrandListChange , carBrandViewChange , carBrandDataChange , togglersChange , carBrandDataEmpty , carBrandListEmpty, carBrandViewEmpty } = carBrandSlice.actions
export default carBrandSlice.reducer;
