import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import { bikeDataChange, bikeDataEmpty } from '../../../Reducer/bikeSlice';
import { toast } from 'react-toastify';
import Select from "react-select";
import { postApiCall } from "gfdu";
import { decrypt, encrypt } from "../../../Helpers/Crypto";
import { contentLoadingChange } from "../../../Reducer/componentSlice"

const Registration = (props) => {
    const dispatch = useDispatch();
    const bikeData = useSelector((state) => state.bike?.bikeData || {});
    const [buttonDisable, setButtonDisable] = useState(false)
    const segmentOption = [
        { label: "Standard", value: "Standard" },
        { label: "Cruiser", value: "Cruiser" },
        { label: "Touring", value: "Touring" },
        { label: "Sport", value: "Sport" },
        { label: "Off-road", value: "Off-road" },
        { label: "Dual-purpose", value: "Dual-purpose" },
        { label: "Sport touring", value: "Sport touring" },
        { label: "Scooters", value: "Scooters" }
    ]
    const fuelOption = [
        { label: "Petrol", value: "Petrol" },
        { label: "EV", value: "EV" }
    ]
    const brandOption = [
        { label: "Royal Enfield", value: "Royal Enfield" },
        { label: "YAMAHA", value: "YAMAHA" },
        { label: "BAJAJ", value: "BAJAJ" },
        { label: "Honda", value: "Honda" },
        { label: "Hero", value: "Hero" },
        { label: "KTM", value: "KTM" },
        { label: "Keeway", value: "Keeway" },
        { label: "Suzuki", value: "Suzuki" },
        { label: "TOYOTA", value: "TOYOTA" },
        { label: "Aprilia", value: "Aprilia" },
        { label: "Benelli", value: "Benelli" },
        { label: "BMW", value: "BMW" },
        { label: "CFMoto", value: "CFMoto" },
        { label: "Ducati", value: "Ducati" },
        { label: "Harley Davidson", value: "Harley Davidson" },
        { label: "Husqvarna", value: "Husqvarna" },
        { label: "Indian", value: "Indian" },
        { label: "Jawa", value: "Jawa" },
        { label: "Kawasaki", value: "Kawasaki" },
        { label: "Moto Guzzi", value: "Moto Guzzi" },
        { label: "Moto Morini", value: "Moto Morini" },
        { label: "Norton", value: "Norton" },
        { label: "Triumph", value: "Triumph" },
        { label: "Yezdi", value: "Yezdi" },
        { label: "Zontes", value: "Zontes" },
        { label: "QJ Motor", value: "QJ Motor" },
        { label: "Benda", value: "Benda" },
        { label: "Brabus", value: "Brabus" },
        { label: "BSA", value: "BSA" },
        { label: "MBP Moto", value: "MBP Moto" },
        { label: "Brixton Motorcycles", value: "Brixton Motorcycles" }
    ]
    const handleChange = (e) => {
        dispatch(bikeDataChange({ [e.target.name]: e.target.value }));
    };
    const setSelect = (e, name) => {
        dispatch(bikeDataChange({ [name]: e.value }));
    };
    const onSubmit = () => {
        dispatch(contentLoadingChange(true))
        setButtonDisable(true)
        if (!/^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,3}[0-9]{4}$/?.test(bikeData?.registrationNo?.toUpperCase())) {
            toast.error("Invalid Registration No")
            dispatch(contentLoadingChange(false))
            setButtonDisable(false)
        } else if (bikeData?.brand === "" || bikeData?.brand === undefined) {
            toast.error("Invalid Brand Name")
            dispatch(contentLoadingChange(false))
            setButtonDisable(false)
        } else if (bikeData?.segment === "" || bikeData?.segment === undefined) {
            toast.error("Invalid Segment Name")
            dispatch(contentLoadingChange(false))
            setButtonDisable(false)
        } else if (bikeData?.fuelType === "" || bikeData?.fuelType === undefined) {
            toast.error("Invalid Fuel Type")
            dispatch(contentLoadingChange(false))
            setButtonDisable(false)
        } else if (bikeData?.model === "" || bikeData?.model === undefined) {
            toast.error("Invalid Model Name")
            dispatch(contentLoadingChange(false))
            setButtonDisable(false)
        } else if (bikeData?.color === "" || bikeData?.color === undefined) {
            toast.error("Invalid Colour Details")
            dispatch(contentLoadingChange(false))
            setButtonDisable(false)
        } else if (bikeData?.transmissionType === "" || bikeData?.transmissionType === undefined) {
            toast.error("Invalid Transmission Type")
            dispatch(contentLoadingChange(false))
            setButtonDisable(false)
        } else {
            const bike = encrypt({
                registrationNo: bikeData?.registrationNo?.toUpperCase(),
                brand: bikeData?.brand,
                segment: bikeData?.segment,
                model: bikeData?.model,
                color: bikeData?.color?.toUpperCase(),
                fuelType: bikeData?.fuelType,
                transmissionType: bikeData?.transmissionType,
                seats: Number(bikeData?.seats),
                fasTag: bikeData?.fasTag === "true" ? true : false,
                features: bikeData?.features
            })
            if (props?.associateId !== "" && props?.associateId !== undefined && props?.associateId !== null) {
                postApiCall(`/admin/bike/bikeAdd/${props?.associateId}`, bike, (response) => {
                    if (response?.success) {
                        const decryptedData = decrypt(response?.data)
                        props?.setBikeId(decryptedData?.data?.bikeId)
                        toast?.success("Bike Registered Success Fully")
                        setTimeout(() => {
                            setButtonDisable(false)
                            dispatch(contentLoadingChange(false))
                            props.setStep(2);
                        }, 2000);
                    } else {
                        dispatch(contentLoadingChange(false))
                        setButtonDisable(false)
                        toast?.error(response?.message || response?.msg || "Invalid Request")
                    }
                })
            } else {
                postApiCall("/admin/bike/newBike", bike, (response) => {
                    if (response?.success) {
                        const decryptedData = decrypt(response?.data)
                        props?.setBikeId(decryptedData?.data?.bikeId)
                        toast?.success("Bike Registered Success Fully")
                        setTimeout(() => {
                            props.setStep(2);
                            dispatch(contentLoadingChange(false))
                            setButtonDisable(false)
                        }, 2000);
                    } else {
                        dispatch(contentLoadingChange(false))
                        toast?.error(response?.message || response?.msg || "Invalid Request")
                        setButtonDisable(false)
                    }
                })
            }
        }
    }
    useEffect(() => {
        dispatch(bikeDataEmpty({}));
    }, []);

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Row className='flex-column'>
                        <Col>
                            <FormGroup row className='justify-content-center'>
                                <Label for="registrationNo" sm={3}>Registration No</Label>
                                <Col sm={5}>
                                    <Input id="registrationNo" name="registrationNo" autoComplete='off' onChange={handleChange} value={bikeData.registrationNo || ''} className='input-theme text-upper input-size-2' type="text" />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup row className='justify-content-center'>
                                <Label for="brand" sm={3}>Brand</Label>
                                <Col sm={5}>
                                    <Select id="brand" name="brand" autoComplete='off'
                                        styles={{
                                            control: (styles) => ({ ...styles, borderColor: "#ff4d32", "&:hover": { boxShadow: "0 0 0 3px #ff4d3215", borderColor: "#ff4d32" } }),
                                            option: (style, state) => ({ ...style, "&:hover": { backgroundColor: state.isSelected ? "#ff4d32" : "#ff4d3215" }, backgroundColor: state.isSelected && "#ff4d32" }),
                                        }}
                                        className='input-size-2'
                                        options={brandOption}
                                        onChange={(e) => setSelect(e, "brand")}
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup row className='justify-content-center'>
                                <Label for="segment" sm={3}>Segment</Label>
                                <Col sm={5}>
                                    <Select id="segment" name="segment" autoComplete='off'
                                        styles={{
                                            control: (styles) => ({ ...styles, borderColor: "#ff4d32", "&:hover": { boxShadow: "0 0 0 3px #ff4d3215", borderColor: "#ff4d32" } }),
                                            option: (style, state) => ({ ...style, "&:hover": { backgroundColor: state.isSelected ? "#ff4d32" : "#ff4d3215" }, backgroundColor: state.isSelected && "#ff4d32" }),
                                        }}
                                        className='input-size-2'
                                        options={segmentOption}
                                        onChange={(e) => setSelect(e, "segment")}
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup row className='justify-content-center'>
                                <Label for="model" sm={3}>Model</Label>
                                <Col sm={5}>
                                    <Input id="model" name="model" autoComplete='off' onChange={handleChange} value={bikeData.model || ''} className='input-theme text-upper input-size-2' type="text" />
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row className='flex-column'>
                        <Col>
                            <FormGroup row className='justify-content-center'>
                                <Label for="fuelType" sm={3}>Fuel</Label>
                                <Col sm={5}>
                                    <Select id="fuelType" name="fuelType" autoComplete='off'
                                        styles={{
                                            control: (styles) => ({ ...styles, borderColor: "#ff4d32", "&:hover": { boxShadow: "0 0 0 3px #ff4d3215", borderColor: "#ff4d32" } }),
                                            option: (style, state) => ({ ...style, "&:hover": { backgroundColor: state.isSelected ? "#ff4d32" : "#ff4d3215" }, backgroundColor: state.isSelected && "#ff4d32" }),
                                        }}
                                        className='input-size-2'
                                        options={fuelOption}
                                        onChange={(e) => setSelect(e, "fuelType")}
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup row className='justify-content-center'>
                                <Label for="color" sm={3}>Colour</Label>
                                <Col sm={5}>
                                    <Input id="color" name="color" autoComplete='off' onChange={handleChange} value={bikeData.color || ''} className='input-theme text-upper input-size-2' type="text" />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup row className='justify-content-center'>
                                <Label for="transmissionType" sm={3}>Transmission</Label>
                                <Col sm={5} className='d-flex justify-content-start align-items-center'>
                                    <div>
                                        <Input name="transmissionType" id='Gear' onChange={handleChange} value="Gear" checked={bikeData.transmissionType === 'Gear'} type="radio" />
                                        <Label for="Gear" className='mx-2'>Gear</Label>
                                    </div>
                                    <div>
                                        <Input name="transmissionType" id='Non-Gear' onChange={handleChange} value="Non-Gear" checked={bikeData.transmissionType === 'Non-Gear'} type="radio" />
                                        <Label for="Non-Gear" className='mx-2'>Non-Gear</Label>
                                    </div>
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row sm={1} className='my-2' >
                <Col className='text-center my-4'><Button className='button-theme' onClick={onSubmit} disabled={buttonDisable} > Update</Button></Col>
            </Row>

        </React.Fragment>
    );
};

export default Registration;