import { getApiCall } from 'gfdu'
import React, { useEffect, useState } from 'react'
import { decrypt } from '../../../Helpers/Crypto'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Card, CardBody, Col, Row, Table } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { formatDate } from '../../../Helpers/Utils'
import { carListChange, carListEmpty } from '../../../Reducer/carSlice'
import { bikeListChange, bikeListEmpty } from '../../../Reducer/bikeSlice'
import { contentLoadingChange } from '../../../Reducer/componentSlice'
import TablePlaceHolder from '../../../Helpers/Components/TablePlaceHolder'
import { toast } from 'react-toastify'

const VehicleData = () => {
    const { id } = useParams("id");
    const dispatch = useDispatch()
    const car = useSelector((state) => state.car)
    const bike = useSelector((state) => state.bike)
    const [loading, setLoading] = useState({ bike: false, car: false })
    const navigate = useNavigate()
    const [view, setView] = useState(false)
    useEffect(() => {
        setLoading({ bike: false, car: false })
        dispatch(contentLoadingChange(true))
        dispatch(carListEmpty())
        dispatch(bikeListEmpty())
        getApiCall(`/admin/associate/details/${id}`, (response) => {
            if (response?.success) {
                const decryptedData = decrypt(response?.data)
                if (decryptedData?.success) {
                    if (decryptedData?.data?.kyc?.aadhar && decryptedData?.data?.kyc?.pan) {
                        setView(true)
                    } else {
                        setView(false)
                    }
                } else {
                    toast.error("Unable to Retrive Data")
                }
            } else {
                toast.error("Unable to Retrive Data")
            }
            dispatch(contentLoadingChange(false))
        })
        getApiCall(`/admin/associate/car/details/${id}`, (response) => {
            if (response?.success) {
                const decryptedData = decrypt(response?.data)
                dispatch(carListChange(decryptedData?.data))
            } else {
                dispatch(carListEmpty())
            }
            setLoading((prev) => ({ ...prev, car: true }))
            dispatch(contentLoadingChange(false))
        })
        getApiCall(`/admin/associate/bike/details/${id}`, (response) => {
            if (response?.success) {
                const decryptedData = decrypt(response?.data)
                dispatch(bikeListChange(decryptedData?.data))
            } else {
                dispatch(bikeListEmpty())
            }
            setLoading((prev) => ({ ...prev, bike: true }))
            dispatch(contentLoadingChange(false))
        })



    }, [])

    return (
        <React.Fragment>
            {
                view ?
                    <>
                        <Row className='m-2 me-4'>
                            <div >
                                <Card className='table-card'>
                                    <CardBody className='table-content'>
                                        <Row className='m-2 justify-content-end'  >
                                            <Col><div className="d-flex justify-content-end"><Button className='button-theme' onClick={() => navigate(`/associate/registration/car/${id}`)} >Add Car</Button></div></Col>
                                        </Row>
                                        <Table className="table-bg" borderless >
                                            <thead>
                                                <tr className="border-bottom" >
                                                    <th>Car Id</th>
                                                    <th>Registration No</th>
                                                    <th>Brand</th>
                                                    <th>Segment </th>
                                                    <th>Model</th>
                                                    <th>Fuel</th>
                                                    <th>Owned By</th>
                                                    <th>Created At</th>
                                                    <th>Created By</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    loading.car ? (car?.carList && car?.carList?.length !== 0 ? car?.carList?.map((value, key) => {
                                                        return (
                                                            <tr key={key} className={`cursor-pointer-hover ${!value?.status && "text-red"}`} onClick={() => navigate(`/car/${value?.carId}`)} >
                                                                <td>{(value?.carId)?.toUpperCase()}</td>
                                                                <td>{(value?.registrationNo)?.toUpperCase()}</td>
                                                                <td>{value?.brand}</td>
                                                                <td>{value?.segment}</td>
                                                                <td>{value?.model}</td>
                                                                <td>{value?.fuelType}</td>
                                                                <td>{value?.ownerType === "MSD" ? "Company" : "Host"}</td>
                                                                <td>{formatDate(value?.createdAt)}</td>
                                                                <td>{value?.createdBy}</td>
                                                            </tr>
                                                        )
                                                    }) :
                                                        <tr className='text-center' ><td colSpan={9}>No Data Found</td></tr>)
                                                        : <TablePlaceHolder count={9} />
                                                }
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </div>
                        </Row>
                        <Row className='m-2 me-4'>
                            <div >
                                <Card className='table-card'>
                                    <CardBody className='table-content'>
                                        <Row className='m-2 justify-content-end' >
                                            <Col ><div className="d-flex justify-content-end"><Button className='button-theme' onClick={() => navigate(`/associate/registration/bike/${id}`)} >Add Bike</Button></div></Col>
                                        </Row>
                                        <Table className="table-bg" borderless >
                                            <thead>
                                                <tr className="border-bottom" >
                                                    <th>Bike Id</th>
                                                    <th>Registration No</th>
                                                    <th>Brand</th>
                                                    <th>Segment </th>
                                                    <th>Model</th>
                                                    <th>Fuel</th>
                                                    <th>Owned By</th>
                                                    <th>Created At</th>
                                                    <th>Created By</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    loading.bike ? (bike?.bikeList && bike?.bikeList?.length !== 0 ? bike?.bikeList?.map((value, key) => {
                                                        return (
                                                            <tr key={key} className={`cursor-pointer-hover ${!value?.status && "text-red"}`} onClick={() => navigate(`/bike/${value?.bikeId}`)} >
                                                                <td>{(value?.bikeId)?.toUpperCase()}</td>
                                                                <td>{(value?.registrationNo)?.toUpperCase()}</td>
                                                                <td>{value?.brand}</td>
                                                                <td>{value?.segment}</td>
                                                                <td>{value?.model}</td>
                                                                <td>{value?.fuelType}</td>
                                                                <td>{value?.ownerType === "MSD" ? "Our" : "Host"}</td>
                                                                <td>{formatDate(value?.createdAt)}</td>
                                                                <td>{value?.createdBy}</td>
                                                            </tr>
                                                        )
                                                    }) :
                                                        <tr className='text-center' ><td colSpan={9}>No Data Found</td></tr>)
                                                        : <TablePlaceHolder count={9} />
                                                }
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </div>
                        </Row>
                    </>
                    : ""
            }
        </React.Fragment>
    )
}

export default VehicleData