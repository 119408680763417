import React from 'react'

const PlaceHolder = (props) => {
  return (
    <React.Fragment>
        <h5 className="card-title placeholder-glow"><span className="placeholder bg-secondary w-100 col-12"></span></h5>
    </React.Fragment>
  )
}

export default PlaceHolder