import { getApiCall, patchApiCall, postApiCall, reloadWindow } from 'gfdu';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row, Table, UncontrolledDropdown } from 'reactstrap'
import { decrypt, encrypt } from '../../Helpers/Crypto';
import { toast } from 'react-toastify';
import Loader from '../../Helpers/Components/Loader';
import Select from "react-select";
import { formatDate, textCapital } from '../../Helpers/Utils';

const BrandView = () => {
    const { id } = useParams("id");
    const navigate = useNavigate()
    const [buttonDisable, setButtonDisable] = useState(false)
    const [brandDetails, setBrandDetails] = useState({})
    const [modelData, setModelData] = useState({})
    const [loading, setLoading] = useState(true)
    const [createModal, setCreateModal] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const carOption = [
        { label: "Hatchback", value: "Hatchback" },
        { label: "Sedan", value: "Sedan" },
        { label: "SUV", value: "SUV" },
        { label: "Electric Cars", value: "Electric Cars" },
        { label: "Luxury Cars", value: "Luxury Cars" },
        { label: "Crossovers", value: "Crossovers" }
    ]
    const bikeOption = [
        { label: "Commuter Bikes", value: "Commuter Bikes" },
        { label: "Sport Bikes", value: "Sport Bikes" },
        { label: "Cruiser Bikes", value: "Cruiser Bikes" },
        { label: "Adventure/Touring Bikes", value: "Adventure/Touring Bikes" },
        { label: "Electric Bikes", value: "Electric Bikes" }
    ]
    const setSelect = (e) => {
        setModelData((prev) => ({
            ...prev,
            segment: e.value
        }))
    }
    const handleChange = (e) => {
        setModelData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }
    const modelCreate = () => {
        if (modelData?.modelName === "" || modelData?.modelName?.trim()?.length === 0 || modelData?.modelName === undefined || modelData?.modelName === null || Number(modelData?.modelName) === "number") {
            toast?.error("Invalid Model Name")
        } else if (modelData?.segment === "") {
            toast?.error("Invalid Segment")
        } else {
            setButtonDisable(true)
            postApiCall(`/admin/vehicle/brand/model/${brandDetails?.brandId}`, encrypt({ vehicleType: brandDetails?.brandType === "Cars" ? "Car" : "Bike", modelName: modelData?.modelName, segment: modelData?.segment }), (response) => {
                if (response?.success) {
                    toast?.success("Registered Successfully")
                    setTimeout(() => {
                        reloadWindow()
                    }, 2000)
                } else {
                    toast?.error(response?.message)
                    setButtonDisable(false)
                }
            })
        }
    }
    const openEdit = (modelId) => {
        getApiCall(`/admin/vehicle/brand/model/${modelId}`, (response) => {
            if (response?.success) {
                const decryptedData = decrypt(response?.data)
                if (decryptedData?.success) {
                    setModelData(decryptedData?.data)
                    setEditModal(true)
                } else {
                    toast?.error("Invalid Response")
                }
            } else {
                toast?.error(response?.message || response?.msg)
            }
        })
    }
    const modelEdit = (modelId) => {
        if (modelData?.modelName === "" || modelData?.modelName?.trim()?.length === 0  || modelData?.modelName === undefined || modelData?.modelName === null || Number(modelData?.modelName) === "number") {
            toast?.error("Invalid Model Name")
        } else if (modelData?.segment === "") {
            toast?.error("Invalid Segment")
        } else {
            setButtonDisable(true)
            patchApiCall(`/admin/vehicle/brand/model/${modelId}`, encrypt({ modelName: modelData?.modelName, segment: modelData?.segment }), (response) => {
                if (response?.success) {
                    toast?.success("Updated Successfully")
                    setTimeout(() => {
                        reloadWindow()
                    }, 2000)
                } else {
                    toast?.error(response?.message)
                    setButtonDisable(false)
                }
            })
        }
    }
    useEffect(() => {
        setModelData({modelName : "" , segment : ""})
    }, [createModal])
    useEffect(() => {
        setBrandDetails({})
        setLoading(true)
        getApiCall(`/admin/vehicle/brand/${id}`, (response) => {
            if (response?.success) {
                const decryptedData = decrypt(response?.data)
                if (decryptedData?.success) {
                    setBrandDetails((prev) => ({
                        ...prev,
                        ...decryptedData?.data
                    }))
                    console.log(decryptedData?.data);

                } else {
                    setBrandDetails({})
                }
            } else {
                setBrandDetails({})
                toast.error("Unable to Retrive Data")
            }
            setLoading(false)
        })
    }, [])
    return (
        <React.Fragment>
            <Modal isOpen={createModal} toggle={() => setCreateModal(!createModal)}>
                <ModalHeader toggle={() => setCreateModal(!createModal)}></ModalHeader>
                <ModalBody>
                    <Row xs={1}>
                        <Col>
                            <h4 className='text-center text-theme my-3'>Create Model</h4>
                            <FormGroup row className='justify-content-center'>
                                <Label for="modelName" sm={3}>Model Name</Label>
                                <Col sm={5}>
                                    <Input id="modelName" name="modelName" autoComplete='off' className='input-theme text-upper input-size-2' type="text" value={modelData?.modelName} onChange={handleChange} />
                                </Col>
                            </FormGroup>
                            <FormGroup row className='justify-content-center'>
                                <Label for="brandType" sm={3}>Segment</Label>
                                <Col sm={5}>
                                    <Select id="brandType" name="brandType" autoComplete='off'
                                        styles={{
                                            control: (styles) => ({ ...styles, borderColor: "#ff4d32", "&:hover": { boxShadow: "0 0 0 3px #ff4d3215", borderColor: "#ff4d32" } }),
                                            option: (style, state) => ({ ...style, "&:hover": { backgroundColor: state.isSelected ? "#ff4d32" : "#ff4d3215" }, backgroundColor: state.isSelected && "#ff4d32" }),
                                        }}
                                        className='input-size-2'
                                        options={brandDetails?.brandType === "Cars" ? carOption : bikeOption}
                                        onChange={setSelect}
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col className='m-2 text-center'><Button className='button-theme' disabled={buttonDisable} onClick={modelCreate}>Register</Button></Col>
                    </Row>

                </ModalBody>
            </Modal>
            <Modal isOpen={editModal} toggle={() => setEditModal(!editModal)}>
                <ModalHeader toggle={() => setEditModal(!editModal)}></ModalHeader>
                <ModalBody >
                    <Row xs={1}>
                        <Col>
                            <h4 className='text-center text-theme my-3'>Edit Model</h4>
                            <FormGroup row className='justify-content-center'>
                                <Label for="modelName" sm={3}>Model Name</Label>
                                <Col sm={5}>
                                    <Input id="modelName" name="modelName" autoComplete='off' className='input-theme text-upper input-size-2' type="text" value={modelData?.modelName} onChange={handleChange} />
                                </Col>
                            </FormGroup>
                            <FormGroup row className='justify-content-center'>
                                <Label for="brandType" sm={3}>Segment</Label>
                                <Col sm={5}>
                                    <Select id="brandType" name="brandType" autoComplete='off'
                                        styles={{
                                            control: (styles) => ({ ...styles, borderColor: "#ff4d32", "&:hover": { boxShadow: "0 0 0 3px #ff4d3215", borderColor: "#ff4d32" } }),
                                            option: (style, state) => ({ ...style, "&:hover": { backgroundColor: state.isSelected ? "#ff4d32" : "#ff4d3215" }, backgroundColor: state.isSelected && "#ff4d32" }),
                                        }}
                                        className='input-size-2'
                                        options={brandDetails?.brandType === "Cars" ? carOption : bikeOption}
                                        value={{ value: modelData?.segment, label: modelData?.segment }}
                                        onChange={setSelect}
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col className='m-2 text-center'><Button className='button-theme' disabled={buttonDisable} onClick={() => modelEdit(modelData?.modelId)}>Update</Button></Col>
                    </Row>

                </ModalBody>
            </Modal>
            {
                loading ?
                    <Loader /> :
                    <Row xs={1}>
                        <Col className='text-center fs-2'>Brand Details</Col>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col className='text-center fs-3 text-theme'>{brandDetails?.brand}</Col>
                                    </Row>
                                    <Row className='justify-content-between'>
                                        <Col className='text-end mx-2'><Button className='button-theme' onClick={() => setCreateModal(!createModal)}>New Model</Button></Col>
                                    </Row>
                                    <Table className="table-bg my-2" borderless >
                                        <thead>
                                            <tr className="border-bottom text-center" >
                                                <th>S.No</th>
                                                <th>Model Id</th>
                                                <th>Model</th>
                                                <th>Segment</th>
                                                <th>Variants</th>
                                                <th>Created By</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                brandDetails?.models && brandDetails?.models?.length !== 0 ? brandDetails?.models?.map((value, i) => {
                                                    return (
                                                        <tr key={i} className={`text-center ${!value?.status && "text-red"}`} >
                                                            <td className='text-center'>{i + 1}</td>
                                                            <td>{value?.modelId?.toLowerCase()}</td>
                                                            <td>{textCapital(value?.modelName)}</td>
                                                            <td>{value?.segment}</td>
                                                            <td>{value?.variants || 0}</td>
                                                            <td>{formatDate(value?.createdAt)}</td>
                                                            <td className='d-flex justify-content-center'>
                                                                <UncontrolledDropdown className="mx-3" >
                                                                    <DropdownToggle className="nav-link not-button"><i className="fa-solid fa-ellipsis-vertical cursor-pointer "></i></DropdownToggle>
                                                                    <DropdownMenu container="body">
                                                                        <DropdownItem onClick={() => navigate(`/vehicle/model/${value?.modelId}`)}>View</DropdownItem>
                                                                        <DropdownItem onClick={() => openEdit(value?.modelId)}>Edit</DropdownItem>
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </td>
                                                        </tr>
                                                        // onClick={() => navigate(`/model/${value?.modelId}`)}
                                                    )
                                                }) :
                                                    <tr className='text-center' ><td colSpan={9}>No Data Found</td></tr>
                                            }
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
            }
        </React.Fragment>
    )
}

export default BrandView
