import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ButtonGroup, Card, CardBody, Col, FormGroup, Input, Label, Row, Spinner, Table } from 'reactstrap'
import { customerDataChange } from '../../../Reducer/customerSlice'
import { contentLoadingChange } from '../../../Reducer/componentSlice';
import { toast } from 'react-toastify'
import { patchApiCall, reloadWindow } from 'gfdu'
import { decrypt, encrypt } from '../../../Helpers/Crypto'
import { formatDate, parseDateString } from '../../../Helpers/Utils'
import DatePicker from 'react-multi-date-picker'
import { useParams } from 'react-router-dom'
import PlaceHolder from '../../../Helpers/Components/PlaceHolder';

const KycDetails = (props) => {
    const { id } = useParams("id");
    const dispatch = useDispatch()
    const customer = useSelector((state) => state.customer)
    const [buttonDisable, setButtonDisable] = useState(true)
    const [document, setDocument] = useState(1)
    const [disable, setDisable] = useState(false)
    const [dob, setDob] = useState((new Date()).setFullYear(((new Date()).getFullYear()) - 18))
    const handleChange = (e) => {
        dispatch(customerDataChange({ [e?.target?.name]: e?.target?.value }))
    }
    const aadharOtpSend = () => {
        dispatch(contentLoadingChange(true))
        if (customer?.customerData?.aadharNo?.length !== 12 && Number(customer?.customerData?.aadharNo) == customer?.customerData?.aadharNo) {
            toast.error("Invalid Aadhar No")
            dispatch(contentLoadingChange(false))
        } else {
            setDisable(true)
            patchApiCall(`/admin/customer/kyc/aadhar/otp/${id}`, encrypt({ aadharNo: Number(customer?.customerData?.aadharNo) }), (response) => {
                if (response?.success) {
                    const decryptedData = decrypt(response?.data)
                    if (decryptedData?.success) {
                        const validKeys = {
                            reference_id: decryptedData?.data?.reference_id,
                            decentroTxnId: decryptedData?.data?.decentroTxnId
                        }
                        dispatch(customerDataChange(validKeys))
                        toast.success("Otp Sended")
                        setButtonDisable(false)
                    } else {
                        toast.error("Invalid Aadhar Card")
                    }
                } else {
                    toast.error(response?.msg || response?.message || "Invalid Aadhar No !")
                }
                dispatch(contentLoadingChange(false))
                setDisable(false)
            })
        }
    }
    const aadharVerify = () => {
        dispatch(contentLoadingChange(true))
        if (customer?.customerData?.aadharNo?.length !== 12) {
            dispatch(contentLoadingChange(false))
            toast.error("Invalid Aadhar No")
        } else if (customer?.customerData?.otp?.length !== 6) {
            dispatch(contentLoadingChange(false))
            toast.error("Invalid Aadhar Otp")
        } else {
            setDisable(true)
            const validKeys = {
                aadharNo: customer?.customerData?.aadharNo,
                reference_id: customer?.customerData.reference_id,
                decentroTxnId: customer?.customerData?.decentroTxnId,
                otp: customer?.customerData?.otp
            }
            patchApiCall(`/admin/customer/kyc/aadhar/verify/${id}`, encrypt(validKeys), (response) => {
                if (response?.success) {
                    toast?.success("Aadhar Vertified Successfully")
                    setTimeout(() => {
                        setDisable(false)
                        reloadWindow()
                    }, 2000);
                } else {
                    setDisable(false)
                    toast.error(response?.msg || response?.message || "Invalid Aadhar No")
                }
                dispatch(contentLoadingChange(false))
            })
        }
    }
    const DlVerify = () => {
        setButtonDisable(false)
        dispatch(contentLoadingChange(true))
        const date = new Date(dob)
        const dateOfBirth = formatDate(date)
        if (!/^([A-Z]{2})(\d{2}|\d{3})[a-zA-Z]{0,1}(\d{4})(\d{7})$/.test(customer?.customerData?.dlNo?.toUpperCase())) {
            toast.error("Invalid DL No")
            dispatch(contentLoadingChange(false))
            setButtonDisable(true)
        } else {
            patchApiCall(`/admin/customer/kyc/dl/${id}`, encrypt({ dlNo: customer?.customerData?.dlNo?.toUpperCase(), dob: dateOfBirth }), (response) => {
                if (response?.success) {
                    toast?.success("DL Vertified Successfully")
                    setTimeout(() => {
                        setButtonDisable(true)
                        reloadWindow()
                    }, 2000);
                } else {
                    setButtonDisable(true)
                    toast.error(response?.msg || response?.message || "Invalid dlNo")
                }
                dispatch(contentLoadingChange(false))
            })
        }
    }
    const VoterVerify = () => {
        dispatch(contentLoadingChange(true))
        setButtonDisable(false)
        if (!/^([a-zA-Z]){3}([0-9]){7}$/.test(customer?.customerData?.voterId)) {
            dispatch(contentLoadingChange(false))
            setButtonDisable(true)
            toast.error("Invalid Voter No")
        } else {
            patchApiCall(`/admin/customer/kyc/voter/${id}`, encrypt({ voterId: customer?.customerData?.voterId.toUpperCase() }), (response) => {
                if (response?.success) {
                    toast?.success("Voter Verified Successfully")
                    setTimeout(() => {
                        reloadWindow()
                        setButtonDisable(true)
                    }, 2000);
                } else {
                    setButtonDisable(true)
                    toast.error(response?.msg || response?.message || "Invalid Voter No")
                }
                dispatch(contentLoadingChange(false))
            })
        }
    }
    const PassportVerify = () => {
        dispatch(contentLoadingChange(true))
        const date = new Date(dob)
        const dateOfBirth = formatDate(date)
        setButtonDisable(false)
        if (!/^[a-zA-Z\s]{2,}$/.test(customer?.customerData?.name)) {
            dispatch(contentLoadingChange(false))
            toast.error("Invalid Name")
            setButtonDisable(true)
        } else if (!/^[a-zA-Z0-9]{12,15}$/.test(customer?.customerData?.passportNo)) {
            dispatch(contentLoadingChange(false))
            toast.error("Invalid Passport No")
            setButtonDisable(true)
        } else {
            patchApiCall(`/admin/customer/kyc/passport/${id}`, encrypt({ name: customer?.customerData?.name?.toUpperCase(), passportNo: customer?.customerData?.passportNo?.toUpperCase(), dob: dateOfBirth }), (response) => {
                if (response?.success) {
                    toast?.success("Passport Verified Successfully")
                    setTimeout(() => {
                        reloadWindow()
                        setButtonDisable(true)
                    }, 2000);
                } else {
                    setButtonDisable(true)
                    toast.error(response?.message || response?.msg || "Invalid Passport Details")
                }
                dispatch(contentLoadingChange(false))
            })
        }
    }
    return (
        <React.Fragment>
            <Row xs={1} sm={2} lg={3} className='mx-2 my-3' >
                <Col className='my-2' >
                    <Card className='kyc-card'>
                        <CardBody >
                            {
                                customer?.customerView?.email?.status ?
                                    <>
                                        {
                                            (customer?.customerView?.kyc?.aadhar?.aadharNo === "" || customer?.customerView?.kyc?.aadhar?.aadharNo === undefined) ?
                                                <>
                                                    {
                                                        (customer?.customerView?.status === false || customer?.customerView?.email?.status === false) ?
                                                            <i className='verify-lock bx bxs-lock' ></i> :
                                                            <>
                                                                <div className='text-center mb-3'><h4>Aadhar Details</h4></div>
                                                                <FormGroup row className='justify-content-center'>
                                                                    <Label for="aadharNo" sm={4}>Aadhar No</Label>
                                                                    <Col sm={8}>{buttonDisable ? <Input id="aadharNo" name="aadharNo" autoComplete='off' value={customer?.customerData?.aadharNo || ""} onChange={(e) => { e.target.value.length <= 12 && (Number(e.target.value) == e.target.value) && handleChange(e) }} className='input-theme' type="text" /> : <><span className='me-3'>{customer?.customerData?.aadharNo}</span><i onClick={() => setButtonDisable(true)} className='bx bxs-pencil' ></i></>}</Col>
                                                                </FormGroup>
                                                                {
                                                                    !buttonDisable &&
                                                                    <FormGroup row className='justify-content-center'>
                                                                        <Label for="otp" sm={4}>Otp</Label>
                                                                        <Col sm={8}><Input id="otp" name="otp" autoComplete='off' value={customer?.customerData?.otp || ""} onChange={(e) => { e.target.value.length <= 6 && (Number(e.target.value) == e.target.value) && handleChange(e) }} className='input-theme input-size-1' type="text" /></Col>
                                                                    </FormGroup>
                                                                }
                                                                {
                                                                    buttonDisable ?
                                                                        <div className='text-center'><Button className='button-theme' disabled={disable} onClick={aadharOtpSend}>Send Otp</Button></div>
                                                                        :
                                                                        <div className='text-center'><Button className='button-theme' disabled={disable} onClick={aadharVerify}>Verify Aadhar</Button></div>
                                                                }
                                                            </>
                                                    }
                                                </>
                                                :
                                                <>
                                                    <div className='text-center mb-3'><h4>Aadhar Details</h4></div>
                                                    <div className='profile-data mb-3'><span>Aadhar No</span><span>{props?.loading ? (customer?.customerView?.kyc?.aadhar?.aadharNo || "-") : <PlaceHolder />}</span></div>
                                                    <div className='profile-data mb-3'><span>House No</span><span>{props?.loading ? (customer?.customerView?.kyc?.aadhar?.aadharDetails?.proofOfAddress?.house || "-") : <PlaceHolder />}</span></div>
                                                    <div className='profile-data mb-3'><span>Street</span><span>{props?.loading ? (customer?.customerView?.kyc?.aadhar?.aadharDetails?.proofOfAddress?.street || "-") : <PlaceHolder />}</span></div>
                                                    <div className='profile-data mb-3'><span>District</span><span>{props?.loading ? (customer?.customerView?.kyc?.aadhar?.aadharDetails?.proofOfAddress?.district || "-") : <PlaceHolder />}</span></div>
                                                    <div className='profile-data mb-3'><span>State</span><span>{props?.loading ? (customer?.customerView?.kyc?.aadhar?.aadharDetails?.proofOfAddress?.state || "-") : <PlaceHolder />}</span></div>
                                                </>
                                        }
                                    </> :
                                    <i className='verify-lock bx bxs-lock' ></i>
                            }
                        </CardBody>
                    </Card>
                </Col>
                <Col className='my-2' >
                    <Card className='kyc-card'>
                        <CardBody>
                            {
                                customer?.customerView?.kyc?.aadhar?.aadharNo !== undefined ?
                                    customer?.customerView?.kyc?.dl?.dlNo === undefined ?
                                        (customer?.customerView?.status === false || customer?.customerView?.email?.status === false) ?
                                            <i className='verify-lock bx bxs-lock' ></i> :
                                            <>
                                                <div className='text-center mb-3'><h4>License Details</h4></div>
                                                <FormGroup row className='justify-content-center'>
                                                    <Label for="licenseNo" sm={4}>License No</Label>
                                                    <Col sm={6}><Input id="dlNo" name="dlNo" autoComplete='off' value={customer?.customerData?.dlNo || ""} onChange={(e) => { e.target.value.length <= 15 && handleChange(e) }}  disabled={!buttonDisable} className='input-theme text-upper' type="text" /></Col>
                                                </FormGroup>
                                                <FormGroup row className='justify-content-center'>
                                                    <Label for="dob" sm={4}>DOB</Label>
                                                    <Col sm={6}><DatePicker className='calender-theme' disabled={!buttonDisable} inputClass='date-theme' format='DD-MM-YYYY' value={dob} onChange={(e) => setDob(e)} minDate={(new Date()).setFullYear(((new Date()).getFullYear()) - 100)} maxDate={(new Date()).setFullYear(((new Date()).getFullYear()) - 18)} /></Col>
                                                </FormGroup>
                                                <div className='text-center'><Button className='button-theme mx-5' disabled={!buttonDisable} onClick={DlVerify}>Verify</Button></div>
                                            </>
                                        :
                                        <>
                                            <div className='text-center mb-3'><h4>Licence Details</h4></div>
                                            <div className='profile-data mb-3'><span>Licence No</span><span>{props?.loading ? (customer?.customerView?.kyc?.dl?.dlNo || "-") : <PlaceHolder />}</span></div>
                                            <div className='profile-data mb-3'><span>Date Of Birth</span><span>{props?.loading ? (customer?.customerView?.kyc?.aadhar?.aadharDetails?.proofOfIdentity?.dob || "-") : <PlaceHolder />}</span></div>
                                            <div className='profile-data mb-3'><span>Validity</span><span>{props?.loading ? ((customer?.customerView?.kyc?.dl?.dlDate && formatDate(customer?.customerView?.kyc?.dl?.dlDate)) || "-") : <PlaceHolder />}</span></div>
                                            <div className='profile-data mb-3'><span>Dl Status</span><span>{(new Date(customer?.customerView?.kyc?.dl?.dlDate) > (new Date())) ? <span className='text-green'>Active</span> : <span className='text-red'>Deactive</span>}</span></div>
                                        </>
                                    :
                                    <i className='verify-lock bx bxs-lock' ></i>
                            }
                        </CardBody>
                    </Card>
                </Col>
                <Col className='my-2' >
                    <Card className='kyc-card'>
                        <CardBody >
                            {
                                customer?.customerView?.kyc?.aadhar?.aadharNo !== undefined ?
                                    customer?.customerView?.kyc?.dl?.dlNo !== undefined ?
                                        (customer?.customerView?.kyc?.voter !== undefined || customer?.customerView?.kyc?.passport !== undefined) ?
                                            <>
                                                <div className='text-center mb-3'><h4>Verified Document</h4></div>
                                                <div className='profile-data mb-3'><span>Document No</span><span>{props?.loading ? (customer?.customerView?.kyc?.voter || customer?.customerView?.kyc?.passport || "-") : <PlaceHolder />}</span></div>
                                                <div className='profile-data mb-3'><span>Document Type</span><span>{props?.loading ? (customer?.customerView?.kyc?.voter && "Voter Id" || customer?.customerView?.kyc?.passport && "Passport" || "-") : <PlaceHolder />}</span></div>
                                                <p className='profile-data'><span>Document Status</span><span className='text-green'>Verified</span></p>
                                            </>
                                            :
                                            (customer?.customerView?.status === false || customer?.customerView?.email?.status === false) ?
                                                <i className='verify-lock bx bxs-lock' ></i> :
                                                <>
                                                    <div className='text-center mb-3'><h4>Verified Document</h4></div>
                                                    <div className='text-center mb-3' >
                                                        <ButtonGroup className='table-button' size="sm">
                                                            <Button outline className={document === 1 ? "t-btn-s" : ""} onClick={() => setDocument(1)} >Voter</Button>
                                                            <Button outline className={document === 2 ? "t-btn-s" : ""} onClick={() => setDocument(2)}>Passport</Button>
                                                        </ButtonGroup>
                                                    </div>
                                                    {
                                                        document === 1 ?
                                                            <>
                                                                <FormGroup row className='justify-content-center'>
                                                                    <Label for="voterId" sm={4}>Voter Id</Label>
                                                                    <Col sm={6}><Input id="voterId" name="voterId" disabled={!buttonDisable} autoComplete='off' value={customer?.customerData?.voterId || ""} onChange={(e) => { e.target.value.length <= 15 && handleChange(e) }} className='input-theme text-upper' type="text" /></Col>
                                                                </FormGroup>
                                                                <div className='text-center'><Button className='button-theme mx-5' disabled={!buttonDisable} onClick={VoterVerify}>Verify</Button></div>
                                                            </>
                                                            :
                                                            <>
                                                                <FormGroup row className='justify-content-center'>
                                                                    <Label for="name" sm={3}>Name </Label>
                                                                    <Col sm={5}>
                                                                        <Input id="name" name="name" autoComplete='off' disabled={!buttonDisable} value={customer?.customerData?.name || ""} onChange={(e) => { handleChange(e) }} className='input-theme input-size-1 text-upper' type="text" />
                                                                    </Col>
                                                                </FormGroup>
                                                                <FormGroup row className='justify-content-center'>
                                                                    <Label for="passportNo" sm={3}>File No</Label>
                                                                    <Col sm={5}>
                                                                        <Input id="passportNo" name="passportNo" autoComplete='off' disabled={!buttonDisable} value={customer?.customerData?.passportNo || ""} onChange={(e) => { e.target.value.length <= 15 && handleChange(e) }} className='input-theme input-size-1 text-upper' type="text" />
                                                                    </Col>
                                                                </FormGroup>
                                                                <FormGroup row className='justify-content-center'>
                                                                    <Label for="dob" sm={3}>DOB</Label>
                                                                    <Col sm={5}>
                                                                        <DatePicker className='calender-theme' calendarPosition='left' disabled={!buttonDisable} format='DD-MM-YYYY' inputClass="date-theme" value={dob} onChange={setDob} minDate={(new Date()).setFullYear(((new Date()).getFullYear()) - 100)} maxDate={(new Date()).setFullYear(((new Date()).getFullYear()) - 18)} />
                                                                    </Col>
                                                                </FormGroup>
                                                                <div className='text-center'><Button className='button-theme mx-5' disabled={!buttonDisable} onClick={PassportVerify}>Verify</Button></div>
                                                            </>
                                                    }
                                                </>
                                        :
                                        <i className='verify-lock bx bxs-lock' ></i>
                                    :
                                    <i className='verify-lock bx bxs-lock' ></i>
                            }

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default KycDetails