import React, { createContext, useContext, useEffect, useState } from "react";
import { getCookie ,setCookie , removeCookie, reloadWindowToPath } from "gfdu";

const AuthContext = createContext();

function AuthenticateProvider({ children }) {
  const logIn = (token , userId) => {
    setCookie("token", token);
    setCookie("userId", userId);
    setIsAuthenticated(true);
  };
  const token = getCookie("token")
  const logOut = () => {
    removeCookie("token");
    removeCookie("userId");
    setIsAuthenticated(false);
    reloadWindowToPath("/");
  };
  const [isAuthenticated, setIsAuthenticated] = useState(!!token);
  useEffect(() => {
    if (token) {
      setIsAuthenticated(true);
    }
  }, [token]);

  return (
    <AuthContext.Provider value={{ logIn, logOut, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthenticateProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
