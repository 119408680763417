import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Card, CardBody, Button, Modal, ModalBody, FormGroup, Label, Input, ModalHeader } from 'reactstrap';
import { formatDate, textCapital } from '../../../Helpers/Utils';
import { toast } from 'react-toastify';
import { patchApiCall, reloadWindow } from 'gfdu';
import { encrypt } from '../../../Helpers/Crypto';
import { bikeDataChange } from '../../../Reducer/bikeSlice';
import { contentLoadingChange } from '../../../Reducer/componentSlice'
import ImageUpload from './ImageUpload';
import EditDetails from './EditDetails';
import PlaceHolder from '../../../Helpers/Components/PlaceHolder';


const CarDetails = (props) => {
    const dispatch = useDispatch()
    const inputRef = useRef()
    const imageRef = useRef()
    const bike = useSelector((state) => state.bike)
    const [bikeItems, setCarItems] = useState([])
    const [viewReason, setViewReason] = useState(false)
    const [disableButton, setButtonDisable] = useState(false)
    const [reasonToggle, setReasonToggle] = useState(false)
    const [selectedImage, setSelectedImage] = useState("")
    const [imageOpen, setImageOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [openImage, setOpenImage] = useState(false)
    const [viewCar, setViewCar] = useState("")
    const changeStatus = (status) => {
        dispatch(contentLoadingChange(true))
        setButtonDisable(true)
        if (status === false && (bike?.bikeData?.reason === undefined || bike?.bikeData?.reason === "" || bike?.bikeData?.reason?.length <= 5)) {
            setButtonDisable(false)
            dispatch(contentLoadingChange(false))
            if (bike?.bikeData?.reason?.length !== 0 && bike?.bikeData?.reason?.length <= 5) {
                return toast.error("Reason is not enough")
            } else {
                return toast.error("Reason is required")
            }
        }
        patchApiCall(`/admin/bike/status/${props?.bikeId}?status=${status}`, encrypt({ ...(status === false ? { reason: bike?.bikeData?.reason } : {}) }), (response) => {
            if (response?.success) {
                toast.success(`${status ? "Active Successfully" : "Deactive Successfully"}`)
                setTimeout(() => {
                    reloadWindow()
                }, 2000);
            } else {
                setButtonDisable(false)
                toast.error(response.msg || response.message || "Status Couldn't Update")
            }
            dispatch(contentLoadingChange(false))
        })
    }
    const handleChange = (e) => {
        dispatch(bikeDataChange({ [e?.target?.name]: e?.target?.value }))
    }
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file !== undefined) {
            setViewCar(URL.createObjectURL(file))
            imageRef.current = file
        }
    }
    const updateImage = async () => {
        dispatch(contentLoadingChange(true))
        setButtonDisable(true)
        const formData = new FormData();
        formData.append("bike", imageRef.current)
        const imgId = (inputRef.current)?.split("/")?.pop()?.split(".")[0]
        patchApiCall(`/admin/bike/bikeImage/${props?.bikeId}?imageId=${imgId}`, formData, (response) => {
            if (response?.success) {
                toast.success("Update Successfully");
                setTimeout(() => {
                    reloadWindow()
                    setButtonDisable(false)
                }, 2000);
            } else {
                toast.error(response?.msg || response?.message || "Update Failed");
                setButtonDisable(false)
            }
            dispatch(contentLoadingChange(false))
        })
    }
    const viewImage = (imgId) => {
        inputRef.current = imgId
        setViewCar(imgId)
        setOpenImage(true)
    }
    useEffect(() => {
        if (bike?.bikeView?.bikeImages && bike?.bikeView?.bikeImages?.length !== 0) {
            setCarItems(
                bike?.bikeView?.bikeImages?.map((value, key) => ({
                    src: value,
                    altText: `Bike ${key + 1}`,
                    key: `Bike ${key + 1}`
                }))
            )
        }
    }, [])
    useEffect(() => {
        if (bikeItems?.length !== 0) {
            setSelectedImage(bikeItems[0])
        }
    }, [bikeItems])

    return (
        <React.Fragment>
            <Modal isOpen={viewReason} toggle={() => setViewReason(!viewReason)}>
                <ModalHeader toggle={() => setViewReason(!viewReason)} className='text-center' >Reason</ModalHeader>
                <ModalBody className='reason-modal'>{bike?.bikeView?.errorMsg}</ModalBody>
            </Modal>
            <Modal isOpen={reasonToggle} toggle={() => setReasonToggle(!reasonToggle)} >
                <ModalBody >
                    <Row className=' flex-column justify-content-center align-items-center'>
                        <Col>
                            <FormGroup row className='justify-content-center'>
                                <Label for="reason" sm={3}>Reason</Label>
                                <Col sm={7}>
                                    <Input id='reason' name='reason' className='input-theme' value={bike?.bikeData?.reason} onChange={handleChange} />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col><div className='text-center' ><Button color='danger' disabled={disableButton} onClick={() => changeStatus(false)} >Deactive</Button></div></Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Modal isOpen={imageOpen} size='xl' toggle={() => setImageOpen(!imageOpen)} >
                <ModalBody >
                    <Row className='my-3' ><Col className='text-center'><h4>Bike Upload</h4></Col></Row>
                    <ImageUpload bikeId={props?.bikeId} setImageOpen={setImageOpen} />
                </ModalBody>
            </Modal>
            <Modal isOpen={editOpen} size='xl' toggle={() => setEditOpen(!editOpen)}>
                <ModalBody >
                    {/* <Row className='my-3' ><Col className='text-center'><h4>Edit Details</h4></Col></Row> */}
                    <Row className='my-3' >
                        <Col className='text-center'>
                            <h5>Bike Id : {bike?.bikeView?.bikeId}</h5>
                        </Col>
                    </Row>
                    <EditDetails bikeId={props?.bikeId} setEditOpen={setEditOpen} />
                </ModalBody>
            </Modal>
            <Modal isOpen={openImage} size='xl' toggle={() => setOpenImage(!openImage)}>
                <ModalBody >
                    <Row className='flex-column' >
                        <Col><div className='view-bike'><img src={viewCar} alt="view" /></div></Col>
                        <Col className='text-center my-3'><div className='input-profile'><input type='file' id='photo' onChange={handleFileChange} /></div><Button tag={"label"} for="photo" className='button-theme'>Change</Button></Col>
                        {
                            viewCar === inputRef.current
                                ? "" :
                                <Col className='text-center my-2'><div className='d-flex justify-content-evenly'><Button className='button-theme' disabled={disableButton} onClick={updateImage}>Update </Button><Button color='danger' disabled={disableButton} onClick={() => setOpenImage(false)}>Close</Button></div></Col>
                        }
                    </Row>
                </ModalBody>
            </Modal>
            <Row className="m-2" >
                <Col lg={7} className='my-2'>
                    <Card className='bike-main-view' >
                        <CardBody>
                            <div >
                                {
                                    bike?.bikeView?.bikeImages && bike?.bikeView?.bikeImages?.length !== 0 ?
                                        <div className='bike-image-view'>
                                            <div className='bike-image-main'>
                                                <div className='bike-hover-image'>
                                                    <Button className='button-theme mx-1' onClick={() => viewImage(selectedImage?.src)} >View</Button>
                                                </div>
                                                <img src={selectedImage?.src || ""} alt={selectedImage?.altText} />
                                            </div>
                                            {
                                                bikeItems && bikeItems?.length !== 0 &&
                                                bikeItems?.map((value, i) => {
                                                    if (i < 10) {
                                                        return (
                                                            <div onClick={() => setSelectedImage(value)} className={`bike-images ${selectedImage?.key === value?.key ? "active-bike-image" : ""}`} key={value?.key}>
                                                                {selectedImage?.key === value?.key ? <div className='blur' ><i className='text-white bx bx-expand'></i></div> : ""}
                                                                <img src={value?.src} alt={value?.altText} />
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </div> :
                                        <div className='image-upload'>
                                            <Button className='button-theme' onClick={() => setImageOpen(true)} >Upload Images</Button>
                                        </div>
                                }
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={5} className='my-2'>
                    <Card className='bike-main-view' >
                        <CardBody>
                            <Row className='flex-column'>
                                <Col>
                                    <div className='profile-data mb-3'><span>Bike ID</span><span>{(props?.loading ? (bike?.bikeView?.bikeId || "-") : <PlaceHolder />)} <i className='ms-2 profile-edit bx bx-edit' onClick={() => setEditOpen(true)} ></i></span></div>
                                    <div className='profile-data mb-3'><span>Owned By</span><span>{(props?.loading ? (textCapital(bike?.bikeView?.ownerType) || "-") : <PlaceHolder />)}</span></div>
                                    <div className='profile-data mb-3'><span>Created By</span><span>{(props?.loading ? (textCapital(bike?.bikeView?.createdBy) || "-") : <PlaceHolder />)}</span></div>
                                    <div className='profile-data mb-3'><span>Created At</span><span>{(props?.loading ? (formatDate(bike?.bikeView?.createdAt) || "-") : <PlaceHolder />)}</span></div>
                                    <div className='profile-data mb-3'><span>Registration No</span><span>{(props?.loading ? (bike?.bikeView?.registrationNo?.toUpperCase() || "-") : <PlaceHolder />)}</span></div>
                                    <div className='profile-data mb-3'><span>Brand</span><span>{(props?.loading ? ((bike?.bikeView?.brand)?.toUpperCase() || "-") : <PlaceHolder />)}</span></div>
                                    <div className='profile-data mb-3'><span>Model</span><span>{(props?.loading ? ((bike?.bikeView?.model)?.toUpperCase() || "-") : <PlaceHolder />)}</span></div>
                                    <div className='profile-data mb-3'><span>Segment</span><span>{(props?.loading ? ((bike?.bikeView?.segment)?.toUpperCase() || "-") : <PlaceHolder />)}</span></div>
                                    <div className='profile-data mb-3'><span>Transmission</span><span>{(props?.loading ? ((bike?.bikeView?.transmissionType)?.toUpperCase() || "-") : <PlaceHolder />)}</span></div>
                                    <div className='profile-data mb-3'><span>Fuel</span><span>{(props?.loading ? (bike?.bikeView?.fuelType || "-") : <PlaceHolder />)}</span></div>
                                    <div className='profile-data mb-3'><span>Colour</span><span>{props?.loading ? ((bike?.bikeView?.color)?.toUpperCase() || "-") : <PlaceHolder />}</span></div>
                                    <p className='profile-data'>
                                        <span>Status</span>
                                        <span>
                                            {
                                                !bike?.bikeView?.status ?
                                                    <Button color="success" disabled={disableButton} onClick={() => changeStatus(true)}>Active</Button> :
                                                    <Button color="danger" onClick={() => setReasonToggle(true)}>Deactive</Button>
                                            }
                                        </span>
                                    </p>
                                    {
                                        bike?.bikeView?.status === false && <p className='profile-data' ><span>Reason</span><span><Button className='button-theme' onClick={() => setViewReason(!viewReason)}>View</Button></span></p>
                                    }
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default CarDetails;