import { postApiCall, reloadWindow } from 'gfdu'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, ButtonGroup, Card, CardBody, Col, Container, Input, Row, Table } from 'reactstrap'
import { decrypt, encrypt } from '../../Helpers/Crypto'
import { useDispatch, useSelector } from 'react-redux'
import { customerListChange } from "../../Reducer/customerSlice"
import { formatDate, textCapital } from '../../Helpers/Utils'
import { toast } from 'react-toastify'
import Pagination from '../../Helpers/Components/Pagination'
import TablePlaceHolder from "../../Helpers/Components/TablePlaceHolder"
import { contentLoadingChange } from '../../Reducer/componentSlice'

const CustomerList = () => {
  const dispatch = useDispatch()
  const customer = useSelector((state) => state.customer)
  const navigate = useNavigate()
  const [next, setNext] = useState(false)
  const [loading, setLoading] = useState(true)
  const [createdBy, setCreatedBy] = useState(1)
  const [status, setStatus] = useState(1)
  const [filter, setFilter] = useState({
    page: 0,
    count: 10,
    // filter: "",
    // status: true,
    // verified: false,
    // createdBy: "",
    sortBy: "customerId",
    sort: false
  })
  const sort = (sortBy, sort) => {
    setFilter((prev) => ({
      ...prev,
      sortBy: sortBy,
      sort: sort
    }))
  }
  const setPageNo = (value) => {
    setFilter((prev) => ({ ...prev, page: value }))
  }
  const search = (e) => {
    setFilter((prev) => ({
      ...prev,
      filter: e.target.value
    }))
  }
  useEffect(() => {
    setLoading(false)
    dispatch(contentLoadingChange(false))
    dispatch(customerListChange([]))
    const filterData = {
      ...(filter?.filter && { filter: filter?.filter }),
      ...(filter?.status && { status: filter?.status }),
      ...(filter?.createdBy && { createdBy: filter?.createdBy }),
      sort: filter?.sort,
      sortBy: filter?.sortBy,
      ...(status !== 1 ? { status: status === 2 ? true : false } : {}),
      ...(createdBy !== 1 ? { createdBy: createdBy === 2 ? "admin" : createdBy === 3 ? "subadmin" : "self" } : {}),
    }
    postApiCall(`/admin/customer/list/${filter?.page}?count=${filter?.count}`, encrypt(filterData), (response) => {
      if (response?.success) {
        const decryptedData = decrypt(response?.data)
        if (decryptedData?.success) {
          dispatch(customerListChange(decryptedData?.data?.list))
          setNext(decryptedData?.data?.next)
        } else {
          dispatch(customerListChange([]))
          setNext(false)
        }
      } else {
        dispatch(customerListChange([]))
        setNext(false)
      }
      setLoading(true)
      dispatch(contentLoadingChange(false))
    })
  }, [filter, status, createdBy])
  return (
    <div className="branch">
      <Container fluid>
        <Row className="justify-content-between" >
          <Col className='text-center'><h3>Customer</h3></Col>
        </Row>
      </Container>
      <Card className="branch-card table-card">
        <CardBody className="table-content">
          <Row xl={4} >
            <Col><Input className='search-box' placeholder='Search...' value={filter?.filter} onChange={search} /></Col>
            <Col className='text-center'>
              <ButtonGroup className='table-button' size="sm">
                <Button outline className={status === 1 ? "t-btn-s" : ""} onClick={() => setStatus(1)} >All</Button>
                <Button outline className={status === 2 ? "t-btn-s" : ""} onClick={() => setStatus(2)}>Active</Button>
                <Button outline className={status === 3 ? "t-btn-s" : ""} onClick={() => setStatus(3)}>Deactive</Button>
              </ButtonGroup>
            </Col>
            <Col className='text-center'>
              <ButtonGroup className='table-button' size="sm">
                <Button outline className={createdBy === 1 ? "t-btn-s" : ""} onClick={() => setCreatedBy(1)} >All</Button>
                <Button outline className={createdBy === 2 ? "t-btn-s" : ""} onClick={() => setCreatedBy(2)}>Admin</Button>
                <Button outline className={createdBy === 3 ? "t-btn-s" : ""} onClick={() => setCreatedBy(3)}>Staff</Button>
                <Button outline className={createdBy === 4 ? "t-btn-s" : ""} onClick={() => setCreatedBy(4)}>Self</Button>
              </ButtonGroup>
            </Col>
            <Col><div className="d-flex justify-content-end"><Button className='button-theme' onClick={() => navigate("/customer/registration")} >Registration</Button></div></Col>
          </Row>
          <Table className="table-bg" borderless >
            <thead>
              <tr className="border-bottom" >
                <th><div className='sort-head'><div className='sort-arrow' ><i className={filter?.sortBy === "customerId" && filter?.sort === false ? "i-active" : ""} onClick={() => sort("customerId", false)} >▲</i><i className={filter?.sortBy === "customerId" && filter?.sort === true ? "i-active" : ""} onClick={() => sort("customerId", true)} >▼</i></div> Customer Id</div></th>
                <th><div className='sort-head'><div className='sort-arrow' ><i className={filter?.sortBy === "name" && filter?.sort === false ? "i-active" : ""} onClick={() => sort("name", false)} >▲</i><i className={filter?.sortBy === "name" && filter?.sort === true ? "i-active" : ""} onClick={() => sort("name", true)} >▼</i></div> Name </div></th>
                <th><div className='sort-head'><div className='sort-arrow' ><i className={filter?.sortBy === "phone" && filter?.sort === false ? "i-active" : ""} onClick={() => sort("phone", false)} >▲</i><i className={filter?.sortBy === "phone" && filter?.sort === true ? "i-active" : ""} onClick={() => sort("phone", true)} >▼</i></div> Phone </div></th>
                <th><div className='sort-head'><div className='sort-arrow' ><i className={filter?.sortBy === "email" && filter?.sort === false ? "i-active" : ""} onClick={() => sort("email", false)} >▲</i><i className={filter?.sortBy === "email" && filter?.sort === true ? "i-active" : ""} onClick={() => sort("email", true)} >▼</i></div> Email </div></th>
                <th>Gender</th>
                <th>Created At</th>
                <th>Created By</th>
              </tr>
            </thead>
            <tbody>
              {
                loading ?
                  (customer?.customerList && customer?.customerList?.length !== 0 ? customer?.customerList?.map((value, key) => {
                    return (
                      <tr key={key} className={`cursor-pointer-hover table-content-row  ${!value?.status && "text-disable"}`} onClick={() => navigate(`/customer/${value?.customerId}`)} >
                        <td>{(value?.customerId || "-")}</td>
                        <td>{textCapital(value?.name) || "-"}</td>
                        <td>{value?.phone || "-"}</td>
                        <td>{value?.email?.emailId || "-"}</td>
                        <td>{textCapital(value?.gender) || "-"}</td>
                        <td>{formatDate(value?.createdAt) || "-"}</td>
                        <td>{textCapital(value?.createdBy) || "-"}</td>
                      </tr>
                    )
                  }) :
                    <tr className='text-center' ><td colSpan={7}>No Data Found</td></tr>)
                  :
                  <TablePlaceHolder count={7} />
              }
            </tbody>
          </Table>
          <Pagination pageNo={filter?.page} changePage={setPageNo} next={next} />
        </CardBody>
      </Card>
    </div>
  )
}

export default CustomerList