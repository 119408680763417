import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    bikeBrandList : [],
    bikeBrandView : {},
    bikeBrandData : {},
    togglers : {
      emailOpen : false,
      emailOtp : false,
      reasonOpen : false
    }
};

const bikeBrandSlice = createSlice({
  name: "bikeBrand",
  initialState,
  reducers: {
    bikeBrandDataEmpty : (state,{payload}) =>{
      state.bikeBrandData = {}
    },
    bikeBrandListEmpty : (state,{payload}) =>{
      state.bikeBrandList = []
    },
    bikeBrandViewEmpty : (state,{payload}) =>{
      state.bikeBrandView = {}
    },
    bikeBrandListChange : (state,{payload}) => {
      state.bikeBrandList = payload
    },
    bikeBrandViewChange : (state,{payload}) => {
      state.bikeBrandView = { ...state?.bikeBrandView , ...payload}
    },
    bikeBrandDataChange : (state,{payload}) => {
      state.bikeBrandData = { ...state?.bikeBrandData , ...payload}
    },
    togglersChange : (state,{payload}) => {
      state.togglers = { ...state?.togglers,...payload}
    }  
  }
});

export const { bikeBrandListChange , bikeBrandViewChange , bikeBrandDataChange , togglersChange , bikeBrandDataEmpty , bikeBrandListEmpty, bikeBrandViewEmpty } = bikeBrandSlice.actions
export default bikeBrandSlice.reducer;
