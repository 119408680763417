import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    associateList : [],
    associateView : {},
    associateData : {},
    togglers : {
      emailOpen : false,
      emailOtp : false,
      reasonOpen : false
    }
};
const associateSlice = createSlice({
  name: "associate",
  initialState,
  reducers: {
    associateDataEmpty : (state,{payload}) =>{
      state.associateData = {}
    },
    associateListEmpty : (state,{payload}) =>{
      state.associateList = []
    },
    associateViewEmpty : (state,{payload}) =>{
      state.associateView = {}
    },
    associateListChange : (state,{payload}) => {
      state.associateList = payload
    },
    associateViewChange : (state,{payload}) => {
      state.associateView = { ...state?.associateView , ...payload}
    },
    associateDataChange : (state,{payload}) => {
      state.associateData = { ...state?.associateData , ...payload}
    },
    togglersChange : (state,{payload}) => {
      state.togglers = { ...state?.togglers,...payload}
    }  
  }
});

export const { associateListChange , associateViewChange , associateDataChange , togglersChange , associateDataEmpty , associateListEmpty, associateViewEmpty } = associateSlice.actions
export default associateSlice.reducer;
