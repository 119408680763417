import { getApiCall, patchApiCall, reloadWindow, textCapitalize } from 'gfdu'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { decrypt, encrypt } from '../../../Helpers/Crypto';
import { useDispatch, useSelector } from 'react-redux';
import { associateDataChange, associateDataEmpty, associateViewChange, associateViewEmpty, togglersChange } from '../../../Reducer/associateSlice';
import { formatDate, textCapital } from '../../../Helpers/Utils';
import { contentLoadingChange } from '../../../Reducer/componentSlice';
import PlaceHolder from '../../../Helpers/Components/PlaceHolder';
import ProfileAvatar from '../../../Assets/images/avatar.png';

const AssociateView = (props) => {
    const { id } = useParams("id");
    const inputFile = useRef(null)
    const dispatch = useDispatch()
    const [profilePhoto, setProfilePhoto] = useState("")
    const associate = useSelector((state) => state.associate)
    const associateData = associate?.associateData
    const togglers = associate?.togglers
    const [viewReason, setViewReason] = useState(false)
    const [loading, setLoading] = useState(true)
    const [viewPhoto, setViewPhoto] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const [buttonDisable, setButtonDisable] = useState(false)
    const [otpButton, setOtpButton] = useState(false)
    const [photoDisable, setPhotoDisable] = useState(false)
    const [disable, setDisable] = useState(false)
    const openEditModal = () => {
        dispatch(contentLoadingChange(true))
        setEditModal(true)
        getApiCall(`/admin/associate/details/${id}`, (response) => {
            if (response?.success) {
                const decryptedData = decrypt(response?.data)
                if (decryptedData?.success) {
                    dispatch(associateDataChange(decryptedData?.data))
                } else {
                    dispatch(associateDataChange({}))
                }
            } else {
                dispatch(associateDataChange({}))
            }
            dispatch(contentLoadingChange(false))
        })
    }
    const updateData = () => {
        dispatch(contentLoadingChange(true))
        const formData = new FormData();
        if (!/^[a-zA-Z\s]{2,}$/.test(associateData?.name)) {
            toast.error("Invalid Name");
            dispatch(contentLoadingChange(false))
            return false;
        }
        if (!["male", "female"].includes(associateData?.gender)) {
            toast.error("Invalid Gender");
            dispatch(contentLoadingChange(false))
            return false;
        }
        for (const key in associateData) {
            formData.append(key, associateData[key]);
        }
        patchApiCall(`/admin/associate/profile/${id}`, formData, (response) => {
            if (response?.success) {
                toast.success("Update Successfully");
                dispatch(associateDataChange({}));
                setEditModal(false);
                setTimeout(() => {
                    reloadWindow()
                }, 2000);
            } else {
                toast.error(response.msg || response.message || "Update Failed");
            }
            dispatch(contentLoadingChange(false))
        })
    }
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file !== undefined) {
            setProfilePhoto(file)
            inputFile.current = URL.createObjectURL(file)
        }
    };
    const radioChange = (e) => {
        dispatch(associateDataChange({ gender: e.target.value }));
    }
    const changeToggle = (status) => {
        dispatch(togglersChange(status))
    }
    const handleChange = (e) => {
        dispatch(associateDataChange({ [e?.target?.name]: e?.target?.value }))
    }
    const updateProfileData = async () => {
        dispatch(contentLoadingChange(true))
        setPhotoDisable(true)
        if (!associate?.associateView) {
            toast.error("Invalid Associate Data");
            setPhotoDisable(false)
            dispatch(contentLoadingChange(false))
            return;
        }
        if (associate.associateView.photo === profilePhoto || profilePhoto === "") {
            toast.error("Invalid Input");
            setPhotoDisable(false)
            dispatch(contentLoadingChange(false))
            return;
        }
        const formData = new FormData();
        formData.append("name", associate.associateView.name);
        formData.append("gender", associate.associateView.gender);
        formData.append("photo", profilePhoto);
        patchApiCall(`/admin/associate/profile/${id}`, formData, (response) => {
            if (response?.success) {
                toast.success("Update Successfully");
                setViewPhoto(false);
                setTimeout(() => {
                    reloadWindow()
                    setPhotoDisable(false)
                }, 2000);
            } else {
                toast.error(response.msg || response.message || "Update Failed");
                setPhotoDisable(false)
            }
            dispatch(contentLoadingChange(false))
        })
    };
    const emailSendOtp = () => {
        dispatch(contentLoadingChange(true))
        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(associateData?.email)) {
            dispatch(contentLoadingChange(false))
            toast.error("Invalid Email Id");
        } else {
            patchApiCall(`/admin/associate/email/otp/${id}`, encrypt({ email: associateData?.email }), (response) => {
                if (response?.success) {
                    toast.success("Otp Sended")
                    changeToggle({ emailOtp: true })
                } else {

                    toast.error(response?.msg || response?.message || "Invalid Email")
                }
                dispatch(contentLoadingChange(false))
            })
        }
    }
    const emailVerifyOtp = () => {
        dispatch(contentLoadingChange(true))
        setButtonDisable(true)
        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(associateData?.email)) {
            toast.error("Invalid Email Id");
            dispatch(contentLoadingChange(false))
            setButtonDisable(false)
        } else if (!/^\d{5}$/.test(associateData?.mailOtp)) {
            toast.error("Invalid Email Otp");
            dispatch(contentLoadingChange(false))
            setButtonDisable(false)
        } else {
            patchApiCall(`/admin/associate/email/verify/${id}`, encrypt({ email: associateData?.email, otp: associateData?.mailOtp }), (response) => {
                if (response?.success) {
                    toast.success("Otp Verified SuccessFully")
                    setTimeout(() => {
                        reloadWindow()
                        setButtonDisable(false)
                    }, 2000);
                } else {
                    toast.error(response?.msg || response?.message || "Invalid Email")
                    setButtonDisable(false)
                }
                dispatch(contentLoadingChange(false))
            })
        }
    }
    const changeStatus = (status) => {
        dispatch(contentLoadingChange(true))
        setButtonDisable(true)
        if (status === false && !associateData?.reason) {
            setButtonDisable(false)
            dispatch(contentLoadingChange(false))
            return toast.error("Reason is required")
        }
        patchApiCall(`/admin/associate/status/${id}?status=${status}`, encrypt({ ...(status === false ? { reason: associateData?.reason } : {}) }), (response) => {
            if (response?.success) {
                toast.success(`${status ? "Active Successfully" : "Deactive Successfully"}`)
                setTimeout(() => {
                    setButtonDisable(false)
                    reloadWindow()
                }, 2000);
            } else {
                toast.error(response.message || response.msg || "Status Couldn't Update")
                setButtonDisable(false)
            }
            dispatch(contentLoadingChange(false))
        })
    }
    const aadharOtpSend = () => {
        dispatch(contentLoadingChange(true))
        if (associateData?.aadharNo?.length !== 12 && Number(associateData?.aadharNo) == associateData?.aadharNo) {
            toast.error("Invalid Aadhar No")
            dispatch(contentLoadingChange(false))
        } else {
            setDisable(true)
            patchApiCall(`/admin/associate/kyc/aadhar/otp/${id}`, encrypt({ aadharNo: Number(associateData?.aadharNo) }), (response) => {
                if (response?.success) {
                    const decryptedData = decrypt(response?.data)
                    if (decryptedData?.success) {
                        const validKeys = {
                            reference_id: decryptedData?.data?.reference_id,
                            decentroTxnId: decryptedData?.data?.decentroTxnId
                        }
                        dispatch(associateDataChange(validKeys))
                        toast.success("Otp Sended")
                        setOtpButton(true)
                    } else {
                        toast.error("Invalid Aadhar Card")
                    }
                } else {
                    toast.error(response?.msg || response?.message || "Invalid Aadhar No !")
                }
                dispatch(contentLoadingChange(false))
                setDisable(false)
            })
        }
    }
    const aadharVerify = () => {
        dispatch(contentLoadingChange(true))
        if (associateData?.aadharNo?.length !== 12) {
            dispatch(contentLoadingChange(false))
            toast.error("Invalid Aadhar No")
        } else if (associateData?.otp?.length !== 6) {
            dispatch(contentLoadingChange(false))
            toast.error("Invalid Aadhar Otp")
        } else {
            setDisable(true)
            const validKeys = {
                aadharNo: associateData?.aadharNo,
                reference_id: associateData.reference_id,
                decentroTxnId: associateData?.decentroTxnId,
                otp: associateData?.otp
            }
            patchApiCall(`/admin/associate/kyc/aadhar/verify/${id}`, encrypt(validKeys), (response) => {
                if (response?.success) {
                    toast?.success("Aadhar Vertified Successfully")
                    reloadWindow()
                } else {
                    toast.error(response?.msg || response?.message || "Invalid Aadhar No")
                }
                setDisable(false)
                dispatch(contentLoadingChange(false))
            })
        }
    }
    const panVerify = () => {
        dispatch(contentLoadingChange(true))
        if (associateData?.panNo?.length !== 10) {
            dispatch(contentLoadingChange(false))
            toast.error("Invalid Pan No")
        } else {
            patchApiCall(`/admin/associate/kyc/pan/verify/${id}`, encrypt({ panNo: associateData?.panNo?.toUpperCase() }), (response) => {
                if (response?.success) {
                    toast?.success("Pan Vertified Successfully")
                    reloadWindow()
                } else {
                    toast.error(response?.msg || response?.message || "Invalid Pan No2")
                }
                dispatch(contentLoadingChange(false))
            })
        }
    }
    useEffect(() => {
        setLoading(false)
        dispatch(associateViewEmpty())
        dispatch(associateDataEmpty())
        setProfilePhoto("")
        inputFile.current = ""
        dispatch(contentLoadingChange(true))
        getApiCall(`/admin/associate/details/${id}`, (response) => {
            if (response?.success) {
                const decryptedData = decrypt(response?.data)
                if (decryptedData?.success) {
                    dispatch(associateViewChange(decryptedData?.data))
                    setProfilePhoto(decryptedData?.data?.photo)
                    inputFile.current = decryptedData?.data?.photo
                } else {
                    dispatch(associateViewChange({}))
                }
            } else {
                dispatch(associateViewChange({}))
                toast.error("Unable to Retrive Data")
            }
            setLoading(true)
            dispatch(contentLoadingChange(false))
        })
    }, [])
    useEffect(() => {
        dispatch(associateDataChange({ email: associate?.associateView?.email?.emailId }))
    }, [associate?.associateView])
    useEffect(() => {
        inputFile.current = associate?.associateView?.photo
        setProfilePhoto(associate?.associateView?.photo)
    }, [viewPhoto])
    useEffect(() => {
        dispatch(associateDataChange({ otp: "" }))
    }, [buttonDisable])
    return (
        <React.Fragment>
            <Modal isOpen={viewReason} toggle={() => setViewReason(!viewReason)}>
                <ModalHeader toggle={() => setViewReason(!viewReason)} className='text-center' >Reason</ModalHeader>
                <ModalBody className='reason-modal'>{associate?.associateView?.errorMsg}</ModalBody>
            </Modal>
            <Modal isOpen={togglers?.emailOpen} toggle={() => changeToggle({ emailOpen: !togglers?.emailOpen })} >
                <ModalBody >
                    <Row><Col className='text-center mb-3 text-theme '>Email Update</Col></Row>
                    <Row className=' flex-column justify-content-center align-items-center'>
                        <Col>
                            <FormGroup row className='justify-content-center'>
                                <Label for="email" sm={3}>Email ID</Label>
                                <Col sm={7}>
                                    <Row xs={4} className='align-items-center'>
                                        <Col xs={10}>{!togglers?.emailOtp ? <Input id='email' name='email' type='email' autoComplete='off' className='input-theme text-lower' value={associate?.associateData?.email} onChange={handleChange} /> : `${associate?.associateData?.email}`}</Col>
                                        <Col xs={2} ><i onClick={emailSendOtp} className=' fs-5 bx bx-send' ></i></Col>
                                    </Row>
                                </Col>
                            </FormGroup>
                        </Col>
                        {
                            togglers?.emailOtp &&
                            <>
                                <Col>
                                    <FormGroup row className='justify-content-center'>
                                        <Label for="mailOtp" sm={3}>Otp</Label>
                                        <Col sm={7}>
                                            <Input id='mailOtp' name='mailOtp' autoComplete='off' className='input-theme input-size-1' value={associate?.associateData?.mailOtp || ""} onChange={(e) => (e.target.value?.length <= 5 && Number(e.target?.value) == e.target?.value) ? handleChange(e) : ""} />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col><div className='text-center'><Button className='button-theme' disabled={buttonDisable} onClick={emailVerifyOtp} >Verify</Button></div></Col>
                            </>
                        }
                    </Row>
                </ModalBody>
            </Modal>
            <Modal isOpen={togglers?.reasonOpen} toggle={() => changeToggle({ reasonOpen: !togglers?.reasonOpen })} >
                <ModalBody >
                    <Row className=' flex-column justify-content-center align-items-center'>
                        <Col>
                            <FormGroup row className='justify-content-center'>
                                <Label for="reason" sm={3}>Reason</Label>
                                <Col sm={7}>
                                    <Input id='reason' name='reason' className='input-theme' value={associate?.associateData?.reason} onChange={handleChange} />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col><div className='text-center' ><Button color='danger' disabled={buttonDisable} onClick={() => changeStatus(false)} >Deactivate</Button></div></Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Modal isOpen={editModal} toggle={() => setEditModal(!editModal)} >
                <ModalBody >
                    <Row className='my-2 flex-column'>
                        <Col>
                            <FormGroup row className='justify-content-center'>
                                <Label for="name" sm={3}>Name</Label>
                                <Col sm={7}>
                                    <Input id="name" name="name" autoComplete='off' onChange={handleChange} value={associateData.name || ''} className='input-theme text-upper' type="text" />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup row className='justify-content-center'>
                                <Label for="gender" sm={3}>Gender</Label>
                                <Col sm={7} className='d-flex justify-content-evenly align-items-center'>
                                    <div>
                                        <Input name="gender" id='male' onChange={radioChange} value="male" checked={associateData.gender === 'male'} type="radio" />
                                        <Label for="male" className='mx-2'>Male</Label>
                                    </div>
                                    <div>
                                        <Input name="gender" id='female' onChange={radioChange} value="female" checked={associateData.gender === 'female'} type="radio" />
                                        <Label for="female" className='mx-2'>Female</Label>
                                    </div>
                                </Col>
                            </FormGroup>
                        </Col>

                        <Col className='text-center'><Button className='button-theme' onClick={updateData}>Update</Button></Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Modal isOpen={viewPhoto} toggle={() => setViewPhoto(!viewPhoto)}>
                <ModalBody >
                    <Row className='flex-column'>
                        <Col className='text-center'>Profile Photo</Col>
                        <Col className='view-photo'>{inputFile.current ? <img src={inputFile.current} alt='profile' /> : "Upload Image"}</Col>
                        <Col className='text-center' ><div className='input-profile'><input type='file' id='profile' name='profile' onChange={handleFileChange} /></div> <Label htmlFor='profile' className='profile-label' >Change Profile</Label></Col>
                        {
                            associate?.associateView?.photo === profilePhoto
                                ? "" :
                                <Col className='text-center my-2'><div className='d-flex justify-content-evenly'><Button className='button-theme' disabled={photoDisable} onClick={updateProfileData}>Update</Button><Button color='danger' onClick={() => setViewPhoto(false)}>Close</Button></div></Col>
                        }
                    </Row>
                </ModalBody>
            </Modal>
            <Row className='m-2'>
                <Col lg={5} className='mb-2' >
                    <Card className='profile-big-card'>
                        <CardBody>
                            <div className='customer-profile'>
                                <img src={associate?.associateView?.photo || ProfileAvatar} alt='Customer' onClick={() => setViewPhoto(true)} />
                            </div>
                            <Row className='justify-content-center'>
                                <Col sm={10} >
                                    <div className='profile-data my-3'><span>Host ID</span><span>{loading ? (associate?.associateView?.associateId ? associate?.associateView?.associateId : "-") : <PlaceHolder />}</span></div>
                                    <div className='profile-data mb-3'><span>Created By</span><span>{loading ? (associate?.associateView?.createdBy ? textCapital(associate?.associateView?.createdBy) : "-") : <PlaceHolder />}</span></div>
                                    <div className='profile-data mb-3'><span>Created At</span><span>{loading ? (associate?.associateView?.createdAt ? formatDate(associate?.associateView?.createdAt) : "-") : <PlaceHolder />}</span></div>
                                    <div className='profile-data mb-3'><span>Name</span><span>{loading ? (associate?.associateView?.name ? <> {textCapital(associate?.associateView?.name)} <i className='ms-2 profile-edit bx bx-edit' onClick={openEditModal} ></i></> : "-") : <PlaceHolder />}</span></div>
                                    <div className='profile-data mb-3'><span>Gender</span><span>{loading ? (associate?.associateView?.gender ? textCapitalize(associate?.associateView?.gender) : "-") : <PlaceHolder />}</span></div>
                                    <div className='profile-data mb-3'><span>Phone </span><span>{loading ? (associate?.associateView?.associateId ? associate?.associateView?.associateId : "-") : <PlaceHolder />}</span></div>
                                    <div className='profile-data mb-3'><span>Host ID</span><span>{loading ? (associate?.associateView?.phone ? associate?.associateView?.phone : "-") : <PlaceHolder />}</span></div>
                                    {associate?.associateView?.photo ? <div className='profile-data cursor-pointer mb-2'><span>Email</span><span className={"align-middle  " + `${associate?.associateView?.email?.status ? "text-green" : 'text-red cursor-pointer-hover'}`} onClick={() => changeToggle({ emailOpen: !togglers?.emailOpen })} >{loading ? (associate?.associateView?.email?.emailId ? associate?.associateView?.email?.emailId : "-") : <PlaceHolder />}</span></div> : ""}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col className='me-3'>
                    <Card className='profile-big-card'>
                        <CardBody>
                            <Row className='justify-content-center'>
                                <Col sm={9} >
                                    <p className='profile-data'>
                                        <span>Status</span>
                                        <span>
                                            {
                                                associate?.associateView?.status !== undefined ? (!associate?.associateView?.status ?
                                                    <Button color="success" disabled={buttonDisable} onClick={() => changeStatus(true)}>Active</Button> :
                                                    <Button color="danger" onClick={() => changeToggle({ reasonOpen: true })}>Deactive</Button>)
                                                    : <Button color="success" disabled className="card-text placeholder-glow"><span className="placeholder bg-secondary col-12"></span></Button>
                                            }
                                        </span>
                                    </p>
                                    {
                                        associate?.associateView?.status === false &&
                                        <p className='profile-data' ><span>Reason</span><span><Button className='button-theme' onClick={() => setViewReason(!viewReason)}>View</Button></span></p>
                                    }
                                    {
                                        associate?.associateView?.photo ? ((associate?.associateView?.kyc?.aadhar?.aadharNo === "" || associate?.associateView?.kyc?.aadhar?.aadharNo === undefined)
                                            ?
                                            <>
                                                {associate?.associateView?.email?.status ?
                                                    ((associate?.associateView?.status === false || associate?.associateView?.email?.status === false) ?
                                                        ""
                                                        :
                                                        <>
                                                            <FormGroup row className='justify-content-center'>
                                                                <Label for="aadharNo" sm={4}>Aadhar No</Label>
                                                                <Col sm={8}>{!otpButton ? <Input id="aadharNo" name="aadharNo" autoComplete='off' value={associateData?.aadharNo || ""} onChange={(e) => { e.target.value.length <= 12 && (Number(e.target.value) == e.target.value) && handleChange(e) }} className='input-theme' type="text" /> : <><span className='me-3'>{associateData?.aadharNo}</span><i onClick={() => setOtpButton(false)} className='bx bxs-pencil' ></i></>}</Col>
                                                            </FormGroup>
                                                            {
                                                                otpButton &&
                                                                <FormGroup row className='justify-content-center'>
                                                                    <Label for="otp" sm={4}>Otp</Label>
                                                                    <Col sm={8}><Input id="otp" name="otp" autoComplete='off' value={associateData?.otp || ""} onChange={(e) => { e.target.value.length <= 6 && (Number(e.target.value) == e.target.value) && handleChange(e) }} className='input-theme input-size-1' type="text" /></Col>
                                                                </FormGroup>
                                                            }
                                                            <Row sm={2} className='justify-content-end'>
                                                                <Col sm={8} className='text-center'>
                                                                    {
                                                                        !otpButton ?
                                                                            <Button className='button-theme' disabled={disable} onClick={aadharOtpSend}> Send Otp </Button>
                                                                            :
                                                                            <Button className='button-theme' disabled={disable} onClick={aadharVerify}>Verify Aadhar</Button>
                                                                    }
                                                                </Col>
                                                            </Row>

                                                        </>) :
                                                    ""
                                                }
                                            </> :
                                            <>
                                                <div className='profile-data my-3'><span>Aadhar No</span><span className='d-flex align-items-center'>{loading ? (associate?.associateView?.kyc?.aadhar?.aadharNo && associate?.associateView?.kyc?.aadhar?.aadharNo) : <PlaceHolder />} <i className='ms-1 text-green bx bx-check-circle' ></i></span></div>
                                                <div className='profile-data mb-3'><span>Date Of Birth</span><span>{loading ? (associate?.associateView?.kyc?.aadhar?.aadharDetails?.proofOfIdentity?.dob || "-") : <PlaceHolder />}</span></div>
                                                <div className='profile-data mb-3'><span>Care Of</span><span>{loading ? (associate?.associateView?.kyc?.aadhar?.aadharDetails?.proofOfAddress?.careOf || "-") : <PlaceHolder />}</span></div>
                                                <div className='profile-data mb-3'><span>House No</span><span>{loading ? (associate?.associateView?.kyc?.aadhar?.aadharDetails?.proofOfAddress?.house || "-") : <PlaceHolder />}</span></div>
                                                <div className='profile-data mb-3'><span>Street</span><span>{loading ? (associate?.associateView?.kyc?.aadhar?.aadharDetails?.proofOfAddress?.street || "-") : <PlaceHolder />}</span></div>
                                                <div className='profile-data mb-3'><span>District</span><span>{loading ? (associate?.associateView?.kyc?.aadhar?.aadharDetails?.proofOfAddress?.district || "-") : <PlaceHolder />}</span></div>
                                                <div className='profile-data mb-3'><span>State</span><span>{loading ? (associate?.associateView?.kyc?.aadhar?.aadharDetails?.proofOfAddress?.state || "-") : <PlaceHolder />}</span></div>
                                                <div className='profile-data mb-3'><span>Country</span><span>{loading ? (associate?.associateView?.kyc?.aadhar?.aadharDetails?.proofOfAddress?.country || "-") : <PlaceHolder />}</span></div>
                                            </>) : ""
                                    }
                                    {
                                        associate?.associateView?.kyc?.aadhar?.aadharNo !== undefined ?
                                            associate?.associateView?.kyc?.pan === undefined ?
                                                associate?.associateView?.status ?
                                                    <FormGroup row className='justify-content-center'>
                                                        <Label for="panNo" sm={4}>Pan No</Label>
                                                        <Col sm={8}><span className='d-flex align-items-center'><Input id="panNo" name="panNo" autoComplete='off' value={associateData?.panNo || ""} onChange={(e) => { e.target.value.length <= 10 && handleChange(e) }} className='input-theme text-uppercase' type="text" /><i onClick={panVerify} className='ms-1 fs-3 cursor-pointer-hover bx bxs-send'></i></span></Col>
                                                    </FormGroup> : ""
                                                :
                                                <div className='profile-data mb-3'><span>Pan No</span><span className='d-flex align-items-center'>{loading ? (associate?.associateView?.kyc?.pan && associate?.associateView?.kyc?.pan || "-") : <PlaceHolder />}<i className='ms-1 text-green bx bx-check-circle' ></i></span></div> : ""
                                    }
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </React.Fragment>
    )
}

export default AssociateView;
