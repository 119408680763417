import CryptoJS from 'crypto-js';
import Config from './Config';

const isJson = (str) => {
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        return false;
    }
};

export const encrypt = (data) => {
    const parsedkey = CryptoJS.enc.Utf8.parse(Config.KEYS.PARSED_KEY);
    const iv = CryptoJS.enc.Utf8.parse(Config.KEYS.IV_KEY);
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), parsedkey, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });
    return { encryptedData : encrypted.toString() };
};

export const decrypt = (data) => {
    try {
        var keys = CryptoJS.enc.Utf8.parse(Config.KEYS.PARSED_KEY);
        const iv = CryptoJS.enc.Utf8.parse(Config.KEYS.IV_KEY);
        let base64 = CryptoJS.enc.Base64.parse(data);
        let src = CryptoJS.enc.Base64.stringify(base64);
        var decrypt = CryptoJS.AES.decrypt(src, keys, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        if (isJson(decrypt.toString(CryptoJS.enc.Utf8))) {
            return {
                success: true,
                data: JSON.parse(decrypt.toString(CryptoJS.enc.Utf8)),
            };
        } else {
            return { success: false };
        }
    } catch (error) {
        return { success: false };
    }
};


