import React, { useEffect, useState } from 'react'

const Timer = (props) => {
    const [timer, setTimer] = useState(null)
    const [seconds, setSeconds] = useState(undefined)

    const timerStart = (count) => {
        if (timer) {
            clearInterval(timer);
        }
        setSeconds(count);
        setTimer(setInterval(() => {
            if (count !== 0) {
                count--;
                setSeconds(count);
            }
        }, 1000))
    }
    useEffect(() => {
        if (seconds <= 0 ) {
            if (props?.endFunction !== undefined) {
                props?.endFunction()
            }
            clearInterval(timer);
        }
    }, [seconds])
    useEffect(() => {
        if (props?.time) {
            timerStart(props.time)
        }
    }, [])
    return (
        <React.Fragment>{seconds}</React.Fragment>
    )
}

export default Timer