import React, { useEffect, useState } from 'react'
import { Col, Container, Row, FormGroup, Input, Label, Button, InputGroup, Card, CardBody } from 'reactstrap'
import Shape from "../Assets/images/Shape.svg"
import { useDispatch, useSelector } from 'react-redux'
import { dataChange } from "../Reducer/loginSlice"
import { toast } from 'react-toastify'
import { postApiCall, textCapitalize } from 'gfdu'
import { decrypt, encrypt } from "../Helpers/Crypto"
import { useAuth } from "../Contexts/AuthenticateProvider"
import Particles from 'react-tsparticles';
import { loadSnowPreset } from 'tsparticles-preset-snow';

const Login = () => {
  const [timer, setTimer] = useState(null)
  const [eye, setEye] = useState(false)
  const [next, setNext] = useState(true)
  const [buttonDisable, setButtonDisable] = useState(false)
  const [seconds, setSeconds] = useState(120)
  const [resend, setResend] = useState(false)
  const { logIn } = useAuth();
  const userData = useSelector((state) => state.login)
  const dispatch = useDispatch()
  const handleChange = (e) => {
    dispatch(dataChange([e.target.name, e.target.value]))
  }
  const onOtpSend = () => {
    setButtonDisable(true)
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(userData?.email)) {
      setButtonDisable(false)
      toast.error("Please Enter Valid Email")
    } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(userData?.password)) {
      setButtonDisable(false)
      toast.error("Please Enter Valid Password")
    } else {
      postApiCall("/admin-auth/signin/send-otp", encrypt({ email: userData?.email?.toLowerCase(), password: userData?.password }), (response) => {
        if (response?.success) {
          setNext(false)
          setResend(true)
          timerReSend(120)
          toast.success("Otp Sended Successfully")
        } else {
          setResend(false)
          toast.error(textCapitalize(response?.msg) || textCapitalize(response?.message) || "Invalid Email or Password")
        }
        setButtonDisable(false)
      })
    }
  }
  const onSubmit = () => {
    setButtonDisable(true)
    if (!/^[0-9]{5}$/.test(userData?.otp)) {
      setButtonDisable(false)
      toast.error("Enter a Valid Otp")
    } else {
      postApiCall("/admin-auth/signin/verify-otp", encrypt({ email: userData?.email, password: userData?.password, otp: userData?.otp }), (response) => {
        if (response?.success) {
          const data = decrypt(response?.data)
          if (data?.success) {
            logIn(data?.data?.token, data?.data?.userId)
            clearInterval(timer);
            toast.success("Login Successfully")
          } else {
            toast.error("Try Again Later")
          }
        } else {
          toast.error("Invalid Otp")
        }
        setButtonDisable(false)
      })
    }
  }
  useEffect(() => {
    if (next) {
      setSeconds(0)
      dispatch(dataChange(["otp", ""]))
    }
  }, [next])
  const timerReSend = (count) => {
    if (timer) {
      clearInterval(timer);
    }
    setSeconds(count);
    setResend(true);
    let timerCount = setInterval(() => {
      if (count !== 0) {
        count--;
        setSeconds(count);
      }
      if (count === 0) {
        setSeconds(0);
        clearInterval(timer);
        setResend(false);
      }
    }, 1000);
    setTimer(timerCount)
  };

  return (
    <React.Fragment>
      <div className='login'>
        <div className='login-head-main'>
          <div className='login-head-image'></div>
          <div className='login-head-color'><Particles id="tsparticles" init={async (main) => { await loadSnowPreset(main) }} loaded={() => { }} options={{ preset: "snow", fullScreen: { enable: false, zIndex: 0 }, background: { color: { value: "transparent" } } }} /></div>
          <div className='login-head-shape'><img src={Shape} alt='shape' /></div>
        </div>
        <div className='login-content'>
          <Container>
            <Row>
              <Col className='login-body'>
                <Card className='login-card'>
                  <CardBody>
                    {
                      next ?
                        <Row className='flex-column '>
                          <Col className='my-4 fs-3 text-primary text-center'>Welcome Admin</Col>
                          <Col>
                            <FormGroup className='text-dark' >
                              <Label htmlFor='email' >Email</Label>
                              <Input id="email" name="email" autoComplete='off' onChange={handleChange} value={userData?.email} className='input-theme text-lower' placeholder="Enter Email address" type="email" />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup className='text-dark' >
                              <Label htmlFor='password' >Password</Label>
                              <InputGroup  >
                                <Input id="password" name="password" className='input-theme' value={userData?.password} onChange={handleChange} placeholder="Enter password" type={eye ? "text" : "password"} />
                                <Button className='button-theme' onClick={() => setEye(!eye)}>{eye ? <i className="bi bi-eye"></i> : <i className="bi bi-eye-slash"></i>}</Button>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col className='text-center  my-4'><Button className='button-theme' disabled={buttonDisable} onClick={onOtpSend}>Login</Button></Col>
                        </Row>
                        :
                        <Row className='flex-column mt-5'>
                          <Col className='my-4 fs-3 text-center text-primary'>Otp is sended on your Id </Col>
                          <Col>
                            <FormGroup className='text-dark' >
                              <Label htmlFor='otp'>Otp</Label>
                              <Input id="otp" name="otp" autoComplete='off' onChange={handleChange} value={userData?.otp} className='input-theme' placeholder="Enter Otp" type="text" />
                            </FormGroup>
                          </Col>
                          <Col className='d-flex justify-content-between align-items-center' ><Button disabled={buttonDisable} onClick={() => setNext(true)} color='link' >Back</Button>  <span>{resend && <span className='text-theme'>{(seconds !== 0) && (seconds + " S")}</span>}<Button onClick={onOtpSend} disabled={ buttonDisable || resend} color='link' >Resend Otp</Button></span></Col>
                          <Col className='text-center  my-4'><Button className='button-theme' disabled={buttonDisable} onClick={onSubmit}>Login</Button></Col>
                        </Row>
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Login