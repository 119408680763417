const env = process.env

const Config = {
    KEYS : {
        PARSED_KEY : env.REACT_APP_PARSED_KEY,
        IV_KEY : env.REACT_APP_IV_KEY,
        MAP_KEY : env.REACT_APP_MAP_KEY
    }
}

export default Config;