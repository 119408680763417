import React from 'react'
import { Button, ButtonGroup, Card, CardBody, Col, Container, Row } from 'reactstrap'

const Dashboard = () => {
  return (
    <React.Fragment>
      <Container fluid>
        <Row className='my-3'>
          <Col className='text-center'>
            <ButtonGroup className='table-button'>
              <Button outline>Today</Button>
              <Button outline>This Week</Button>
              <Button outline>This Month</Button>
              <Button outline>This Year</Button>
              <Button outline>Custom</Button>
            </ButtonGroup>
          </Col>
        </Row>
        <Row xs={1} sm={2} lg={4}>
          <Col className='my-2' >
            <div className='dashboard-card'>
              <div className='card-body'>
                <div className='dashboard-content'>
                  <p >Customer</p>
                  <span className='text-theme-2' >10</span>
                </div>
                <div className='dashboard-icon'><i className="fa-solid fa-users"></i></div>
              </div>
            </div>
          </Col>
          <Col className='my-2'>
            <div className='dashboard-card'>
              <div className='card-body'>
                <div className='dashboard-content'>
                  <p >Associate</p>
                  <span className='text-theme-2' >10</span>
                </div>
                <div className='dashboard-icon'><i className="fa-solid fa-address-card"></i></div>
              </div>
            </div>
          </Col>
          <Col className='my-2'>
            <div className='dashboard-card'>
              <div className='card-body'>
                <div className='dashboard-content'>
                  <p >Car</p>
                  <span className='text-theme-2' >10</span>
                </div>
                <div className='dashboard-icon'><i className="fa-solid fa-car-side"></i></div>
              </div>
            </div>
          </Col>
          <Col className='my-2'>
            <div className='dashboard-card'>
              <div className='card-body'>
                <div className='dashboard-content'>
                  <p >Bike</p>
                  <span className='text-theme-2' >10</span>
                </div>
                <div className='dashboard-icon'><i className="fa-duotone fa-solid fa-motorcycle"></i></div>
              </div>
            </div>
          </Col>

        </Row>
      </Container>
    </React.Fragment>
  )
}

export default Dashboard