import { getApiCall, patchApiCall, postApiCall, reloadWindow, reloadWindowToPath } from 'gfdu';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { decrypt, encrypt } from '../../Helpers/Crypto';
import { toast } from 'react-toastify';
import Select from "react-select";
import DatePicker from "react-multi-date-picker";

const VariantEdit = () => {
    const { id } = useParams("id");
    const [buttonDisable, setButtonDisable] = useState(false)
    const [variantData, setVariantData] = useState({})
    const [loading, setLoading] = useState(false)
    const maxKmsOption = [
        { label: "200,000 KM", value: "200000" },
        { label: "300,000 KM", value: "300000" },
        { label: "400,000 KM", value: "400000" },
        { label: "500,000 KM", value: "500000" },
        { label: "600,000 KM", value: "600000" },
        { label: "700,000 KM", value: "700000" }
    ]
    const carFuelOption = [
        { label: "Petrol", value: "Petrol" },
        { label: "Diesel", value: "Diesel" },
        { label: "Bio-Diesel", value: "Bio-Diesel" },
        { label: "CNG", value: "CNG" },
        { label: "LPG", value: "LPG" },
        { label: "EV", value: "EV" },
        { label: "Petrol+EV", value: "Petrol+EV" },
        { label: "Petrol+CNG", value: "Petrol+CNG" }
    ]
    const bikeFuelOption = [
        { label: "Petrol", value: "Petrol" },
        { label: "EV", value: "EV" },
        { label: "Petrol+EV", value: "Petrol+EV" },
        { label: "Petrol+CNG", value: "Petrol+CNG" }
    ]
    const carTransmissionOption = [
        { label: "Gear", value: "Gear" },
        { label: "Semi-Auto", value: "Semi-Auto" },
        { label: "Automatic", value: "Automatic" }
    ]
    const bikeTransmissionOption = [
        { label: "Gear", value: "Gear" },
        { label: "Automatic", value: "Automatic" }
    ]
    const seatOption = [
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
        { label: "8", value: "8" },
        { label: "9", value: "9" }
    ]
    const setYear = (e) => {
        setVariantData((prev) => ({
            ...prev,
            year: new Date(e)?.getFullYear()
        }))
    }
    const setSelect = (e, name) => {
        setVariantData((prev) => ({
            ...prev,
            [name]: e.value
        }))
    };
    const handleChange = (e) => {
        setVariantData((prev) => ({
            ...prev,
            [e?.target?.name]: e?.target?.value
        }))
    }
    const onSubmit = () => {
        setButtonDisable(true)
        if (variantData?.variantName === "" || variantData?.variantName?.trim()?.length === 0 || variantData?.variantName === undefined || variantData?.variantName === null || typeof variantData?.variantName !== "string") {
            toast.error("Invalid Variant Name")
        } else if (variantData?.year === "" || variantData?.year === undefined || variantData?.year === null) {
            toast.error("Invalid Variant Year")
        } else if (variantData?.fuelType === "" || variantData?.fuelType === undefined || variantData?.fuelType === null) {
            toast.error("Invalid Fuel Type")
        } else if (variantData?.transmissionType === "" || variantData?.transmissionType === undefined || variantData?.transmissionType === null) {
            toast.error("Invalid Transmission Type")
        } else if (variantData?.brandType === "Cars" && (variantData?.seats === "" || variantData?.seats === undefined || variantData?.seats === null)) {
            toast.error("Invalid Seats")
        } else if (variantData?.maxKmsRange === "" || variantData?.maxKmsRange === undefined || variantData?.maxKmsRange === null) {
            toast.error("Invalid Max Kms Range")
        } else {
            const data = {
                "variantName": variantData?.variantName,
                "year": Number(new Date(variantData?.year,0,1)?.getFullYear()),
                "fuelType": variantData?.fuelType,
                "transmissionType": variantData?.transmissionType,
                "seats": variantData?.brandType === "Cars" ? variantData?.seats : 2,
                "maxKmsRange": variantData?.maxKmsRange
            }
            return patchApiCall(`/admin/vehicle/brand/model/variant/${id}`, encrypt(data), (response) => {
                if (response?.success) {
                    toast.success("Update Successfully")
                    setTimeout(() => {
                        reloadWindowToPath(`/vehicle/variant/${id}`)
                    }, 2000)
                } else {
                    toast?.error(response?.msg || response?.message)
                    setButtonDisable(false)
                }
            })
        }
        setButtonDisable(false)
    }
    useEffect(() => {
        setVariantData({})
        setLoading(true)
        getApiCall(`/admin/vehicle/brand/model/variant/${id}`, (response) => {
            if (response?.success) {
                const decryptedData = decrypt(response?.data)
                if (decryptedData?.success) {
                    setVariantData((prev) => ({
                        ...prev,
                        ...decryptedData?.data
                    }))
                } else {
                    setVariantData({})
                }
            } else {
                setVariantData({})
                toast.error("Unable to Retrive Data")
            }
            setLoading(false)
        })
    }, [])
    return (
        <React.Fragment>
            {
                loading ?
                    "" :
                    <Row className='justify-content-center'>
                        <Col sm={10}>
                            <Card>
                                <CardBody>
                                    <h2 className='text-theme text-center my-5'>Variant Update</h2>
                                    <Row sm={2} xs={1} className='my-2'>
                                        <Col>
                                            <FormGroup row className='justify-content-center'>
                                                <Label for="brand" sm={3}>Brand</Label>
                                                <Col sm={5}>
                                                    <Input id="brand" name="brand" autoComplete='off' disabled={true} value={variantData?.brand || ''} className='input-theme text-upper' type="text" />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row className='justify-content-center'>
                                                <Label for="model" sm={3}>Model</Label>
                                                <Col sm={5}>
                                                    <Input id="model" name="model" autoComplete='off' disabled={true} value={variantData?.modelName || ''} className='input-theme text-upper' type="text" />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row className='justify-content-center'>
                                                <Label for="segment" sm={3}>Segment</Label>
                                                <Col sm={5}>
                                                    <Input id="segment" name="segment" autoComplete='off' disabled={true} value={variantData?.segment || ''} className='input-theme text-upper' type="text" />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row className='justify-content-center'>
                                                <Label for="brandType" sm={3}>Vehicle Type</Label>
                                                <Col sm={5}>
                                                    <Input id="brandType" name="brandType" autoComplete='off' disabled={true} value={variantData?.brandType || ''} className='input-theme text-upper' type="text" />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row className='justify-content-center'>
                                                <Label for="variantName" sm={3}>Variant Name</Label>
                                                <Col sm={5}>
                                                    <Input id="variantName" name="variantName" autoComplete='off' onChange={handleChange} value={variantData?.variantName || ''} className='input-theme text-upper' type="text" />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row className='justify-content-center'>
                                                <Label for="year" sm={3}>Year</Label>
                                                <Col sm={5}>
                                                    <DatePicker id="year" name="year" className="calender-theme" inputClass="date-theme" minDate={new Date(new Date().getFullYear() - 20, 0, 1)} maxDate={new Date()} value={new Date(Number(variantData?.year) || new Date().getFullYear() - 20, 0, 1)} onChange={setYear} onlyYearPicker />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row className='justify-content-center'>
                                                <Label for="fuelType" sm={3}>Fuel Type</Label>
                                                <Col sm={5}>
                                                    <Select id="fuelType" name="fuelType" autoComplete='off'
                                                        styles={{
                                                            control: (styles) => ({ ...styles, borderColor: "#ff4d32", "&:hover": { boxShadow: "0 0 0 3px #ff4d3215", borderColor: "#ff4d32" } }),
                                                            option: (style, state) => ({ ...style, "&:hover": { backgroundColor: state.isSelected ? "#ff4d32" : "#ff4d3215" }, backgroundColor: state.isSelected && "#ff4d32" }),
                                                        }}
                                                        className='input-size-2'
                                                        value={{ label: variantData?.fuelType, value: variantData?.fuelType }}
                                                        options={variantData?.brandType === "Cars" ? carFuelOption : bikeFuelOption}
                                                        onChange={(e) => setSelect(e, "fuelType")}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row className='justify-content-center'>
                                                <Label for="transmissionType" sm={3}>Transmission Type</Label>
                                                <Col sm={5}>
                                                    <Select id="transmissionType" name="transmissionType" autoComplete='off'
                                                        styles={{
                                                            control: (styles) => ({ ...styles, borderColor: "#ff4d32", "&:hover": { boxShadow: "0 0 0 3px #ff4d3215", borderColor: "#ff4d32" } }),
                                                            option: (style, state) => ({ ...style, "&:hover": { backgroundColor: state.isSelected ? "#ff4d32" : "#ff4d3215" }, backgroundColor: state.isSelected && "#ff4d32" }),
                                                        }}
                                                        className='input-size-2'
                                                        value={{ label: variantData?.transmissionType, value: variantData?.transmissionType }}
                                                        options={variantData?.brandType === "Cars" ? carTransmissionOption : bikeTransmissionOption}
                                                        onChange={(e) => setSelect(e, "transmissionType")}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row className='justify-content-center'>
                                                <Label for="seats" sm={3}>Seats</Label>
                                                <Col sm={5}>
                                                    <Select id="seats" name="seats" autoComplete='off'
                                                        styles={{
                                                            control: (styles) => ({ ...styles, borderColor: "#ff4d32", "&:hover": { boxShadow: "0 0 0 3px #ff4d3215", borderColor: "#ff4d32" } }),
                                                            option: (style, state) => ({ ...style, "&:hover": { backgroundColor: state.isSelected ? "#ff4d32" : "#ff4d3215" }, backgroundColor: state.isSelected && "#ff4d32" }),
                                                        }}
                                                        className='input-size-2'
                                                        value={variantData?.brandType === "Cars" ? { label: variantData?.seats, value: variantData?.seats } : { label: "2", value: "2" }}
                                                        options={variantData?.brandType === "Cars" ? seatOption : [{ label: "2", value: "2" }]}
                                                        onChange={(e) => setSelect(e, "seats")}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row className='justify-content-center'>
                                                <Label for="maxKmsRange" sm={3}>Max Kms Range</Label>
                                                <Col sm={5}>
                                                    <Select id="maxKmsRange" name="maxKmsRange" autoComplete='off'
                                                        styles={{
                                                            control: (styles) => ({ ...styles, borderColor: "#ff4d32", "&:hover": { boxShadow: "0 0 0 3px #ff4d3215", borderColor: "#ff4d32" } }),
                                                            option: (style, state) => ({ ...style, "&:hover": { backgroundColor: state.isSelected ? "#ff4d32" : "#ff4d3215" }, backgroundColor: state.isSelected && "#ff4d32" }),
                                                        }}
                                                        className='input-size-2'
                                                        value={{ label: variantData?.maxKmsRange, value: variantData?.maxKmsRange }}
                                                        options={maxKmsOption}
                                                        onChange={(e) => setSelect(e, "maxKmsRange")}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className='justify-content-center'>
                                        <Col>
                                            <div className='text-center my-3'>
                                                <Button className='button-theme' disabled={buttonDisable} onClick={onSubmit}>Update</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
            }
        </React.Fragment>
    )
}

export default VariantEdit
