import { getApiCall } from 'gfdu';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { decrypt } from '../../Helpers/Crypto';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, Table, UncontrolledDropdown } from 'reactstrap';
import { textCapital } from '../../Helpers/Utils';

const ModelView = () => {
    const { id } = useParams("id");
    const navigate = useNavigate()
    const [modelDetails, setModelDetails] = useState({})
    const [openEdit,setOpenEditModel] = useState(false)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setModelDetails({})
        setLoading(true)
        getApiCall(`/admin/vehicle/brand/model/${id}`, (response) => {
            if (response?.success) {
                const decryptedData = decrypt(response?.data)
                if (decryptedData?.success) {
                    setModelDetails((prev) => ({
                        ...prev,
                        ...decryptedData?.data
                    }))
                    console.log(decryptedData?.data);

                } else {
                    setModelDetails({})
                }
            } else {
                setModelDetails({})
                toast.error("Unable to Retrive Data")
            }
            setLoading(false)
        })
    }, [])
    return (

        <React.Fragment>
            {
                loading ?
                    ""
                    :
                    <React.Fragment>
                        <Row xs={1}>
                            <Col className='text-center text-theme fs-3'>Model Details</Col>
                            <Col className='my-2'>
                                <Card>
                                    <CardBody>
                                        <Row sm={3} xs={1}>
                                            <Col sm={3}>
                                                <Table className='table-pad mx-3'>
                                                    <tr>
                                                        <td>Brand ID</td>
                                                        <td>{modelDetails?.brandId}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Brand Name</td>
                                                        <td>{modelDetails?.brand}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Model ID</td>
                                                        <td>{modelDetails?.modelId}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Model Name</td>
                                                        <td>{textCapital(modelDetails?.modelName)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Segment</td>
                                                        <td>{modelDetails?.segment}</td>
                                                    </tr>
                                                </Table>
                                                <div className='text-center'><Button className='button-theme' onClick={()=>navigate(`/vehicle/model/variant/${modelDetails?.modelId}`)}>New Variant</Button></div>
                                            </Col>
                                            <Col sm={9} className='col-scroll'>
                                                <Table className="table-bg table-scroll my-2" borderless>
                                                    <thead>
                                                        <tr className="border-bottom text-center">
                                                        <th>S.No</th>
                                                            <th>Variant Name</th>
                                                            <th>Transmisson</th>
                                                            <th>Fuel Type</th>
                                                            <th>Max Kms Range</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            modelDetails?.variants &&  modelDetails?.variants?.length !== 0 ?  modelDetails?.variants?.map((value, key) => {
                                                                return (
                                                                    <tr key={key} className={`text-center ${!value?.status ? "text-red" : ""}`} >
                                                                        <td>{key + 1}</td>
                                                                        <td>{textCapital(value?.variantName)}</td>
                                                                        <td>{value?.transmissionType || ""}</td>
                                                                        <td>{value?.fuelType || ""}</td>
                                                                        <td>{`${value?.seats } Seats`|| ""}</td>
                                                                        <td className='d-flex w-100 justify-content-center'>
                                                                            <UncontrolledDropdown className="mx-3" >
                                                                                <DropdownToggle className="nav-link not-button"><i className="fa-solid fa-ellipsis-vertical cursor-pointer "></i></DropdownToggle>
                                                                                <DropdownMenu container="body">
                                                                                    <DropdownItem onClick={() => navigate(`/vehicle/variant/${value?.variantId}`)}>View</DropdownItem>
                                                                                    <DropdownItem onClick={() => navigate(`/vehicle/model/variant/edit/${value?.variantId}`)}>Edit</DropdownItem>
                                                                                </DropdownMenu>
                                                                            </UncontrolledDropdown>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }) :
                                                                <tr><td className='text-center' colSpan={9}>No Data Found</td></tr>
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </React.Fragment>
            }
        </React.Fragment>
    )
}

export default ModelView

