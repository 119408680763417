import React, { useState } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import Steper from '../../../Helpers/Components/Steper'
import Registration from './Registration'
import AadharUpdate from './AadharUpdate'
import PanUpdate from './PanUpdate'

const AssociateRegistration = () => {
    const [step,setStep] = useState(1)
    const [customerId,setCustomerId] = useState("")
    return (
        <React.Fragment>
            <Row className='justify-content-center mb-5'>
                <Col lg={10}>
                    <Row><Col className='text-center text-theme fs-3'>Associate Registration</Col></Row>
                    <Card>
                        <CardBody>
                            <Row className='mb-5'>
                                <Col>
                                    <Steper count={3} active={step} />
                                </Col>
                            </Row>
                            {
                                step === 1 ? <Registration setCustomerId={setCustomerId} changeStep={setStep}/> : step === 2 ?  <AadharUpdate customerId={customerId} changeStep={setStep}/> : step ===  3 ?  <PanUpdate customerId={customerId} /> : ""
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}



export default AssociateRegistration