import { postApiCall, reloadWindow } from 'gfdu'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, ButtonGroup, Card, CardBody, Col, Container, Input, Offcanvas, OffcanvasBody, OffcanvasHeader, Row, Table } from 'reactstrap'
import { decrypt, encrypt } from '../../Helpers/Crypto'
import { useDispatch, useSelector } from 'react-redux'
import { carListChange } from "../../Reducer/carSlice"
import { formatDate, textCapital } from '../../Helpers/Utils'
import { toast } from 'react-toastify'
import Pagination from '../../Helpers/Components/Pagination'
import { contentLoadingChange } from '../../Reducer/componentSlice'

const CarList = () => {
  const dispatch = useDispatch()
  const car = useSelector((state) => state.car)
  const navigate = useNavigate()
  const [next, setNext] = useState(false)
  const [status, setStatus] = useState(1)
  const [filterBox, setFilterBox] = useState(false)
  const [filter, setFilter] = useState({
    page: 0,
    count: 10,
    // filter: "",
    // status: true,
    sortBy: "carId",
    sort: false,
    createdBy: [],
    brand: [],
    model: [],
    seats: [],
    ownerType: [],
    fuelType: [],
    segment: [],
    transmissionType: [],
    // star:"5",
    // fasTag:true,
    // verified:false
  })
  const filterChange = (key, value) => {
    if (filter[key]?.includes(value)) {
      setFilter((prev) => ({
        ...prev,
        [key]: filter[key]?.filter((items) => items !== value && items !== "0")
      }))
    } else {
      setFilter((prev) => ({
        ...prev,
        [key]: [...(filter[key]?.filter((items) => items !== "0")), `${value}`]
      }))
    }
  }

  const sort = (sortBy, sort) => {
    setFilter((prev) => ({
      ...prev,
      sortBy: sortBy,
      sort: sort
    }))
  }
  const setPageNo = (value) => {
    setFilter((prev) => ({ ...prev, page: value }))
  }
  const search = (e) => {
    setFilter((prev) => ({
      ...prev,
      filter: e.target.value
    }))
  }
  const clearAllFilter = () => setFilter((prev) => ({
    ...prev,
    sortBy: "carId",
    sort: false,
    createdBy: [],
    brand: [],
    model: [],
    seats: [],
    ownerType: [],
    fuelType: [],
    segment: [],
    transmissionType: [],
  }))
  useEffect(() => {
    dispatch(contentLoadingChange(true))
    dispatch(carListChange([]))
    const filterData = {
      sort: filter?.sort,
      sortBy: filter?.sortBy,
      ...(status !== 1 ? { status: status === 2 ? true : false } : {}),
      ...(filter?.filter && { filter: filter?.filter }),
      ...(filter?.seats?.length !== 0 ? { seats: filter?.seats } : {}),
      ...(filter?.ownerType?.length !== 0 ? { ownerType: filter?.ownerType } : {}),
      ...(filter?.fuelType?.length !== 0 ? { fuelType: filter?.fuelType } : {}),
      ...(filter?.createdBy?.length !== 0 ? { createdBy: filter?.createdBy } : {}),
      ...(filter?.segment?.length !== 0 ? { segment: filter?.segment } : {}),
      ...(filter?.transmissionType?.length !== 0 ? { transmissionType: filter?.transmissionType } : {}),
    }
    postApiCall(`/admin/car/list/${filter?.page}?count=${filter?.count}`, encrypt(filterData), (response) => {
      if (response?.success) {
        const decryptedData = decrypt(response?.data)
        if (decryptedData?.success) {
          dispatch(carListChange(decryptedData?.data?.list))
          setNext(decryptedData?.data?.next)
        } else {
          dispatch(carListChange([]))
          setNext(false)
        }
      } else {
        dispatch(carListChange([]))
        setNext(false)
      }
      dispatch(contentLoadingChange(false))
    })
  }, [filter, status])
  return (
    <div className="branch">
      <Offcanvas direction="end" isOpen={filterBox} toggle={() => setFilterBox(!filterBox)} >
        <OffcanvasBody>
          <Row>
            <Col className='text-center' ><h4>Car Filter</h4></Col>
          </Row>
          <Row className='mx-2 flex-column'>
            <Col className='my-2'>
              <h5>Created By</h5>
              <div className='filter-box'>
                <Button outline className={filter?.createdBy.includes("admin") ? "button-theme-3" : "button-theme-2"} onClick={() => filterChange("createdBy", "admin")}>Admin</Button>
                <Button outline className={filter?.createdBy.includes("subadmin") ? "button-theme-3" : "button-theme-2"} onClick={() => filterChange("createdBy", "subadmin")}>Sub Admin</Button>
                <Button outline className={filter?.createdBy.includes("self") ? "button-theme-3" : "button-theme-2"} onClick={() => filterChange("createdBy", "self")}>Self</Button>
              </div>
            </Col>
            <Col className='my-2' >
              <h5>Seats <i className='bx bxs-carousel'></i></h5>
              <div className='filter-box'>
                <Button className={filter?.seats.includes("5") ? "button-theme-3" : "button-theme-2"} onClick={() => filterChange("seats", "5")} >4/5</Button>
                <Button className={filter?.seats.includes("7") ? "button-theme-3" : "button-theme-2"} onClick={() => filterChange("seats", "7")} >6/7</Button>
                <Button className={filter?.seats.includes("9") ? "button-theme-3" : "button-theme-2"} onClick={() => filterChange("seats", "9")} >8/9</Button>
              </div>
            </Col>
            <Col className='my-2'>
              <h5>Owned By <i className='bx bx-group'></i></h5>
              <div className='filter-box'>
                <Button outline className={filter?.ownerType.includes("HOST") ? "button-theme-3" : "button-theme-2"} onClick={() => filterChange("ownerType", "HOST")}>Host</Button>
                <Button outline className={filter?.ownerType.includes("MSD") ? "button-theme-3" : "button-theme-2"} onClick={() => filterChange("ownerType", "MSD")}>Company</Button>
              </div>
            </Col>
            <Col className='my-2'>
              <h5>Transmission <i className="bi bi-gear"></i></h5>
              <div className='filter-box'>
                <Button className={`${filter?.transmissionType?.includes("Manual") ? "button-theme-3" : "button-theme-2"}`} onClick={() => filterChange("transmissionType", "Manual")}>Manual</Button>
                <Button className={`${filter?.transmissionType?.includes("Automatic") ? "button-theme-3" : "button-theme-2"}`} onClick={() => filterChange("transmissionType", "Automatic")}>Automatic</Button>
              </div>
            </Col>
            <Col className='my-2'>
              <h5>Fuel <i className="bi bi-fuel-pump"></i></h5>
              <div>
                <div className='filter-box'>
                  <Button className={`${filter?.fuelType?.includes("Petrol") ? "button-theme-3" : "button-theme-2"}`} onClick={() => filterChange("fuelType", "Petrol")} >Petrol</Button>
                  <Button className={`${filter?.fuelType?.includes("Diesel") ? "button-theme-3" : "button-theme-2"}`} onClick={() => filterChange("fuelType", "Diesel")} >Diesel</Button>
                  <Button className={`${filter?.fuelType?.includes("Bio-Diesel") ? "button-theme-3" : "button-theme-2"}`} onClick={() => filterChange("fuelType", "Bio-Diesel")} >B-Diesel</Button>
                  <Button className={`${filter?.fuelType?.includes("CNG") ? "button-theme-3" : "button-theme-2"}`} onClick={() => filterChange("fuelType", "CNG")} >CNG</Button>
                  <Button className={`${filter?.fuelType?.includes("LPG") ? "button-theme-3" : "button-theme-2"}`} onClick={() => filterChange("fuelType", "LPG")} >LPG</Button>
                  <Button className={`${filter?.fuelType?.includes("EV") ? "button-theme-3" : "button-theme-2"}`} onClick={() => filterChange("fuelType", "EV")} >EV</Button>
                </div>
              </div>
            </Col>
            <Col className='my-2'>
              <h5>Segment <i className='bx bxs-car-garage'></i></h5>
              <div>
                <div className='filter-box'>
                  <Button className={`${filter?.segment?.includes("Hatchback") ? "button-theme-3" : "button-theme-2"}`} onClick={() => filterChange("segment", "Hatchback")} >Hatchback</Button>
                  <Button className={`${filter?.segment?.includes("Sedan") ? "button-theme-3" : "button-theme-2"}`} onClick={() => filterChange("segment", "Sedan")} >Sedan</Button>
                  <Button className={`${filter?.segment?.includes("SUV") ? "button-theme-3" : "button-theme-2"}`} onClick={() => filterChange("segment", "SUV")} >SUV</Button>
                  <Button className={`${filter?.segment?.includes("Luxury") ? "button-theme-3" : "button-theme-2"}`} onClick={() => filterChange("segment", "Luxury")} >Luxury</Button>
                </div>
              </div>
            </Col>
          </Row>
          <Row className='mx-3 my-4'>
            <Col><Button className='button-theme w-100' onClick={clearAllFilter} >Clear All</Button></Col>
          </Row>
        </OffcanvasBody>
      </Offcanvas>
      <Container fluid>
        <Row className="justify-content-between" >
          <Col className='text-center'><h3>Car</h3></Col>
        </Row>
      </Container>
      <Card className="branch-card table-card">
        <CardBody className="table-content">
          <Row xs={4} className=' justify-content-between' >
            <Col><Input className='search-box input-size-2' placeholder='Search...' value={filter?.filter} onChange={search} /></Col>
            <Col className='text-center'>
              <ButtonGroup className='table-button' size="sm">
                <Button outline className={status === 1 ? "t-btn-s" : ""} onClick={() => setStatus(1)} >All</Button>
                <Button outline className={status === 2 ? "t-btn-s" : ""} onClick={() => setStatus(2)}>Active</Button>
                <Button outline className={status === 3 ? "t-btn-s" : ""} onClick={() => setStatus(3)}>Deactive</Button>
              </ButtonGroup>
            </Col>
            {
              console.log(car?.carList)
            }
            <Col sm={1}><div className="d-flex justify-content-end"><Button color="warning" onClick={() => setFilterBox(!filterBox)} >Filter</Button></div></Col>
            <Col sm={1}><div className="d-flex justify-content-end"><Button className='button-theme' onClick={() => navigate("/car/registration")} >Add</Button></div></Col>
          </Row>
          <Table className="table-bg" borderless >
            <thead>
              <tr className="border-bottom text-middle" >
                <th><div className='sort-head'><div className='sort-arrow' ><i className={filter?.sortBy === "carId" && filter?.sort === false ? "i-active" : ""} onClick={() => sort("carId", false)} >▲</i><i className={filter?.sortBy === "carId" && filter?.sort === true ? "i-active" : ""} onClick={() => sort("carId", true)} >▼</i></div> Car Id </div></th>
                <th>Registration No</th>
                <th>Brand</th>
                <th>Segment </th>
                <th>Model</th>
                <th>Fuel</th>
                <th>Owned By</th>
                <th>Created At</th>
                <th>Created By</th>
              </tr>
            </thead>
            <tbody>
              {
                car?.carList && car?.carList?.length !== 0 ? car?.carList?.map((value, key) => {
                  return (
                    <tr key={key} className={`cursor-pointer-hover ${!value?.status && "text-red"}`} onClick={() => navigate(`/car/${value?.carId}`)} >
                      <td>{(value?.carId)?.toUpperCase()}</td>
                      <td>{(value?.registrationNo)?.toUpperCase()}</td>
                      <td>{textCapital(value?.brand)}</td>
                      <td>{value?.segment}</td>
                      <td>{textCapital(value?.modelName)}</td>
                      <td>{value?.fuelType}</td>
                      <td>{value?.ownerType === "MSD" ? "Company" : "Host"}</td>
                      <td>{formatDate(value?.createdAt)}</td>
                      <td>{textCapital(value?.createdBy)}</td>
                    </tr>
                  )
                }) :
                  <tr className='text-center' ><td colSpan={9}>No Data Found</td></tr>
              }
            </tbody>
          </Table>
          <Pagination pageNo={filter?.page} changePage={setPageNo} next={next} />
        </CardBody>
      </Card>
    </div>
  )
}

export default CarList