import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    customerList : [],
    customerView : {},
    customerData : {},
    togglers : {
      emailOpen : false,
      emailOtp : false,
      reasonOpen : false
    }
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    customerDataEmpty : (state,{payload}) =>{
      state.customerData = {}
    },
    customerListEmpty : (state,{payload}) =>{
      state.customerList = []
    },
    customerViewEmpty : (state,{payload}) =>{
      state.customerView = {}
    },
    customerListChange : (state,{payload}) => {
      state.customerList = payload
    },
    customerViewChange : (state,{payload}) => {
      state.customerView = { ...state?.customerView , ...payload}
    },
    customerDataChange : (state,{payload}) => {
      state.customerData = { ...state?.customerData , ...payload}
    },
    togglersChange : (state,{payload}) => {
      state.togglers = { ...state?.togglers,...payload}
    }  
  }
});

export const { customerListChange , customerViewChange , customerDataChange , togglersChange , customerDataEmpty , customerListEmpty, customerViewEmpty } = customerSlice.actions
export default customerSlice.reducer;
