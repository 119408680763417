import { configureStore } from "@reduxjs/toolkit";
import loginSlice from "../Reducer/loginSlice";
import componentSlice from "../Reducer/componentSlice";
import siteDetailsSlice from "../Reducer/siteDetailsSlice";
import associateSlice from "../Reducer/associateSlice";
import customerSlice from  "../Reducer/customerSlice"
import carSlice from  "../Reducer/carSlice"
import bikeSlice from "../Reducer/bikeSlice";
import carVehicleSlice from "../Reducer/carVehicleSlice";
import bikeVehicleSlice from "../Reducer/bikeVehicleSlice";

const store = configureStore({
  reducer: {
    login: loginSlice,
    component : componentSlice,
    siteDetail : siteDetailsSlice,
    associate : associateSlice,
    customer : customerSlice,
    car : carSlice,
    bike : bikeSlice,
    carVehicle : carVehicleSlice,
    bikeVehicle : bikeVehicleSlice
  },
});

export default store;
